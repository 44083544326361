import React, { useState, useEffect } from "react";
import { postApi, getApi } from "../../../../Api/Api";
import PromoCodeModal from "./PromoCode";
import { toast } from "react-toastify";

const CouponTable = ({ setCouponList, coupons }) => {
  const [showModal, setShowModal] = useState(false);
  const [promoCodeList, setPromocodeList] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const [couponCodeId, setCouponCodeId] = useState(null);

  useEffect(() => {
    const getAllPromoCode = async () => {
      try {
        const response = await getApi("admin/get-all-promocode");
        if (response?.data?.data) {
          setPromocodeList(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching promo codes:', error);
        toast.error(error.response?.data?.message || 'Failed to fetch promo codes. Please try again.');
      }
    };

    getAllPromoCode();
  }, [promoCodes]);

  const handleCreatePromoCode = async (id) => {
    const formData = { id: id };
    const response = await postApi(formData, "admin/genrate-promocode");
    if (response?.data) {
      const updateList = [...promoCodeList, response.data];
      setPromocodeList(updateList);
      toast.success("Promocode genrated successfully!");
    } else {
      toast.error("Failed to genrated promocode. Please try again.");
    }
  };

  const handleRemoveCoupon = async (id) => {
    const filteredCoupons = coupons?.filter((item) => item.id !== id);
    setCouponList(filteredCoupons);
    const fromData = { id: id };
    const response = await postApi(fromData, "admin/delete-coupon");
    if (response?.data) {
      toast.success("Coupon removed successfully!");
    } else {
      toast.error("Failed to removed coupon. Please try again.");
    }
  };

  const handlePromocodeList = async (id) => {
    setCouponCodeId(id);
    const promoCode = getById(id);
    setPromoCodes(promoCode);
    setShowModal(true);
  };

  const getById = (id) => {
    const promoCodes = promoCodeList.filter((item) => item.coupon.id === id);

    if (promoCodes?.length > 0) {
      return promoCodes?.map((promoCode) => ({
        id: promoCode.id,
        name: promoCode.coupon.name,
        discount: promoCode.coupon.percent_off,
        code: promoCode.code,
        active: promoCode.active,
      }));
    } else {
      return [];
    }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-6">Coupon List</h2>
      <div className="border p-5 rounded-xl overflow-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Coupon Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Duration
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Duration in Months
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Percent Off
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Promo code
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                promo Code
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {coupons?.map((coupon, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {coupon.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {coupon.duration[0].toUpperCase() + coupon.duration.slice(1)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {coupon.duration === "repeating"
                    ? coupon.duration_in_months
                    : "N/A"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {coupon.percent_off}%
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <button
                    className="px-4 py-2 text-sm font-semibold text-green-500 hover:text-gray-700"
                    onClick={() => handleCreatePromoCode(coupon.id)}
                  >
                    Genrate
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <button
                    className="px-4 py-2 text-sm font-semibold text-blue-500 hover:text-gray-700"
                    onClick={() => handlePromocodeList(coupon.id)}
                  >
                    See All
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  <button
                    className="px-4 py-2 text-sm font-semibold text-red-500 hover:text-gray-700"
                    onClick={() => handleRemoveCoupon(coupon.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PromoCodeModal
        showModal={showModal}
        setShowModal={setShowModal}
        promoCodes={promoCodes}
        setPromoCodes={setPromoCodes}
      />
    </>
  );
};

export default CouponTable;

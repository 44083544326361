import React, { useEffect, useState, useRef } from "react";
import Card from "../../components/Card";
import FiltersModal from "../../components/FiltersModal";
import { getApi, updateApi } from "../../Api/api";
import InfintiteLoader from "../../components/InfiniteLoader";
import HomeSkeltonLoader from "../../components/HomeSkeltonLoader";
import CapModal from "../../components/CapAnalysis/CapModal";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix Leaflet's default icon path issues
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Home = () => {
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdateCapDataLoading, setUpdateCapDataLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCapModalOpen, setCapModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const limit = 200;

  const [capData, setCapData] = useState();

  const [allCapDatas, setAllCapDatas] = useState([]);
  const [viewMode, setViewMode] = useState('split');
  const mapContainer = useRef(null);
  const map = useRef(null);

  // Add a ref to store markers
  const markersRef = useRef({});

  // Add selected property state
  const [selectedAddress, setSelectedAddress] = useState(null);
  const gridItemRefs = useRef({});

  // Add state for map bounds
  const [mapBounds, setMapBounds] = useState(null);

  // Add state for zip boundary
  const [zipBoundaryLayer, setZipBoundaryLayer] = useState(null);

  // Add state for total count and sort
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState('newest');

  const handleCardClick = (property) => {
    let selectedCapData = allCapDatas?.find((item) =>
      item?.address.includes(property?.address?.streetAddress)
    );
    if (selectedCapData) {
      selectedCapData.rental.years = 20;
      selectedCapData.condo.years = 20;
      selectedCapData.existing.years = 20;
      selectedCapData.zoningDistricts =
        property?.webAddressData?.zoningDistricts;
      setCapData(selectedCapData);
    } else {
      setCapData({
        zipCode: property?.address?.postalCode,
      });
    }
  };

  const fetchData = async (resetData = false) => {
    setLoading(true);
    try {
      const query = {
        ...filters,
        page: resetData ? 1 : currentPage,  // Reset to page 1 for new sorts/filters
        limit,
        sortBy,
        bounds: JSON.stringify((viewMode === 'map' || viewMode === 'split') && mapBounds && !filters.zip_code ? {
          north: mapBounds.north,
          south: mapBounds.south,
          east: mapBounds.east,
          west: mapBounds.west
        } : undefined),
      };

      const response = await getApi(`address/get-filtered-address?${new URLSearchParams(query)}`);
      
      if (response?.data?.length > 0) {
        setHasMore(true);
        // Always reset data when sorting or filtering
        if (resetData) {
          setDisplayData(response.data);
          setAllCapDatas(response.data.map(e => e.capData));
        } else {
          setDisplayData(prev => [...prev, ...response.data]);
          setAllCapDatas(prev => [...prev, ...response.data.map(e => e.capData)]);
        }
        setTotalCount(response.totalCount);
      } else {
        setHasMore(false);
        if (resetData) {
          setDisplayData([]);
          setAllCapDatas([]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasMore(false);
    }
    setLoading(false);
  };

  const fetchPropertyTypes = async () => {
    try {
      const url = "address/property_types";
      const response = await getApi(url);
      setPropertyTypes(response.propertyTypes);
    } catch (e) {
      console.log("Error in fetching Property Types", propertyTypes);
    }
  };

  const updateCapData = async (address, section) => {
    try {
      setUpdateCapDataLoading(true);
      let payload;
      if (section === "rental") {
        payload = {
          rental: capData?.rental,
          address: address,
        };
      } else if (section === "condo") {
        payload = {
          condo: capData?.condo,
          address: address,
        };
      } else if (section === "existing") {
        payload = {
          existing: capData?.existing,
          address: address,
        };
      }

      const url = "cap/update/cap_data";
      const response = await updateApi(payload, url);
      setCapData((prev) => {
        return {
          ...prev,
          [section]:
            section === "rental"
              ? response.rental
              : section === "condo"
              ? response.condo
              : response.existing,
        };
      });
      setUpdateCapDataLoading(false);
    } catch (e) {
      setUpdateCapDataLoading(false);
      console.log("Error in fetching Property Types", e);
    }
  };

  useEffect(() => {
    fetchPropertyTypes();
  }, []);

  // Add effect for initial load
  useEffect(() => {
    // console.log("Fetching data for initial load");
    // fetchData(true);
  }, []); // Empty dependency array for initial load

  // Keep existing effect for filter/sort/bounds changes
  useEffect(() => {
    if (sortBy || Object.keys(filters).length > 0 || mapBounds) {
      console.log("Fetching data for filter/sort/bounds changes");
      setCurrentPage(1);
      fetchData(true);
    }
  }, [sortBy, filters, mapBounds]);

  // Keep existing effect for pagination
  useEffect(() => {
    if (!loading && hasMore && currentPage > 1) {
      console.log("Fetching data for pagination");
      fetchData();
    }
  }, [currentPage]);

  useEffect(() => {
    if (viewMode !== 'grid') {  // Initialize map when in 'map' or 'split' mode
      if (!map.current) {
        map.current = L.map(mapContainer.current).setView([40.7128, -74.006], 12);
        
        // Base layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors'
        }).addTo(map.current);

        // Add OpenPTMap layer
        L.tileLayer('http://openptmap.org/tiles/{z}/{x}/{y}.png', {
          attribution: '© OpenPTMap contributors',
          maxZoom: 17,
          opacity: 0.7
        }).addTo(map.current);

        L.control.zoom({
          position: 'topright'
        }).addTo(map.current);

        // Add moveend event listener
        map.current.on('moveend', () => {
          const bounds = map.current.getBounds();
          setMapBounds({
            north: bounds.getNorth(),
            south: bounds.getSouth(),
            east: bounds.getEast(),
            west: bounds.getWest()
          });
        });

        map.current.on('zoomend', () => {
          const zoom = map.current.getZoom();
          updateMarkersBasedOnZoom(zoom);
        });
      }

      // Force a map resize when switching views
      setTimeout(() => {
        if (map.current) {
          map.current.invalidateSize();
        }
      }, 100);
    } else {
      // Cleanup map when switching to grid view
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    }

    return () => {
      if (map.current && viewMode === 'grid') {
        map.current.remove();
        map.current = null;
      }
    };
  }, [viewMode]);

  const updateMarkersBasedOnZoom = (zoom) => {
    // Update marker display based on zoom level
    const markers = document.getElementsByClassName('leaflet-marker-icon');
    Array.from(markers).forEach(marker => {
      if (zoom > 14) {
        marker.style.transform += ' scale(1.2)';
      } else {
        marker.style.transform = marker.style.transform.replace(' scale(1.2)', '');
      }
    });
  };

  // const handleFilterChange = (filterName, value) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [filterName]: value,
  //   }));

  //   // Show zip boundary when zip code filter changes
  //   if (filterName === 'zip_code' && map.current) {
  //     showZipCodeBoundary(value);
  //   }
  // };

  const handleApplyFilters = async (updatedFilters) => {
    console.log("Applying filters:", updatedFilters);
    setFilters(updatedFilters);
    setModalOpen(false);
    setCurrentPage(1);
    setSelectedAddress(null);
    //   // Show zip boundary when zip code filter changes
    if (updatedFilters.zip_code && map.current) {
      showZipCodeBoundary(updatedFilters.zip_code);
    }
    // await fetchData(true);
  };

  const handleResetFilters = () => {
    if (zipBoundaryLayer) {
      map.current.removeLayer(zipBoundaryLayer);
      setZipBoundaryLayer(null);
    }
    setSelectedAddress(null);
    window.location.reload();
  };

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleScroll = () => {
    // Only load more data if we're in grid-only view
    if (viewMode === 'grid') {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (hasMore) {
          fetchMoreData();
        }
      }
    }
  };

  const getUnique = (data) => {
    const uniqueData = Array.from(
      new Map(data?.map((item) => [item?.name, item])).values()
    );
    return uniqueData;
  };

  const getUniqueAndSort = (data, capData) => {
    const uniqueData = Array.from(
      new Map(data?.map((item) => [item?.name, item])).values()
    );

    return uniqueData.sort((a, b) => {
      const capRateA =
        capData?.find((item) =>
          item?.address?.includes(a?.address?.streetAddress)
        )?.rental?.CapRate?.[0] || 0;

      const capRateB =
        capData?.find((item) =>
          item?.address?.includes(b?.address?.streetAddress)
        )?.rental?.CapRate?.[0] || 0;

      return capRateB - capRateA;
    });
  };

  // Add this useEffect to watch for changes in displayData and viewMode
  useEffect(() => {
    if ((viewMode === 'map' || viewMode === 'split') && map.current && displayData.length > 0) {
      // Clear existing markers and shadows
      const markers = document.getElementsByClassName('leaflet-marker-icon');
      const shadows = document.getElementsByClassName('leaflet-marker-shadow');
      Array.from(markers).forEach(marker => marker.remove());
      Array.from(shadows).forEach(shadow => shadow.remove());
      
      // Clear markers ref
      markersRef.current = {};
      
      displayData.forEach(property => {
        const { latitude, longitude } = property.geo;
        if (!latitude || !longitude) return;

        // Get the display value based on current sort
        let displayValue = '';
        switch (sortBy) {
          case 'newest':
          case 'oldest':
            displayValue = property.listingUpdated ? 
              (() => {
                const date = new Date(property.listingUpdated);
                return !isNaN(date.getTime()) ? 
                  date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) 
                  : '';
              })() : '';
            break;
          case 'price_low':
          case 'price_high':
            displayValue = property.price ? `$${property.price.toLocaleString()}` : '';
            break;
          case 'price_sf_low':
          case 'price_sf_high':
            displayValue = property.zoningInfo?.residential?.priceSf ? 
              `$${property.zoningInfo.residential.priceSf.toFixed(0).toLocaleString()}/SF` : '';
            break;
          case 'existing_sf_low':
          case 'existing_sf_high':
            displayValue = property.zoningInfo?.existingPriceSf ? 
              `$${property.zoningInfo.existingPriceSf.toFixed(0).toLocaleString()}/SF` : '';
            break;
          case 'lot_area_low':
          case 'lot_area_high':
            displayValue = property.zoningInfo?.lotArea ? 
              `${property.zoningInfo.lotArea.toLocaleString()} SF` : '';
            break;
          case 'max_apartments_low':
          case 'max_apartments_high':
            displayValue = property.zoningInfo?.residential?.maxApartments ? 
              `${property.zoningInfo.residential.maxApartments} units` : '';
            break;
          case 'existing_apartments_low':
          case 'existing_apartments_high':
            displayValue = property.zoningInfo?.residential?.existingUnits ? 
              `${property.zoningInfo.residential.existingUnits} units` : '';
            break;
          default:
            displayValue = property.zoningInfo?.residential?.priceSf ? 
              `$${property.zoningInfo.residential.priceSf.toFixed(0)}/SF` : '';
        }

        const customIcon = L.divIcon({
          className: 'custom-marker',
          html: `
            <div class="marker-container">
              <img 
                src="${require('leaflet/dist/images/marker-icon.png')}" 
                class="marker-icon"
                style="width: 20px; height: 33px;"
              />
              ${displayValue ? `<div class="price-label">${displayValue}</div>` : ''}
            </div>
          `,
          iconSize: [20, 33],
          iconAnchor: [10, 33],
          popupAnchor: [1, -34]
        });

        const popupContent = `
          <div class="p-2">
            <h3 class="font-bold">${property.address?.streetAddress || 'N/A'}</h3>
            <p>$/SF: ${displayValue}</p>
            ${map.current.getZoom() > 14 ? `
              <p>Max Apartments: ${property.zoningInfo?.residential?.maxApartments || 'N/A'}</p>
              <p>Existing Units: ${property.zoningInfo?.residential?.existingUnits || 'N/A'}</p>
              <p>Lot Area: ${property.zoningInfo?.lotArea || 'N/A'}</p>
              <p>Residential FAR: ${property.zoningInfo?.residential?.far || 'N/A'}</p>
              <p>Residential FA: ${property.zoningInfo?.residential?.fa || 'N/A'}</p>
              <p>Existing $/SF: ${property.zoningInfo?.existingPriceSf || 'N/A'}</p>
              <p>Existing Bldg Area: ${property.zoningInfo?.existingBldgArea || 'N/A'}</p>
            ` : ''}
          </div>
        `;

        const marker = L.marker([latitude, longitude], { icon: customIcon })
          .bindPopup(popupContent)
          .addTo(map.current);

        marker.on('click', () => {
          // Unhighlight any previously selected marker
          if (selectedAddress) {
            unhighlightMarker(selectedAddress);
          }
          
          handleCardClick(property);
          setSelectedAddress(property.address?.streetAddress);
          highlightMarker(property.address?.streetAddress);
          
          // Scroll the corresponding grid item into view if in split mode
          if (viewMode === 'split' && gridItemRefs.current[property.address?.streetAddress]) {
            gridItemRefs.current[property.address?.streetAddress].scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        });

        // Store marker reference with property address as key
        markersRef.current[property.address?.streetAddress] = marker;

        // If this is the selected address, highlight it
        if (selectedAddress === property.address?.streetAddress) {
          highlightMarker(property.address?.streetAddress);
        }
      });
    }
  }, [displayData, viewMode, selectedAddress, sortBy]);

  const highlightMarker = (address) => {
    if (markersRef.current[address] && (viewMode === 'map' || viewMode === 'split')) {
      const marker = markersRef.current[address];
      const markerElement = marker.getElement();
      if (markerElement) {
        const iconElement = markerElement.querySelector('.marker-icon');
        const labelElement = markerElement.querySelector('.price-label');
        if (iconElement) iconElement.style.filter = 'hue-rotate(120deg) brightness(1.2)';
        if (labelElement) labelElement.style.filter = 'hue-rotate(120deg) brightness(1.2)';
        marker.setZIndexOffset(1000);
      }
    }
  };

  const unhighlightMarker = (address) => {
    if (markersRef.current[address] && (viewMode === 'map' || viewMode === 'split')) {
      const marker = markersRef.current[address];
      const markerElement = marker.getElement();
      if (markerElement) {
        const iconElement = markerElement.querySelector('.marker-icon');
        const labelElement = markerElement.querySelector('.price-label');
        if (iconElement) iconElement.style.filter = '';
        if (labelElement) labelElement.style.filter = '';
        marker.setZIndexOffset(0);
      }
    }
  };

  // Add these styles to your CSS
  const styles = `
    .custom-marker {
      background: none;
      border: none;
    }
    
    .marker-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .price-label {
      background: white;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 12px;
      margin-top: -4px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.2);
      white-space: nowrap;
    }
  `;

  // Add the styles to the document
  const styleSheet = document.createElement("style");
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);

  // Add function to fetch and display zip boundary
  const showZipCodeBoundary = async (zipCode) => {
    try {
      // Remove existing zip boundary layer if it exists
      if (zipBoundaryLayer) {
        map.current.removeLayer(zipBoundaryLayer);
        setZipBoundaryLayer(null);
      }

      if (!zipCode) return;

      // Fetch zip code boundary data from Census Bureau's 2022 ACS API
      const response = await fetch(
        `https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/tigerWMS_ACS2022/MapServer/0/query?where=ZCTA5='${zipCode}'&geometryPrecision=5&outSR=4326&f=geojson`
      );
      const data = await response.json();
      
      if (data.features && data.features.length > 0) {
        // Create and add the boundary layer using GeoJSON
        const layer = L.geoJSON(data, {
          style: {
            color: '#3388ff',
            weight: 2,
            fillOpacity: 0.1,
            interactive: false
          }
        }).addTo(map.current);

        setZipBoundaryLayer(layer);
        
        // Fit map to the actual boundary
        map.current.fitBounds(layer.getBounds());
      } else {
        console.log('No boundary data found for zip code:', zipCode);
        
        // Fallback to approximate boundary
        const fallbackResponse = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        const fallbackData = await fallbackResponse.json();
        
        if (fallbackData && fallbackData.places && fallbackData.places[0]) {
          const { latitude, longitude } = fallbackData.places[0];
          
          // Create a rough boundary (approximately 1 mile square)
          const latDelta = 0.014;  // roughly 1 mile
          const lngDelta = 0.018;  // roughly 1 mile
          
          const bounds = [
            [parseFloat(latitude) - latDelta, parseFloat(longitude) - lngDelta],
            [parseFloat(latitude) + latDelta, parseFloat(longitude) + lngDelta]
          ];

          // Create and add the boundary layer
          const layer = L.rectangle(bounds, {
            color: '#3388ff',
            weight: 2,
            fillOpacity: 0.1,
            interactive: false
          }).addTo(map.current);

          setZipBoundaryLayer(layer);
          map.current.fitBounds(bounds);
        }
      }
    } catch (error) {
      console.error('Error fetching zip code boundary:', error);
    }
  };

  // Update sort options
  const sortOptions = [
    { value: 'newest', label: 'Newest First' },
    { value: 'oldest', label: 'Oldest First' },
    { value: 'price_low', label: 'Price: Low to High' },
    { value: 'price_high', label: 'Price: High to Low' },
    { value: 'price_sf_low', label: 'Price/SF: Low to High' },
    { value: 'price_sf_high', label: 'Price/SF: High to Low' },
    { value: 'existing_sf_low', label: 'Existing $/SF: Low to High' },
    { value: 'existing_sf_high', label: 'Existing $/SF: High to Low' },
    { value: 'lot_area_low', label: 'Lot Area: Low to High' },
    { value: 'lot_area_high', label: 'Lot Area: High to Low' },
    { value: 'max_apartments_low', label: 'Max Apartments: Low to High' },
    { value: 'max_apartments_high', label: 'Max Apartments: High to Low' },
    { value: 'existing_apartments_low', label: 'Existing Apartments: Low to High' },
    { value: 'existing_apartments_high', label: 'Existing Apartments: High to Low' },
  ];

  // Handle sort change
  const handleSortChange = async (event) => {
    setSortBy(event.target.value);
    setCurrentPage(1);  // Reset to first page
    console.log("Sorting by:", event.target.value);
    // await fetchData(true);  // Force data reset
  };

  return (
    <div className="fixed inset-0 pt-16 flex">
      <div className="absolute top-4 right-0 m-4 z-50">
        <button
          onClick={() => setViewMode('grid')}
          className={`px-4 py-2 rounded-l-md ${viewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Grid Only
        </button>
        <button
          onClick={() => setViewMode('split')}
          className={`px-4 py-2 rounded-r-md ${viewMode === 'split' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Split View
        </button>
      </div>

      {/* Grid view */}
      {(viewMode === 'grid' || viewMode === 'split') && (
        <div className={`${viewMode === 'split' ? 'w-1/2' : 'w-full'} flex flex-col relative`}>
          <div className="sticky top-0 bg-white py-2 z-10 px-10">
            <div className="flex items-center gap-4">
              {/* <h1 className="text-2xl font-bold">Properties</h1> */}
              <span className="text-gray-600">
                {totalCount} results found
              </span>
            </div>
            <div className="flex items-center gap-4">
              <select
                value={sortBy}
                onChange={handleSortChange}
                className="p-2 border rounded-md"
              >
                {sortOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button
                onClick={() => setModalOpen(!isModalOpen)}
                className="bg-blue-500 text-white p-2 rounded-md font-bold"
              >
                Filters
              </button>
              <button
                onClick={handleResetFilters}
                className="bg-gray-500 text-white p-2 rounded-md font-bold"
              >
                Reset
              </button>
            </div>
          </div>

          {/* Add loading overlay */}
          {loading && (
            <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-20">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          )}

          <div className="flex-1 overflow-y-auto px-10 pb-10">
            {getUniqueAndSort(displayData)?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 gap-6">
                {getUniqueAndSort(displayData)
                  .map((property, index) => (
                    <div
                      key={index}
                      ref={el => gridItemRefs.current[property.address?.streetAddress] = el}
                      onMouseEnter={() => highlightMarker(property?.address?.streetAddress)}
                      onMouseLeave={() => unhighlightMarker(property?.address?.streetAddress)}
                      className={`transition-all duration-200 ${
                        selectedAddress === property.address?.streetAddress 
                          ? 'scale-105 shadow-lg ring-2 ring-blue-500' 
                          : ''
                      }`}
                    >
                      <Card
                        title={property?.name}
                        price={property?.price}
                        priceSf={property?.zoningInfo?.residential?.priceSf || 0}
                        location={property?.address?.streetAddress}
                        beds={property?.beds}
                        baths={property?.baths}
                        sqft={property?.sqft}
                        imgUrls={property?.imgUrls}
                        propertyUrls={property?.propertyUrls}
                        zipcode={property?.address?.postalCode}
                        lotArea={property?.zoningInfo?.lotArea}
                        FARs={property?.zoningInfo?.residential?.far}
                        FAs={property?.zoningInfo?.residential?.fa}
                        maxApartments={property?.zoningInfo?.residential?.maxApartments}
                        existingApartments={property?.zoningInfo?.residential?.existingUnits}
                        existingPriceSf={property?.zoningInfo?.existingPriceSf}
                        zoningDistricts={property?.zoningInfo?.zoningDistricts}
                        listingUpdated={property?.listingUpdated}
                        tipValues={property?.tipValues}
                        setCapModalOpen={setCapModalOpen}
                        onClick={() => {
                          if (selectedAddress) {
                            unhighlightMarker(selectedAddress);
                          }
                          handleCardClick(property);
                          setSelectedAddress(property.address?.streetAddress);
                          highlightMarker(property.address?.streetAddress);
                        }}
                        cardCapData={allCapDatas?.find((item) =>
                          item?.address?.includes(property?.address?.streetAddress)
                        )}
                        updateCapData={updateCapData}
                      />
                    </div>
                  ))}
              </div>
            ) : displayData?.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full">
                <h2 className="text-3xl font-bold text-gray-700 mb-4">
                  Sorry, nothing found
                </h2>
                <p className="text-gray-500">
                  Try adjusting your filters or search criteria.
                </p>
              </div>
            ) : null}
            
            {/* {hasMore && getUniqueAndSort(displayData).length >= 3 && (
              <div className="mt-6">
                <InfintiteLoader />
              </div>
            )} */}
          </div>
        </div>
      )}

      {/* Map view - only show in split mode */}
      {viewMode === 'split' && (
        <div className="w-1/2">
          <div ref={mapContainer} className="h-full" />
        </div>
      )}

      <FiltersModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onFilterChange={(filterName, value) => {
          
        }}
        applyFilters={handleApplyFilters}
        filters={filters}
        propertyTypes={propertyTypes}
        setCapModalOpen={setCapModalOpen}
        capData={capData}
        setCapData={setCapData}
      />
      <CapModal
        isOpen={isCapModalOpen}
        capData={capData}
        onClose={() => {
          setCapData({});
          setCapModalOpen(false);
        }}
        setCapData={setCapData}
        updateCapData={updateCapData}
        isUpdateCapDataLoading={isUpdateCapDataLoading}
      />
    </div>
  );
};

export default Home;

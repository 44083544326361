import { getApi } from "../../Api/api";
import AdminCard from "./AdminCard";

const AddressFormTable = ({ formData, lastElementRef, setFormData }) => {
  const fetchStatuses = async () => {
    const response = await getApi("admin/get-status");
    if (response?.data) {
      setFormData((prevData) => [...prevData, ...response.data]);
    }
    try {
    } catch (error) {
      console.log("error at fetch status: ", error);
    }
  };

  const getSortedData = (formData) => {
    if (!Array.isArray(formData)) {
      console.error("Expected formData to be an array");
      return [];
    }
    const uniqueData = Array.from(
      new Map(formData.map((item) => [item?.address, item])).values()
    );
    const sortedData = uniqueData.sort((a, b) => {
      if (a?.status === "Completed" && b?.status !== "Completed") return -1;
      if (a?.status !== "Completed" && b?.status === "Completed") return 1;
      return 0;
    });
    return sortedData;
  };


  return (
    <div className="mt-48 md:mt-16">
      <div className="pl-12 ">
        <div className="grid grid-cols-3 max-sm:grid-cols-1 max-xl:grid-cols-2 gap-12 max-lg:grid-cols-1">
          {getSortedData(formData)?.length > 0 ? (
            getSortedData(formData)?.map((property, index) => (
              <AdminCard data={property} key={index}     />
            ))
          ) : (
            <div className="flex flex-row justify-center items-center">
              No properties available
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressFormTable;

import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { postApi } from '../Api/Api';
import { useLocation } from 'react-router-dom';
import DisclaimerPage from '../website/pages/DisclaimerPage';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const [token, setToken] = useState(Cookies.get("authToken"));
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get("authToken");
    return !!token;
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const validateAuth = async () => {
      const token = Cookies.get("authToken");
      setToken(token);
      console.log("Validating auth with token:", token ? "present" : "missing");
      if (token) {
        try {
          const response = await postApi({ token }, 'website/user/validate-token');
          console.log("Token validation response:", response);
          setIsAuthenticated(true);
          setIsAdmin(response.role === 'admin');
          setUserEmail(response.email);
          setUserData(response);
        } catch (error) {
          console.error("Token validation failed:", error);
          Cookies.remove("authToken");
          setIsAuthenticated(false);
          setIsAdmin(false);
          setUserEmail('');
          setUserData(null);
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        setUserEmail('');
        setUserData(null);
      }
      setLoading(false);
    };

    validateAuth();

    // Listen for auth updates
    window.addEventListener('auth-update', validateAuth);
    return () => window.removeEventListener('auth-update', validateAuth);
  }, [location.pathname]);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    isAdmin,
    setIsAdmin,
    loading,
    token,
    userEmail,
    userData,
  };
  // If authenticated but hasn't accepted terms, show disclaimer
  const showDisclaimer = isAuthenticated && userData && !userData.hasAcceptedTerms;

  return (
    <AuthContext.Provider value={value}>
      {!loading && !showDisclaimer && children}
      {showDisclaimer && <DisclaimerPage />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); 
import { SquareX } from "lucide-react";
import { Popover } from "@headlessui/react";

import React, { useState, useEffect } from "react";

const FiltersModal = ({
  isOpen,
  onClose,
  onFilterChange,
  applyFilters,
  filters,
  propertyTypes,
  setCapModalOpen,
  setCapData,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState(
    localFilters.propertyTypes || []
  );
  const [selectAll, setSelectAll] = useState(true);

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev); // Toggle between Select All and Deselect All
  };

  useEffect(() => {
    setLocalFilters(filters);
    setSelectedPropertyTypes(filters.propertyTypes || []);
  }, [filters]);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleApplyFilters = () => {
    let updatedFilters;
    if (!selectAll) {
      updatedFilters = {
        ...localFilters,
        property_type: selectedPropertyTypes,
      };
    }

    applyFilters(updatedFilters);
  };

  const handleInputChange = (filterName, value) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    onFilterChange(filterName, value);
  };

  if (!isOpen) return null;

  const renderInputField = (
    id,
    label,
    type,
    value,
    onChange,
    placeholder = "",
    step = "1",
    min = "0"
  ) => (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-gray-800 text-sm">
        {label}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        step={step}
        min={min}
        value={value}
        onChange={onChange}
        className="w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );

  const renderInputCapField = (
    id,
    label,
    type,
    value,
    onChange,
    placeholder = "",
    step = "1",
    min = "0"
  ) => (
    <div className="space-y-1 flex flex-col">
      <label htmlFor={id} className="block text-gray-800 text-sm">
        {label}
      </label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        step={step}
        min={min}
        value={value}
        onChange={onChange}
        className="w-full px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <span
        className="text-blue-600 text-sm hover:cursor-pointer"
        onClick={() => {
          setCapData({});
          setCapModalOpen(true);
        }}
      >
        Set Parameters
      </span>
    </div>
  );

  const handlePropertyTypeChange = (value) => {
    if (selectAll) {
      setSelectAll(false);
    }
    setSelectedPropertyTypes((prevTypes) => {
      const newTypes = prevTypes.includes(value)
        ? prevTypes.filter((type) => type !== value)
        : [...prevTypes, value];
      onFilterChange("propertyTypes", newTypes);

      return newTypes;
    });
  };

  const renderPropertyTypePopover = () => (
    <Popover className="relative">
      <Popover.Button className="w-1/3 mt-4 ml-4 text-sm bg-blue-500 text-white px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
        Property Type
      </Popover.Button>
      <Popover.Panel className="absolute z-10 w-1/2 ml-4 bg-white border border-gray-300 rounded-lg shadow-lg mt-2">
        <div className="p-4 space-y-2 max-h-60 overflow-y-auto">
          {/* Select All / Deselect All Toggle */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="selectAll"
              checked={selectAll}
              onChange={handleSelectAll}
              className="h-4 w-4 text-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="selectAll" className="text-sm text-gray-800">
              {selectAll ? "Deselect All" : "Select All"}
            </label>
          </div>

          {/* Property Types List */}
          {propertyTypes.map((type) => (
            <div key={type} className="flex items-center space-x-2">
              <input
                type="checkbox"
                id={type}
                value={type}
                checked={selectAll || selectedPropertyTypes.includes(type)} // If selectAll is true, all checkboxes will be checked
                onChange={() => handlePropertyTypeChange(type)}
                className="h-4 w-4 text-blue-500 border-gray-300 rounded"
              />
              <label htmlFor={type} className="text-sm text-gray-800">
                {type}
              </label>
            </div>
          ))}
        </div>
        <div className="p-2 flex justify-center">
          <button
            onClick={handleApplyFilters}
            className="bg-blue-500 text-white px-4 py-1 w-[75%] rounded-lg hover:bg-blue-700"
          >
            Apply
          </button>
        </div>
      </Popover.Panel>
    </Popover>
  );

  const renderRangeInputField = (
    id,
    label,
    minValue,
    maxValue,
    onMinChange,
    onMaxChange,
    placeholderMin = "",
    placeholderMax = "",
    step = "1",
    min = "0"
  ) => (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-gray-800 text-sm">
        {label}
      </label>
      <div className="flex justify-between items-center">
        <input
          type="number"
          placeholder={placeholderMin}
          step={step}
          min={min}
          value={minValue}
          onChange={onMinChange}
          className="w-[40%] px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
        />
        <span className="text-sm text-gray-500">to</span>
        <input
          type="number"
          placeholder={placeholderMax}
          step={step}
          min={min}
          value={maxValue}
          onChange={onMaxChange}
          className="w-[40%] px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
        />
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg">
        <div className="bg-blue-500 text-white py-3 px-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-lg font-semibold">Filters</h2>

          <SquareX className="hover:cursor-pointer" onClick={onClose} />
        </div>

        {renderPropertyTypePopover()}

        <div className="space-y-4 p-4">
          {renderRangeInputField(
            "max-apartments",
            "Max Apartments Allowed",
            localFilters.max_apartments_min,
            localFilters.max_apartments_max,
            (e) =>
              handleInputChange("max_apartments_min", parseInt(e.target.value)),
            (e) =>
              handleInputChange("max_apartments_max", parseInt(e.target.value)),
            "Min apartments",
            "Max apartments"
          )}
          {renderInputField(
            "residential-fa",
            "Residential FA",
            "number",
            localFilters.residential_fa,
            (e) =>
              handleInputChange("residential_fa", parseFloat(e.target.value)),
            "Residential FA",
            "0.01"
          )}

          {renderRangeInputField(
            "price-range",
            "Price Range",
            localFilters.price_min,
            localFilters.price_max,
            (e) => handleInputChange("price_min", e.target.value),
            (e) => handleInputChange("price_max", e.target.value),
            "Min Price",
            "Max Price"
          )}

          {renderRangeInputField(
            "price/sf-residental",
            "Price / SF Residental",
            localFilters.price_sf_min,
            localFilters.price_sf__max,
            (e) => handleInputChange("price_sf_min", e.target.value),
            (e) => handleInputChange("price_sf__max", e.target.value),
            "Min ",
            "Max "
          )}

          {renderRangeInputField(
            "lot-area",
            "Lot Area",
            localFilters.lot_area_min,
            localFilters.lot_area_max,
            (e) => handleInputChange("lot_area_min", parseInt(e.target.value)),
            (e) => handleInputChange("lot_area_max", parseInt(e.target.value)),
            "Min area (sqft)",
            "Max area (sqft)"
          )}
          <div className="flex w-full space-x-4 ">
            {renderInputCapField(
              "cap-rate",
              "Cap Rate",
              "text",
              localFilters.cap_rate,
              (e) => handleInputChange("cap-rate", e.target.value),
              "Cap Rate"
            )}
          </div>

          {renderInputField(
            "zip-code",
            "Zip Code",
            "text",
            localFilters.zip_code,
            (e) => handleInputChange("zip_code", e.target.value),
            "Enter zip code"
          )}
          {renderInputField(
            "zoning-district",
            "Zoning District",
            "text",
            localFilters.zoning_district,
            (e) => handleInputChange("zoning_district", e.target.value),
            "Enter zoning district"
          )}

          <div className="space-y-2">
            <label htmlFor="listing-updates" className="block text-gray-800">
              Listing Updates
            </label>
            <div className="flex justify-between items-center">
              <input
                type="date"
                value={localFilters.listing_updates_min}
                onChange={(e) =>
                  handleInputChange("listing_updates_min", e.target.value)
                }
                className="w-[40%] px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
              />
              <span className="text-sm text-gray-500">to</span>
              <input
                type="date"
                value={localFilters.listing_updates_max}
                onChange={(e) =>
                  handleInputChange("listing_updates_max", e.target.value)
                }
                className="w-[40%] px-2 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2"
              />
            </div>
          </div>

          <div className="pt-4">
            <button
              onClick={() => applyFilters(localFilters)}
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-700 transition-all"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;

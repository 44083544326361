import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fetchData,
  checkLable,
  checkSelectedFilter,
  handleSubmit,
} from "../utils/formUtils";
import Loader from "./Loader";

const DynamicFormWebsite = () => {
  const [selectedKeys, setSelectedKeys] = useState({});
  const [filters, setFilters] = useState([]);
  const [address, setAddress] = useState("");
  const [formSubmited, setformSubmitted] = useState(false);
  const [lotArea, setLotArea] = useState();
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const location = useLocation();
  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const user = searchParams.get("user");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      try {
        const data = await fetchData(id);
        setLotArea(data?.lotArea);
        setFilters(data?.sendFilter);
        setAddress(data?.address);
      } catch (error) {
        console.error("Error fetching and setting data:", error);
      }
    };

    if (id) {
      fetchDataAndSetState();
    }
  }, [id]);

  let withinLabel;
  let notWithInLabel;

  const mergedData = filters?.flatMap((item) => {
    return Object.keys(item)?.map((sheetName) => {
      const {
        filterlayerSD_ZD = [],
        filterlayerSD_ZD_CO = [],
        filterlayerZD = [],
        filterlayerZD_CO = [],
      } = item[sheetName];

      let mergedArray = [];
      if (sheetName !== "use_group") {
        mergedArray = [
          ...filterlayerSD_ZD,
          ...filterlayerSD_ZD_CO,
          ...filterlayerZD,
          ...filterlayerZD_CO,
        ];
      } else {
        mergedArray = item.use_group;
      }

      return { sheetName: sheetName, datafilters: mergedArray };
    });
  });

  const flattenedData = mergedData?.flat();

  if (formSubmited) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-sky-100 border border-sky-300 text-sky-800 rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Your Form has submitted!</h2>
        </div>
      </div>
    );
  }

  const filteredData = flattenedData?.flatMap(({ sheetName, datafilters }) =>
    datafilters?.map((filter) => ({
      index: filter.index,
      sheetName: sheetName,
      zoningDistrict: filter["Zoning District (Zola)"],
      commercialOverlay: filter["Commercial Overlay (Zola)"],
      specialDistrict: filter["Special District (Zola)"],
      subdistrict: filter["Subdistrict (Operator to Select in Step 1)"],
      filter_5: filter["Filter 5 (Operator to Select in Step 1)"],
      filter_6: filter["Filter 6 (Operator to Select in Step 1)"],
      filter_7: filter["Filter 7 (Operator to Select in Step 1)"],
    }))
  );

  const transformedData = filteredData?.reduce((acc, item) => {
    let keys = [];

    if (item?.sheetName !== "far") {
      return acc;
    }

    if (
      item?.filter_7 ===
      `${item.zoningDistrict} & ${item.commercialOverlay}____ SF`
    ) {
      withinLabel = `${item.zoningDistrict} & ${item.commercialOverlay} & Within 100 FT of Wide Street____ SF`;
      notWithInLabel = `${item.zoningDistrict} & ${item.commercialOverlay} & Not Within 100 FT of Wide Street____ SF`;
      keys.push(withinLabel);
      keys.push(notWithInLabel);
    } else {
      keys.push(item?.filter_7);
    }

    if (item?.specialDistrict) {
      keys.push(item.subdistrict);
    }

    keys?.forEach((key) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key]?.push({
        index: item.index,
        sheetName: item.sheetName,
      });
    });

    return acc;
  }, {});

  const handleCheckboxChange = (key, sheetName) => {

    setSelectedKeys((prevSelected) => ({
      ...prevSelected,
      [key]: !prevSelected[key],
    }));
    if (key?.includes("____ SF")) {
      handleInputChange(key, lotArea);
    }
  };

  const handleInputChange = (key, value) => {
    setInputValues((prevInputValues) => {
      const newValues = { ...prevInputValues, [key]: value };
      return newValues;
    });
  };

  const finalData = {};

  if (transformedData) {
    for (const [key, entries] of Object.entries(transformedData)) {
      entries.forEach((entry) => {
        const { sheetName } = entry;

        if (!finalData[sheetName]) {
          finalData[sheetName] = {};
        }

        if (!finalData[sheetName][key]) {
          finalData[sheetName][key] = [];
        }

        finalData[sheetName][key].push(entry);
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="justify-center flex items-center font-bold text-3xl mt-4">
            <h1>Filter Form</h1>
          </div>

          <div className="flex items-center sm:justify-center justify-start h-screen overflow-auto">
            <form
              onSubmit={(event) =>
                handleSubmit(
                  event,
                  selectedKeys,
                  inputValues,
                  transformedData,
                  address,
                  user,
                  setformSubmitted,
                  checkSelectedFilter,
                  withinLabel,
                  notWithInLabel,
                  setLoading
                )
              }
              className="bg-white shadow-md rounded-lg"
            >
              <div className="p-6 bg-white shadow-md rounded-lg space-y-4">
                {finalData && Object.keys(finalData)?.length > 0 ? (
                  Object.keys(finalData)?.map((sheetName) => {
                    return (
                      <div key={sheetName}>
                        <div className="text-3xl font-semibold text-transform: capitalize">
                          {sheetName}
                        </div>
                        {Object.keys(finalData[sheetName])?.map((key) => (
                          <div key={key}>
                            <div className="flex items-center space-x-3 my-3.5">
                              <label className="flex items-center space-x-2 text-gray-800 text-sm">
                                {key !== "All" && (
                                  <input
                                    type="checkbox"
                                    checked={!!selectedKeys[key]}
                                    onChange={() =>
                                      handleCheckboxChange(key, sheetName)
                                    }
                                    className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                  />
                                )}
                                {key !== "All" && (
                                  <span className="text-nowrap">
                                    {key?.split("____")[0]}
                                  </span>
                                )}
                                {key !== "All" && key?.includes("____ SF") && (
                                  <div className="flex">
                                    <input
                                      onChange={(e) =>
                                        handleInputChange(key, e.target.value)
                                      }
                                      // value={inputValues[key] || ""}
                                      value={inputValues[key] ?? lotArea}
                                      placeholder="Enter area of lot"
                                      type="number"
                                      disabled={!selectedKeys[key]}
                                      className="border-b-2 bg-white"
                                    />
                                    {key !== "All" && (
                                      <span className="ml-2">
                                        {key?.split("____")[1]}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </label>
                            </div>
                            <div className="">
                              {!inputValues[key] &&
                                selectedKeys[key] &&
                                sheetName === "far" && (
                                  <span className="text-red-500">
                                    Please Enter lot area
                                  </span>
                                )}
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  })
                ) : (
                  <p className="font-bold">Please click on Submit..</p>
                )}
              </div>
              <div className="pt-4">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white font-medium px-4 py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicFormWebsite;

import { useState, useEffect } from "react";
import { getApi, postApi } from "../../../Api/Api";
import SubscriptionCard from "./userSubscriptionPage";
import { useAuth } from "../../../context/AuthContext";
// Create a single event target instance for subscription events
const subscriptionEvents = new EventTarget();

const SubscriptionStatus = ({ token, showSummary = true, onSubscriptionUpdate }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        if (!token) return null;
        const response = await postApi(
          {
            token,
          },
          "payment/get-payment",
          token
        );
        setSubscriptionDetails(response.data);
        onSubscriptionUpdate?.(response.data.remainingQuantity > 0);
      } catch (error) {
        console.error("Error fetching subscription details:", error);
      }
    };

    fetchSubscriptionDetails();

    // Set up event listener
    const handleSubscriptionUpdate = () => {
      fetchSubscriptionDetails();
    };

    subscriptionEvents.addEventListener('subscription-update', handleSubscriptionUpdate);

    // Cleanup
    return () => {
      subscriptionEvents.removeEventListener('subscription-update', handleSubscriptionUpdate);
    };
  }, [token, onSubscriptionUpdate]);

  const isExpired = subscriptionDetails && new Date(subscriptionDetails.expiredAt).getTime() < Date.now();

  if (showSummary) {
    return (
      <>
        {!subscriptionDetails && (
          <span className="p-4">
            No subscription found
          </span>
        )}
        {subscriptionDetails && isExpired && (
          <span className="p-4">
            {subscriptionDetails.planName} (Expired)
          </span>
        )}
        {subscriptionDetails && subscriptionDetails.isSubscribed && subscriptionDetails.remainingQuantity > 0 && (
          <span className="p-4">
            {subscriptionDetails.planName} ({subscriptionDetails.remainingQuantity} lot(s) remaining)
          </span>
        )}
        {subscriptionDetails && subscriptionDetails.remainingQuantity <= 0 && (
          <span className="p-4">
            {subscriptionDetails.planName} (No lots remaining)
          </span>
        )}
      </>
    );
  }
  return (
    <div className="mb-4 p-4 bg-white rounded-lg shadow">
      <h3 className="font-semibold text-lg mb-2">Subscription Status</h3>
      <div className="space-y-2">
        {subscriptionDetails && (
          <>
            <p>Plan: {subscriptionDetails.planName}</p>
            <p className={subscriptionDetails.remainingQuantity <= 0 ? "text-red-600 font-semibold" : ""}>
              Remaining Analyses: {subscriptionDetails.remainingQuantity}
            </p>
            <p>
                {subscriptionDetails.expiredAt && (
                    <span className={isExpired ? "text-red-600 font-semibold" : ""}>
                        {isExpired ? "Expired: " : "Expires: "}
                        {new Date(subscriptionDetails.expiredAt).toLocaleDateString()}
                    </span>
                )}
            </p>
            <p>
                {subscriptionDetails.allowMapAccess ? "Map Access: Enabled" : "Map Access: Disabled"}
            </p>
          </>
        )}
        {!subscriptionDetails && (
          <p>No subscription found</p>
        )}
        {(!subscriptionDetails || !subscriptionDetails.isSubscribed) && (
          <SubscriptionCard />
        )}
      </div>
    </div>
  );
};

const Paywall = ({ mapView = false, children }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();

  useEffect(() => {
    // console.log("Paywall useEffect running, token:", token); // Debug log

    const fetchSubscriptionDetails = async () => {
      try {
        if (!token) {
          console.log("No token available, skipping fetch"); // Debug log
          setLoading(false);
          return null;
        }
        // console.log("Fetching subscription details..."); // Debug log
        const response = await postApi(
          {
            token,
          },
          "payment/get-payment",
          token
        );
        // console.log("Subscription details received:", response.data); // Debug log
        setSubscriptionDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscription details:", error);
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();

    // Also listen for subscription updates
    const handleSubscriptionUpdate = () => {
      fetchSubscriptionDetails();
    };

    subscriptionEvents.addEventListener('subscription-update', handleSubscriptionUpdate);

    return () => {
      subscriptionEvents.removeEventListener('subscription-update', handleSubscriptionUpdate);
    };
  }, [token]); // Keep token in dependency array

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (subscriptionDetails?.isSubscribed && (!mapView || subscriptionDetails.allowMapAccess)) {
    return children;
  }

  return (
    <div className="mb-4 p-4 bg-white rounded-lg shadow">
      <h3 className="font-semibold text-lg mb-2">Subscribe to Access</h3>
      <div className="space-y-2">
        {subscriptionDetails && (
          <>
            <p>Current Plan: {subscriptionDetails.planName}</p>
            <p className={subscriptionDetails.remainingQuantity <= 0 ? "text-red-600 font-semibold" : ""}>
              Remaining Analyses: {subscriptionDetails.remainingQuantity}
            </p>
            {mapView && !subscriptionDetails.allowMapAccess && (
              <p className="text-red-600 font-semibold">Your current plan does not include map access</p>
            )}
            {subscriptionDetails.expiredAt && (
              <p>
                <span className={new Date(subscriptionDetails.expiredAt).getTime() < Date.now() ? "text-red-600 font-semibold" : ""}>
                  {new Date(subscriptionDetails.expiredAt).getTime() < Date.now() ? "Expired: " : "Expires: "}
                  {new Date(subscriptionDetails.expiredAt).toLocaleDateString()}
                </span>
              </p>
            )}
          </>
        )}
        {!subscriptionDetails && (
          <p>No subscription found</p>
        )}
        <SubscriptionCard />
      </div>
    </div>
  );
};

const notifySubscriptionUpdate = () => {
  subscriptionEvents.dispatchEvent(new Event('subscription-update'));
};

export { SubscriptionStatus, notifySubscriptionUpdate, Paywall };


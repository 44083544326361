import React from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import FileUpload from "./FileUpload";
import Coupon from "./Coupon";
import { getApi, postApi, deleteApi } from "../../../Api/Api.js";
import { useState, useEffect } from "react";
import { useAuth } from "../../../context/AuthContext.js";

const SubscriptionPopover = ({ user, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    planName: user.subscription || 'Trial',
    totalQuantity: user.totalLots || 3,
    remainingQuantity: user.remainingLots || 3,
    expiresAt: user.expiresAt ? new Date(user.expiresAt).toISOString().split('T')[0] : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    allowMapAccess: user.allowMapAccess || true,
    isActive: user.isActive || false,
    skipActivationEmail: false,
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await onSave(user.email, formData);
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" 
      onClick={handleBackgroundClick}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseMove={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      onKeyUp={(e) => e.stopPropagation()}
    >
      <div 
        className="bg-white p-6 rounded-lg max-w-md w-full" 
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseMove={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        onKeyUp={(e) => e.stopPropagation()}
      >
        <h3 className="text-lg font-semibold mb-4">Edit User - {user.email}</h3>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Account Status</label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.isActive}
                    onChange={(e) => setFormData({...formData, isActive: e.target.checked})}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">Account Active</span>
                </label>
                {!user.isActive && formData.isActive && (
                  <div className="mt-2">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={formData.skipActivationEmail}
                        onChange={(e) => setFormData({...formData, skipActivationEmail: e.target.checked})}
                        className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      <span className="ml-2 text-sm text-gray-500">Skip sending activation email</span>
                    </label>
                  </div>
                )}
              </div>
              <p className="mt-1 text-xs text-gray-500">
                {formData.isActive 
                  ? "User will be able to log in and use the system" 
                  : "User won't be able to log in until activated"}
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Subscription Plan</label>
              <select
                value={formData.planName}
                onChange={(e) => setFormData({...formData, planName: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="Trial">Trial</option>
                <option value="Basic">Basic</option>
                <option value="Premium">Premium</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Total Lots</label>
              <input
                type="number"
                value={formData.totalQuantity}
                onChange={(e) => setFormData({...formData, totalQuantity: parseInt(e.target.value)})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Remaining Lots</label>
              <input
                type="number"
                value={formData.remainingQuantity}
                onChange={(e) => setFormData({...formData, remainingQuantity: parseInt(e.target.value)})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Expiration Date</label>
              <input
                type="date"
                value={formData.expiresAt}
                onChange={(e) => setFormData({...formData, expiresAt: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Features</label>
              <div className="mt-1">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.allowMapAccess}
                    onChange={(e) => setFormData({...formData, allowMapAccess: e.target.checked})}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm text-gray-700">Map Access</span>
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              disabled={isSaving}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSaving}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-blue-400 flex items-center"
            >
              {isSaving ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const { userEmail } = useAuth();
  const [analysesForReview, setAnalysesForReview] = useState([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState([]);
  const [verifiedUsers, setVerifiedUsers] = useState([]);
  const [sendingEmail, setSendingEmail] = useState('');
  const [editingUser, setEditingUser] = useState(null);

  const fetchData = React.useCallback(async () => {
    const [analysesResponse, unverifiedUsersResponse, verifiedUsersResponse] = await Promise.all([
      getApi('operator/zoning-analysis-review'),
      getApi('admin/unverified-users'),
      getApi('admin/verified-users')
    ]);
    setAnalysesForReview(analysesResponse);
    setUnverifiedUsers(unverifiedUsersResponse);
    setVerifiedUsers(verifiedUsersResponse);
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSendWelcomeEmail = async (email) => {
    try {
      setSendingEmail(email);
      await postApi({}, `admin/send-welcome-email/${email}`);
      alert('Welcome email sent successfully');
      fetchData();
    } catch (error) {
      console.error('Error sending welcome email:', error);
      alert('Failed to send welcome email');
    } finally {
      setSendingEmail('');
    }
  };

  const handleFormFill = () => {
    navigate("/operator");
  };

  const handleUpdateSubscription = async (email, subscriptionData) => {
    try {
      const { skipActivationEmail, ...data } = subscriptionData;
      await postApi({
        ...data,
        skipActivationEmail: skipActivationEmail || false
      }, `admin/update-subscription/${email}`);
      fetchData(); // Refresh the data
    } catch (error) {
      console.error('Error updating subscription:', error);
      alert('Failed to update subscription');
      throw error; // Re-throw to handle in the popover
    }
  };

  const handleDeleteUser = async (email) => {
    if (window.confirm(`Are you sure you want to delete user ${email}? This action cannot be undone.`)) {
      try {
        await deleteApi(`admin/delete-user/${email}`);
        fetchData(); // Refresh the data
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user');
      }
    }
  };

  return (
    <>
      <nav className="w-full max-w-screen px-4 py-2 mx-auto bg-white shadow-md rounded-md lg:px-8 lg:py-3 flex justify-between items-center">
        <span className="text-3xl font-sans font-bold">Admin Panel</span>

        <div>
          <button
            className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() => navigate('/admin/address-data')}
          >
            Address Data
          </button>
        </div>
      </nav>
      <div className="w-full p-7 rounded pb-12 mx-auto mt-8">
        <h2 className="text-lg font-semibold mb-4">Unverified Users</h2>
        {unverifiedUsers.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company Info
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Professional Info
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Registration Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {unverifiedUsers.map((user) => (
                  <tr key={user.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {user.firstName} {user.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.companyName || '-'}</div>
                      <div className="text-xs text-gray-500">{user.companyRole || ''}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.profession || '-'}</div>
                      {user.yearsOfExperience && (
                        <div className="text-xs text-gray-500">{user.yearsOfExperience} years exp.</div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div>Registered: {new Date(user.createdAt).toLocaleDateString()}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.lastVerificationEmailSent ? (
                        <span>
                          Last email sent: {new Date(user.lastVerificationEmailSent).toLocaleString()}
                        </span>
                      ) : (
                        <span className="text-red-500">
                          No email sent
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap space-x-3">
                      <button
                        onClick={() => handleSendWelcomeEmail(user.email)}
                        disabled={sendingEmail === user.email}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center"
                      >
                        {sendingEmail === user.email ? 'Sending...' : 'Send Welcome Email'}
                      </button>
                      <button
                        onClick={() => setEditingUser(user)}
                        className="text-blue-600 hover:text-blue-800 ml-2"
                      >
                        Edit
                      </button>
                      {user.email !== userEmail && (
                        <button
                          onClick={() => handleDeleteUser(user.email)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-sm text-gray-500">No unverified users found</p>
        )}
      </div>
      <div className="w-full p-7 rounded pb-12 mx-auto mt-8">
        <h2 className="text-lg font-semibold mb-4">Verified Users</h2>
        {verifiedUsers.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email (Role)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company Info
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Professional Info
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Registration
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Last Login
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Plan
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Lots (Used/Total)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {verifiedUsers.map((user) => (
                  <tr key={user.email}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.email} ({user.role})</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {user.firstName} {user.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.companyName || '-'}</div>
                      <div className="text-xs text-gray-500">{user.companyRole || ''}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.profession || '-'}</div>
                      {user.yearsOfExperience && (
                        <div className="text-xs text-gray-500">{user.yearsOfExperience} years exp.</div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {user.lastLogin ? new Date(user.lastLogin).toLocaleDateString() : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.isActive ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {user.isActive ? 'Active' : 'Pending Activation'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        user.isSubscribed ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {user.subscription}
                        {user.expiresAt ? ', expires ' + new Date(user.expiresAt).toLocaleDateString() : ''}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold ${
                        user.remainingLots > 0 ? 'text-green-800' : 'text-red-800'
                      }`}>
                        {user.totalLots - user.remainingLots}/{user.totalLots}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap space-x-3">
                      <button
                        onClick={() => setEditingUser(user)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Edit
                      </button>
                      {user.email !== userEmail && (
                        <button
                          onClick={() => handleDeleteUser(user.email)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-sm text-gray-500">No verified users found</p>
        )}
      </div>
      <div className="w-[50%] bg-white p-7 rounded pb-12 mx-auto mt-8">
        <FileUpload />
      </div>
      <div className="recent-analyses">
        <h2 className="text-lg font-semibold mb-4">Analyses for Review</h2>
        {analysesForReview.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Analysis
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Client email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date created
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {analysesForReview.map((analysis, index) => (
                  <tr
                    key={index}
                    onClick={() => navigate(`/review/${analysis._id}`)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {analysis.address.join('; ')}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                              ${
                                analysis.status === "ready"
                                  ? "bg-green-100 text-green-800"
                                  : analysis.status === "pending"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : "bg-gray-100 text-gray-800"
                              }`}
                      >
                        {analysis.userId}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(analysis.updatedAt).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-sm text-gray-500">No recent analyses found</p>
        )}
      </div>
      {/* <Coupon /> */}
      {editingUser && (
        <SubscriptionPopover
          user={editingUser}
          onClose={() => setEditingUser(null)}
          onSave={handleUpdateSubscription}
        />
      )}
    </>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getApi, postApi } from "../../../Api/Api";

const DynamicForm = () => {
  const [selectedKeys, setSelectedKeys] = useState({});
  const [filters, setFilters] = useState([]);
  const [lotArea, setLotArea] = useState();
  const [address, setAddress] = useState("");
  const [formSubmited, setformSubmitted] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const location = useLocation();
  const [yardSelectedFilters, setSelectedFilters] = useState({});
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [submitLoading, setSumbitLoading] = useState(false);

  const { id } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const user = searchParams.get("user");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getApi(`operator/operator-filters/${id}`);
        setLotArea(data?.lotArea);
        setFilters(data?.sendFilter);
        setAddress(data?.address);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let withinLabel;
  let notWithInLabel;

  const checkLable = (label) => {
    if (label === withinLabel || label === notWithInLabel) {
      let zoningLable = label.replace(/ & C[^&]*/g, " ");
      return zoningLable;
    }
    return label;
  };

  const checkSelectedFilter = (selectedFilter) => {
    const farData = finalData?.far;
    const filterKeys = Object.keys(selectedFilter);
    Object.keys(selectedFilter)?.map((item) => {
      if (
        filterKeys.includes(withinLabel) &&
        !filterKeys.includes(checkLable(item))
      ) {
        const label = checkLable(item);
        const value = farData[label]?.map((item) => ({
          ...item,
          input: 0,
          label: label,
        }));
        if (value) {
          selectedFilter[label] = value;
        }
      } else if (
        filterKeys.includes(notWithInLabel) &&
        !filterKeys.includes(checkLable(item))
      ) {
        const label = checkLable(item);
        const value = farData[label]?.map((item) => ({
          ...item,
          input: 0,
          label: label,
        }));
        if (value) {
          selectedFilter[label] = value;
        }
      }
    });
    return selectedFilter;
  };

  const mergedData = filters?.flatMap((item) => {
    return Object.keys(item)?.map((sheetName) => {
      const {
        filterlayerSD_ZD = [],
        filterlayerSD_ZD_CO = [],
        filterlayerZD = [],
        filterlayerZD_CO = [],
      } = item[sheetName];

      let mergedArray = [];
      if (sheetName !== "use_group") {
        mergedArray = [
          ...filterlayerSD_ZD,
          ...filterlayerSD_ZD_CO,
          ...filterlayerZD,
          ...filterlayerZD_CO,
        ];
      } else {
        mergedArray = item.use_group;
      }

      return { sheetName: sheetName, datafilters: mergedArray };
    });
  });

  // Step 2: Flatten the merged array
  const flattenedData = mergedData?.flat();

  if (formSubmited) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-sky-100 border border-sky-300 text-sky-800 rounded-lg p-4 shadow-md">
          <h2 className="text-xl font-semibold">Your Form has submitted!</h2>
        </div>
      </div>
    );
  }

  const filteredData = flattenedData?.flatMap(({ sheetName, datafilters }) =>
    datafilters?.map((filter) => ({
      index: filter.index,
      sheetName: sheetName,
      zoningDistrict: filter["Zoning District (Zola)"],
      commercialOverlay: filter["Commercial Overlay (Zola)"],
      specialDistrict: filter["Special District (Zola)"],
      subdistrict: filter["Subdistrict (Operator to Select in Step 1)"],
      filter_5: filter["Filter 5 (Operator to Select in Step 1)"],
      filter_6: filter["Filter 6 (Operator to Select in Step 1)"],
      filter_7: filter["Filter 7 (Operator to Select in Step 1)"],
      ...(sheetName === "yards" && {
        filter_5_1: filter["Filter 5.1 (Operator to Select in Step 1)"],
      }),
    }))
  );

  const transformedData = filteredData?.reduce((acc, item) => {
    let keys = [];

    if (
      item?.sheetName == "yards" ||
      item?.sheetName == "use_group" ||
      item?.filter_7 == "____ SF"
    ) {
      return acc;
    }

    // If filter_7 contains "R6 & C1-3____ SF", create two new keys but exclude the original key
    if (
      item?.filter_7 ===
      `${item.zoningDistrict} & ${item.commercialOverlay}____ SF`
    ) {
      withinLabel = `${item.zoningDistrict} & ${item.commercialOverlay} & Within 100 FT of Wide Street____ SF`;
      notWithInLabel = `${item.zoningDistrict} & ${item.commercialOverlay} & Not Within 100 FT of Wide Street____ SF`;
      keys.push(withinLabel);
      keys.push(notWithInLabel);
    } else {
      // Add filter_7 key only if it's not "R6 & C1-3____ SF"
      keys.push(item?.filter_7);
    }

    if (item?.specialDistrict) {
      keys.push(item.subdistrict);
    }

    keys?.forEach((key) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key]?.push({
        index: item.index,
        sheetName: item.sheetName,
      });
    });

    return acc;
  }, {});

  const transformedDataYardWithoutSuFilters = filteredData?.reduce(
    (acc, item) => {
      let keys = [item?.filter_6, item?.filter_5, item?.filter_5_1];

      if (item?.sheetName !== "yards") {
        return acc;
      }

      if (item?.specialDistrict) {
        keys.push(item.subdistrict);
      }

      keys?.forEach((key) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key]?.push({
          index: item.index,
          sheetName: item.sheetName,
        });
      });

      return acc;
    },
    {}
  );

  const yardsTransformedData = (data) => {
    const structuredData = {};

    data?.forEach((item) => {
      const { filter_5, filter_5_1, filter_6, index, sheetName } = item;

      if (sheetName !== "yards") {
        return;
      }

      if (!structuredData[filter_5]) {
        structuredData[filter_5] = { subFilters: [], indices: [] };
      }

      const existingSubFilter = structuredData[filter_5]?.subFilters?.find(
        (sub) => sub.subFilter === filter_5_1
      );

      if (existingSubFilter) {
        existingSubFilter.indices.push({ index, sheetName });
      } else {
        structuredData[filter_5].subFilters.push({
          subFilter: filter_5_1,
          indices: [{ index, sheetName }],
        });
      }

      if (!structuredData[filter_6]) {
        structuredData[filter_6] = { indices: [] };
      }
      structuredData[filter_6].indices.push({ index, sheetName });

      structuredData[filter_5].indices.push({ index, sheetName, input: "" });
    });

    return structuredData;
  };

  const structuredData = yardsTransformedData(filteredData);

  const handleCheckboxChange = (key, sheetName) => {
    setSelectedKeys((prevSelected) => ({
      ...prevSelected,
      [key]: !prevSelected[key],
    }));
    if (sheetName == "far" && key?.includes("____ SF")) {
      handleInputChange(key, lotArea);
    }
  };

  const handleInputChange = (key, value) => {
    setInputValues((prevInputValues) => {
      const newValues = { ...prevInputValues, [key]: value };
      return newValues;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedyardFilters = Object.keys(yardSelectedFilters)
      .filter((key) => yardSelectedFilters[key])
      ?.reduce((acc, key) => {
        acc[key] = [
          ...transformedDataYardWithoutSuFilters[key].map((item) => ({
            ...item,
            input: parseFloat(inputValues[key]),
          })),
        ];
        return acc;
      }, {});

    let selectedFilters = Object.keys(selectedKeys)
      .filter((key) => selectedKeys[key])
      ?.reduce((acc, key) => {
        acc[key] = [
          ...transformedData[key].map((item) => ({
            ...item,
            input: parseFloat(inputValues[key]),
            label: key,
          })),
        ];
        return acc;
      }, {});

    // Define the required counts for each sheetName
    try {
      const requiredCounts = {
        // far: 3,
        yards: 3,
        // height: 2,
        // use_group: 2,
      };

      selectedFilters = checkSelectedFilter(selectedFilters);
      const yardFilterSelected = Object.values(selectedyardFilters).flat();
      const filterSelected = Object.values(selectedFilters).flat();

      const selectAllOfYard = transformedDataYardWithoutSuFilters["All"]
        ? transformedDataYardWithoutSuFilters["All"]
        : [];
      const selectAll = transformedData["All"] ? transformedData["All"] : [];

      const filter_to_send = [...yardFilterSelected, ...selectAllOfYard];

      const filter_to_send2 = [...filterSelected, ...selectAll];

      function getRequiredCombinations(data, counts) {
        const combinationCount = {};
        data?.forEach((item) => {
          const key = `${item.index}-${item.sheetName}`;
          if (!combinationCount[key]) {
            combinationCount[key] = { ...item, count: 0 };
          }
          combinationCount[key].count += 1;
        });
        return Object.values(combinationCount).filter((item) => {
          return (
            counts[item.sheetName] && item.count === counts[item.sheetName]
          );
        });
      }

      const all_filter_data = [
        ...getRequiredCombinations(filter_to_send, requiredCounts),
        ...filter_to_send2,
      ];
      const dataTosubmit = {
        address: address,
        selectedFilters: all_filter_data,
        user: user,
      };
      let hasError = false;
      dataTosubmit["selectedFilters"]?.map((item, index) => {
        if (
          item["sheetName"] === "far" &&
          Object.keys(item).includes("input") &&
          isNaN(item["input"])
        ) {
          hasError = true;
        }
      });
      if (hasError) {
        return;
      }
      setSumbitLoading(true);
      const response = await postApi(
        dataTosubmit,
        "operator/operator-response-filter"
      );
      if (response) {
        setSumbitLoading(false);
      }
      console.log("Form submitted successfully:", response);
      setformSubmitted(true);
    } catch (error) {
      setSumbitLoading(false);
      console.error("Error submitting form:", error);
    }

    console.log("Selected Filters for submission:", selectedFilters);
  };

  const handleFilterChange = (filterName, indices) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));
  };

  const handleSubFilterChange = (filterName, subFilter, indices) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [subFilter]: !prev[subFilter],
    }));
  };

  const finalData = {};

  // console.log({transformedData})

  for (const [key, entries] of Object.entries(transformedData)) {
    entries.forEach((entry) => {
      const { sheetName } = entry;

      // Initialize the sheetName object if it doesn't exist
      if (!finalData[sheetName]) {
        finalData[sheetName] = {};
      }

      // Initialize the array for the current key if it doesn't exist
      if (!finalData[sheetName][key]) {
        finalData[sheetName][key] = [];
      }

      // Push the entry to the appropriate array
      finalData[sheetName][key].push(entry);
    });
  }

  console.log({ finalData });  
  return (
    <div className="flex items-center text-sm">
      <form
        onSubmit={handleSubmit}
        className="p-6 bg-white shadow-md rounded-lg space-y-4 mx-auto mt-[10%]"
      >
        <div className="p-6 bg-white shadow-md rounded-lg space-y-4 mx-auto">
          {finalData && Object.keys(finalData)?.length > 0 ? (
            Object.keys(finalData)?.map((sheetName) => {
              return (
                <div>
                  <div className="text-3xl font-semibold text-transform: capitalize">
                    {sheetName}
                  </div>
                  {Object.keys(finalData[sheetName])?.map((key) => (
                    <div>
                      <div
                        key={key}
                        className="flex items-center space-x-3 my-3.5"
                      >
                        <label className="flex items-center space-x-2 text-gray-800 text-sm">
                          {key !== "All" && (
                            <input
                              type="checkbox"
                              checked={!!selectedKeys[key]}
                              onChange={() =>
                                handleCheckboxChange(key, sheetName)
                              }
                              className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            />
                          )}
                          {key !== "All" && (
                            <span className="text-nowrap">
                              {key?.split("____")[0]}
                            </span>
                          )}
                          {key !== "All" && key?.includes("____ SF") && (
                            <div className="flex">
                              <input
                                onChange={(e) =>
                                  handleInputChange(key, e.target.value)
                                }
                                // value={inputValues[key] || lotArea}
                                value={inputValues[key] ?? lotArea}
                                placeholder="Enter area of lot"
                                type="number"
                                disabled={!selectedKeys[key]}
                                className="border-b-2 bg-white"
                              />
                              {key !== "All" && (
                                <span className="ml-2">
                                  {key?.split("____")[1]}
                                </span>
                              )}
                            </div>
                          )}
                        </label>
                      </div>
                      <div className="">
                        {!inputValues[key] &&
                          selectedKeys[key] &&
                          key?.includes("____ SF") &&
                          sheetName == "far" && (
                            <span className="text-red-500">
                              Please Enter lot area
                            </span>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <p className="font-bold">Please click on Submit..</p>
          )}
          <div>
            <div className="text-3xl font-semibold">Yards</div>
            {structuredData &&
              Object.keys(structuredData)?.length > 0 &&
              Object.keys(structuredData)?.map((filterName) => (
                <div key={filterName} className="my-2">
                  {filterName !== "All" && (
                    <label>
                      <input
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-2"
                        type="checkbox"
                        checked={!!yardSelectedFilters[filterName]}
                        onChange={() => handleFilterChange(filterName)}
                      />
                      {filterName}
                    </label>
                  )}
                  <div className="flex flex-col ml-4">
                    {structuredData[filterName]?.subFilters?.map(
                      (subFilter, index) =>
                        subFilter?.subFilter !== "All" &&
                        subFilter?.subFilter !== "" && (
                          <label
                            className="my-1"
                            key={index}
                            style={{
                              color: yardSelectedFilters[filterName]
                                ? "black"
                                : "gray",
                            }}
                          >
                            <input
                              className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 mr-2"
                              type="checkbox"
                              disabled={!yardSelectedFilters[filterName]}
                              onChange={() =>
                                handleSubFilterChange(
                                  filterName,
                                  subFilter.subFilter,
                                  subFilter.indices
                                )
                              }
                            />
                            {subFilter.subFilter}
                          </label>
                        )
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-blue-600 text-white font-medium px-4 py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={submitLoading}
          >
            {submitLoading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.137.84 4.084 2.209 5.5l2.5-2.209z"
                  ></path>
                </svg>
                <span className="ml-2">Submitting...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default DynamicForm;

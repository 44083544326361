import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { postApi } from '../../Api/api';
import { getApi } from '../../../Api/Api.js';
import Cookies from 'js-cookie';
import { useAuth } from '../../../context/AuthContext.js';
import { SubscriptionStatus } from '../../../pages/Home/components/SubscriptionStatus';

const Home = () => {
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recentAnalyses, setRecentAnalyses] = useState([]);
  const [isLoadingRecentAnalyses, setIsLoadingRecentAnalyses] = useState(false);
  const navigate = useNavigate();
  let { token } = useAuth();

  console.log('Home component rendering');

  const fetchRecentAnalyses = useCallback(async () => {
    console.log('fetching recent analyses');
    setIsLoadingRecentAnalyses(true);
    try {
      const response = await getApi('operator/zoning-analysis', token);
      setRecentAnalyses(response || []);
    } catch (err) {
      console.error('Failed to fetch recent analyses:', err);
    } finally {
      setIsLoadingRecentAnalyses(false);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      console.log('Authenticated, fetching analyses');
      setIsVerified(true);
      fetchRecentAnalyses();
      navigate('/property-search');
    }

    return () => {
      console.log('Home component unmounting');
    };
  }, [fetchRecentAnalyses, token, navigate]);

  // Email validation regex
  const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!EMAIL_REGEX.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    try {
      const response = await postApi({email: email}, `/user/verify-email`);
      
    //   console.log('data=', response);
      const data = response.data;
      if (data.user && data.token) {
        if (!data.user.is_email_verified) {
          setIsLoading(false);
          return;
        }
        setIsVerified(true);
        Cookies.set("authToken", data.token, { expires: 1.5 / 24 });
        window.dispatchEvent(new Event('auth-update'));
        navigate('/');
        token = data.token;
        fetchRecentAnalyses();
      } else {
        setError(
            <div className="bg-blue-50 border border-blue-200 text-blue-800 px-4 py-3 rounded relative" role="alert">
              <p className="font-medium">Thank you for your interest!</p>
              <p className="text-sm mt-2">
                We've received your registration request. Our team will review it shortly and send you a verification email.
                Please check your inbox (and spam folder) in the next few hours.
              </p>
            </div>
          );
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    }
    setIsLoading(false);
  };

  const handleOperatorFilterClick = () => {
    const authToken = Cookies.get("authToken");
    console.log('Attempting navigation with token:', authToken);
    if (authToken) {
      navigate('/zoning-analysis');
    } else {
      console.log('No auth token found');
      setError('Please verify your email first');
    }
  };

  return (
    <div className="home-container">
      <div className="content-wrapper">
        <h1 className="logo">NYC Zoning AI</h1>
        <p className="tagline">
          Intelligent zoning analysis for New York City real estate
        </p>

        {!isVerified ? (
          <form className="email-form" onSubmit={handleEmailSubmit}>
            <div className="input-wrapper">
              <input
                className="email-input"
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />
              <button 
                className="submit-button" 
                type="submit" 
                disabled={isLoading}
              >
                {isLoading ? 'Verifying...' : 'Get Started'}
              </button>
            </div>
            {error && <div className="error-message">{error}</div>}
          </form>
        ) : (
          <>
            <div className="options-container">
              <div 
                className="option" 
                onClick={() => navigate('/property-search')}
              >
                <h2 className="option-title">Property Search</h2>
                <p className="option-description">
                  Search and analyze properties across NYC
                </p>
              </div>
              <div 
                className="option" 
                onClick={handleOperatorFilterClick}
              >
                <h2 className="option-title">Search by Address</h2>
                <p className="option-description">
                  Get detailed zoning analysis for specific addresses
                </p>
              </div>
            </div>
            
            <div className="recent-analyses">
              <h2 className="text-lg font-semibold mb-4">Recent Analyses</h2>
              {isLoadingRecentAnalyses ? (
                <p>Loading...</p>
              ) : recentAnalyses.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Analysis
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {recentAnalyses.slice(0, 10).map((analysis, index) => (
                        <tr 
                          key={index}
                          onClick={() => navigate(`/zoning-analysis/${analysis._id}`)}
                          className="hover:bg-gray-50 cursor-pointer"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {analysis.label || analysis.address}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                              ${analysis.status === 'ready' ? 'bg-green-100 text-green-800' : 
                                analysis.status === 'pending' ? 'bg-yellow-100 text-yellow-800' : 
                                'bg-gray-100 text-gray-800'}`}
                            >
                              {analysis.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(analysis.updatedAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-sm text-gray-500">No recent analyses found</p>
              )}
            </div>

            <div className="mt-4">
              <SubscriptionStatus token={token} showSummary={false} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;

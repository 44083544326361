import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { getApi } from "../../Api/Api";

const EmailVerified = () => {
  const location = useLocation();
  const [status, setStatus] = useState("verifying");
  const [verificationData, setVerificationData] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    const verifyEmail = async () => {
      try {
        const response = await getApi(`verifyEmail?token=${token}`);
        if (response && response.success) {
          Cookies.set("is_email_verified", true);
          Cookies.set("authToken", token, { expires: 1.5 / 24 });
          setVerificationData(response);
          setStatus("success");
        } else {
          console.error('Email verification failed:', response);
          setVerificationData(response);
          setStatus("failed");
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationData({
          title: 'Email Verification Failed',
          message: 'An error occurred during verification. Please try again or contact support.'
        });
        setStatus("failed");
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [location]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-lg w-full text-center p-8 border border-gray-200 rounded-xl bg-white shadow-lg">
        {status === "verifying" ? (
          <>
            <div className="animate-pulse">
              <h1 className="text-3xl font-bold text-blue-600 mb-4">
                Verifying Your Email
              </h1>
              <p className="text-gray-600">
                Please wait while we verify your email address...
              </p>
            </div>
          </>
        ) : status === "failed" ? (
          <>
            <div className="text-red-600 mb-6">
              <svg className="w-16 h-16 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h1 className="text-3xl font-bold text-red-600 mb-4">
              {verificationData?.title || 'Email Verification Failed'}
            </h1>
            <p className="text-gray-600 mb-6">
              {verificationData?.message || 'We encountered an error while verifying your email.'}
            </p>
            <div className="space-y-4">
              <p className="text-gray-600">
                Need help? Contact us at{' '}
                <a href="mailto:contact@nyczoning.ai" className="text-blue-600 hover:text-blue-800 underline">
                  contact@nyczoning.ai
                </a>
              </p>
              <Link 
                to="/" 
                className="inline-block px-6 py-2 text-blue-600 border border-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
              >
                Return to Home Page
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="text-green-600 mb-6">
              <svg className="w-16 h-16 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h1 className="text-3xl font-bold text-green-600 mb-4">
              {verificationData?.title}
            </h1>
            <p className="text-gray-700 mb-6">
              {verificationData?.message}
            </p>
            {verificationData?.nextSteps && (
              <div className="mb-8 text-left">
                <ul className="space-y-2">
                  {verificationData.nextSteps.map((step, index) => (
                    <li key={index} className="flex items-start">
                      <span className="text-green-500 mr-2">✓</span>
                      <span className="text-gray-600">{step}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="space-x-4">
              {verificationData?.isActive ? (
                <Link 
                  to="/login" 
                  className="inline-block px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                  Log In Now
                </Link>
              ) : (
                <Link 
                  to="/" 
                  className="inline-block px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Return to Home
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailVerified;

const RentalPropertyCalculator = () => {
  const years = 20;

  return (
    <>
      <div className="overflow-x-auto h-[300px] p-4">
        <table className="min-w-full border text-sm border-gray-300 text-left">
          <thead>
            <tr>
              <th
                colSpan="2"
                className="border border-gray-300 px-4 py-2 text-center bg-gray-100 font-bold"
              >
                For the {years} Years Invested
              </th>

              <th
                colSpan="3"
                className="border border-gray-300 px-4 py-2 text-center bg-gray-100 font-bold"
              >
                For the {years} Years Invested
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Return (IRR)</td>
              <td className="border border-gray-300 px-4 py-2">
                $XXXXXXX/year
              </td>

              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">Monthly</td>
              <td className="border border-gray-300 px-4 py-2">Annually</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4  py-2">
                Total Profit when Sold
              </td>
              <td className="border border-gray-300 px-4 py-2">
                $XXXXXXX/year
              </td>

              <td className="border border-gray-300 px-4 py-2">Income</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Cash on Cash Return
              </td>
              <td className="border border-gray-300 px-4 py-2">xxx %</td>

              <td className="border border-gray-300 px-4 py-2">Mortage Pay</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Capitalization Rate
              </td>
              <td className="border border-gray-300 px-4 py-2">xxx %</td>

              <td className="border border-gray-300 px-4 py-2">Vacancy (5%)</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Total Rental Income
              </td>
              <td className="border border-gray-300 px-4 py-2">$xxx,xxx</td>

              <td className="border border-gray-300 px-4 py-2">Property Tax</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Total Mortgage Payment
              </td>
              <td className="border border-gray-300 px-4 py-2">$xxx,xxx</td>
              <td className="border border-gray-300 px-4 py-2">
                Total Insurance
              </td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Total Expenses
              </td>
              <td className="border border-gray-300 px-4 py-2">$xxx,xxx</td>
              <td className="border border-gray-300 px-4 py-2">
                Maintenance Cost
              </td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2">
                Total Operating Expenses
              </td>
              <td className="border border-gray-300 px-4 py-2">$xxx,xxx</td>
              <td className="border border-gray-300 px-4 py-2">Other Cost</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">Cash Flow</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>

            <tr>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">
                Net Operating Income
              </td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
              <td className="border border-gray-300 px-4 py-2">XXXXX</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RentalPropertyCalculator;

import ApplyChanges from "./ApplyChanges";

const RenovationSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }
    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  return (
    <>
      <div className="px-4 pb-3 text-gray-600">
        <div className="flex items-center my-2 justify-between">
          <label className="text-gray-700 text-nowrap w-1/3">
            Renovation Unit Cost
          </label>
          <div className="flex flex-col w-full items-end">
            <div className="flex space-x-2 w-full justify-end">
              <input
                type="text"
                name="renovationUnitCost"
                className="border-[1px] px-1 rounded w-1/2 border-black"
                value={toggleData?.renovationUnitCost || "--"}
                onChange={handleChange}
              />$/SF
            </div>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="text-gray-700 text-nowrap w-1/3">
            Estimated Renovation Cost
          </label>
          <div className="flex flex-col w-full items-end">
            <div className="flex space-x-2 w-full justify-end">
              <input
                type="text"
                name="ECC"
                className="border-[1px] px-1 rounded w-1/2 border-gray-400 bg-gray-100"
                value={toggleData?.ECC || "--"}
                onChange={handleChange}
                disabled
              />$
            </div>
          </div>
        </div>
        <ApplyChanges
          updateCapData={updateCapData}
          capData={capData}
          currentTable={currentTable}
          isUpdateCapDataLoading={isUpdateCapDataLoading}
        />
      </div>
    </>
  );
};

export default RenovationSection;

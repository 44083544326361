import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import ApplyChanges from "./ApplyChanges";

const ManagementSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const [additionalSections, setAdditionalSections] = useState([]);

  const addSection = () => {
    const newId = additionalSections.length
      ? additionalSections[additionalSections.length - 1].id + 1
      : 1;
    setAdditionalSections([
      ...additionalSections,
      { id: newId, label: `New Section ${newId}` },
    ]);
  };

  const removeSection = (id) => {
    setAdditionalSections(
      additionalSections.filter((section) => section.id !== id)
    );
  };

  const handleLabelChange = (id, newLabel) => {
    setAdditionalSections(
      additionalSections.map((section) =>
        section.id === id ? { ...section, label: newLabel } : section
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    let finalValue = validValue;

    if (name === "value_apperication") {
      const numericValue = parseFloat(validValue);
      if (numericValue > 100) {
        finalValue = "100";
      } else if (numericValue < 0) {
        finalValue = "0";
      }
    }

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: finalValue,
      },
    }));
  };

  return (
    <div className="px-4 pb-3 text-gray-600">
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Property Tax</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.Tax || "--"}
            name="Tax"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">% </span>
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.property_tax || "--"}
            name="property_tax"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Insurance</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.average_insurance_rate || "--"}
            name="average_insurance_rate"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.Insurance || "--"}
            name="Insurance"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">MRATE</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.MRATE || "--"}
            name="MRATE"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/SF/Year</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Maintenance Per Year</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.maintainenceperyear || "--"}
            name="maintainenceperyear"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Management Fee</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.management_fee || "--"}
            name="management_fee"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">%</span>
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.management_cost || "--"}
            name="management_cost"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Value Appreciation</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.value_apperication || "--"}
            name="value_apperication"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">%</span>
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Holding Years</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="ms-4 border-[1px] px-1 rounded w-2/3 border-black text-center"
            value={toggleData?.holding_years || "--"}
            name="holding_years"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Cost to Sell</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.cost_to_sell || "--"}
            name="cost_to_sell"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">%</span>
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/4 border-black text-center"
            value={toggleData?.total_cost_to_sell || "--"}
            name="total_cost_to_sell"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$</span>
        </div>
      </div>
    </div>
  
    {/* Repeat similar alignment for additional sections */}
    {additionalSections.map((section) => (
      <div key={section.id}>
        <div className="flex items-center my-2 justify-between">
          <div className="w-1/3">
            <input
              type="text"
              value={section.label}
              onChange={(e) => handleLabelChange(section.id, e.target.value)}
              className="border-[1px] px-1 rounded w-full border-black text-center"
            />
          </div>
          <div className="flex flex-col w-2/3 items-end">
            <div className="flex space-x-2 w-full justify-end">
              <input
                type="text"
                className="border-[1px] px-1 rounded w-1/4 border-black text-center"
              />
              <input
                type="text"
                className="border-[1px] px-1 rounded w-1/4 border-black text-center"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => removeSection(section.id)}
            className="text-blue-500 text-sm"
          >
            Delete
          </button>
        </div>
      </div>
    ))}
  
    <button onClick={addSection} className="flex items-center mt-4 text-blue-500">
      <FaPlus className="mr-2" /> Add
    </button>
  
    <div className="flex items-center my-2 justify-between">
      <label className="text-gray-700 w-1/3 text-left">Total Management Costs</label>
      <div className="flex flex-col w-2/3 items-end">
        <div className="flex space-x-2 w-full justify-end">
          <input
            type="text"
            disabled
            className="border-[1px] px-1 rounded w-1/4 border-gray-400 bg-gray-100 text-center"
            value={toggleData?.TotalManagementCostValue || "--"}
            name="TotalManagementCostValue"
            onChange={handleChange}
          />
          <span className="w-1/6 text-center">$/Year</span>
        </div>
      </div>
    </div>
    <ApplyChanges
      updateCapData={updateCapData}
      capData={capData}
      currentTable={currentTable}
      isUpdateCapDataLoading={isUpdateCapDataLoading}
    />
  </div>
  
  );
};

export default ManagementSection;

import { getApi, postApi } from "../Api/api";

export const fetchData = async (id) => {
  try {
    const data = await getApi(`operator/operator-filters/${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const checkLable = (label, withinLabel, notWithInLabel) => {
  if (label === withinLabel || label === notWithInLabel) {
    let zoningLable = label.replace(/ & C[^&]*/g, " ");
    return zoningLable;
  }
  return label;
};

export const checkSelectedFilter = (
  selectedFilter,
  finalData,
  withinLabel,
  notWithInLabel
) => {
  const farData = finalData?.far || {};
  const filterKeys = Object.keys(selectedFilter);
  Object.keys(selectedFilter)?.map((item) => {
    if (
      filterKeys.includes(withinLabel) &&
      !filterKeys.includes(checkLable(item, withinLabel, notWithInLabel))
    ) {
      const label = checkLable(item, withinLabel, notWithInLabel);
      const value = farData[label]?.map((item) => ({
        ...item,
        input: 0,
        label: label,
      })) || []; 
      if (value) {
        selectedFilter[label] = value;
      }
    } else if (
      filterKeys.includes(notWithInLabel) &&
      !filterKeys.includes(checkLable(item, withinLabel, notWithInLabel))
    ) {
      const label = checkLable(item, withinLabel, notWithInLabel);
      const value = farData[label]?.map((item) => ({
        ...item,
        input: 0,
        label: label,
      }));
      if (value.length > 0) {  
        selectedFilter[label] = value;
      } else {
        console.warn(`No data found for label: ${label}`); 
      }
    }
  });
  return selectedFilter;
};

export const handleSubmit = async (
  event,
  selectedKeys,
  inputValues,
  transformedData,
  address,
  user,
  setformSubmitted,
  checkSelectedFilter,
  withinLabel,
  notWithInLabel,
  setLoading
) => {
  event.preventDefault();

  let selectedFilters = Object.keys(selectedKeys)
    .filter((key) => selectedKeys[key])
    ?.reduce((acc, key) => {
      acc[key] = [
        ...transformedData[key].map((item) => ({
          ...item,
          input: parseFloat(inputValues[key]),
          label: key,
        })),
      ];
      return acc;
    }, {});

  setLoading(true);

  try {
    selectedFilters = checkSelectedFilter(
      selectedFilters,
      transformedData,
      withinLabel,
      notWithInLabel
    );
    const filterSelected = Object.values(selectedFilters).flat();

    // Check if "All" exists in transformedData
    const selectAll = transformedData["All"] ? transformedData["All"] : [];

    const filter_to_send = [...filterSelected, ...selectAll];

    const dataTosubmit = {
      address: address,
      selectedFilters: filter_to_send,
      user: user,
    };
    let hasError = false;
    dataTosubmit["selectedFilters"]?.map((item, index) => {
      if (
        item["sheetName"] === "far" &&
        Object.keys(item).includes("input") &&
        isNaN(item["input"])
      ) {
        hasError = true;
      }
    });
    if (hasError) {
      return;
    }
    const response = await postApi(
      dataTosubmit,
      "operator/operator-response-filter"
    );
    setformSubmitted(true);
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    setLoading(false);
  }
};

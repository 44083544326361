import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { postApi } from '../../Api/Api';
import { Check, ArrowRight } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const DisclaimerPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);

  // If not authenticated or userData is not available, redirect to login
//   if (!isAuthenticated || !userData) {
//     navigate('/login');
//     return null;
//   }

  const handleAccept = async () => {
    if (!disclaimerChecked) return;
    
    setLoading(true);
    try {
      await postApi({}, 'user/accept-terms');
      // After successful acceptance, trigger a revalidation of the auth state
      window.dispatchEvent(new Event('auth-update'));
      navigate('/property-search');
    } catch (error) {
      console.error('Error accepting terms:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4 py-12">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <div className="border inline-block px-3 py-1 rounded-md bg-gray-100 text-gray-500 mb-6">
          <Check size={18} className="inline-block mr-1" />
          Acknowledged
        </div>
        
        <p className="text-lg font-medium text-gray-900 mb-4">
          Thank you for using our services! While we strive to deliver the most accurate zoning analysis for each property, we are not perfect.
        </p>
        
        <p className="text-gray-700 mb-4">
          Here are a few important points to keep in mind:
        </p>
        
        <div className="mb-8 space-y-4">
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-700">
                NYCZONINGAI may occasionally produce incorrect or misleading information.
              </p>
            </div>
          </div>
          
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-700">
                Publicly available maps may not be precise. A survey by a NY State licensed surveyor is recommended.
              </p>
            </div>
          </div>
          
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-700">
                Our platform is not intended to provide a final zoning analysis. However, we offer a review of results by a licensed professional—either for free or for a minimal fee, depending on your subscription.
              </p>
            </div>
          </div>
          
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-700">
                Do not rely solely on our results without consulting us or an independent licensed professional.
              </p>
            </div>
          </div>
          
          <div className="flex">
            <div className="flex-shrink-0 h-10 w-10 bg-blue-100 rounded-full flex items-center justify-center">
              <svg className="h-6 w-6 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-700">
                As we continue to improve, NYCZONINGAI may adjust usage limits, functionality, or policies. You can upgrade your plan for expanded access to our features.
              </p>
            </div>
          </div>
        </div>
        
        <p className="text-gray-700 mb-6">
          If you notice any discrepancies in the analysis, please reach out to us. We're here to help!
        </p>
        
        <div className="flex items-center mb-6">
          <input
            id="disclaimer-checkbox"
            type="checkbox"
            className="h-4 w-4 text-blue-600 border-gray-300 rounded"
            checked={disclaimerChecked}
            onChange={() => setDisclaimerChecked(!disclaimerChecked)}
          />
          <label htmlFor="disclaimer-checkbox" className="ml-2 block text-sm text-gray-700">
            I acknowledge and understand the limitations of the service
          </label>
        </div>
        
        <button 
          className={`w-full px-4 py-2 rounded-md flex items-center justify-center ${disclaimerChecked ? 'bg-blue-600 text-white hover:bg-blue-500' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
          disabled={!disclaimerChecked || loading}
          onClick={handleAccept}
        >
          {loading ? "Accepting..." : "Sounds Good, Let's Begin"}
          <ArrowRight size={18} className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default DisclaimerPage; 
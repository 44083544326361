import React, { useEffect, useState, useRef, useCallback } from "react";
import { getApi } from "../../Api/api";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import InfintiteLoader from "../InfiniteLoader";
import FormNavbar from "./FormNavbar";
import AddressFormTable from "./AddressFormTable";
import AdminCardLoader from "./AdminCardLoader";

const FormTable = () => {
  const [formData, setFormData] = useState([]);
  const [statusCount, setStatusCount] = useState({});
  const [district, setDistrict] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [zoningDistricts, setZoningDistricts] = useState();
  const [totalProperty, setTotalProperty] = useState();
  const limit = 36;
  const [query, setQuery] = useState("");

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    let url = `admin/get-all-form?page=${currentPage}&limit=${limit}`;

    if (district) {
      url += `&district=${district}`;
      if (currentPage === 1) {
        setFormData("");
      }
    }
    const response = await getApi(url);
    if (response?.data) {
      setFormData((prevData) => [...prevData, ...response.data]);
      setStatusCount(response.formSummary);
      setZoningDistricts(response?.zoningDistricts);
      setTotalProperty(response?.totalProperty);
      setHasMore(response.data.length >= limit);
      setLoading(false);
    }
  }, [district, currentPage]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties, currentPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      if (hasMore) {
        fetchMoreData();
      }
    }
  };

  const filteredDistricts =
    query === ""
      ? zoningDistricts
      : zoningDistricts.filter((district) =>
          district.toLowerCase().includes(query.toLowerCase())
        );

  const handleDistrictChange = (newDistrict) => {
    setDistrict((prevDistrict) => {
      if (prevDistrict === newDistrict) {
        setCurrentPage((prevPage) => prevPage + 1);
      } else {
        setCurrentPage(1);
        setFormData([]);
      }
      return newDistrict;
    });
  };

  return (
    <>
      <FormNavbar />
      {loading && currentPage == 1 ? (
        <AdminCardLoader />
      ) : (
        <>
          <div className="overflow-x-auto p-4 lg:p-8 mt-10">
            <div className="flex flex-col sm:flex-row w-full justify-between gap-5 fixed  bg-white py-1 pr-16">
              <div className="flex gap-10 mt-20 md:mt-3">
                <div className="text-blue-500">
                  Total records = {totalProperty}
                </div>
                <div className="text-green-500">
                  Completed = {statusCount?.completed}
                </div>
                <div className="text-red-500">
                  Pending = {totalProperty - statusCount?.completed}
                </div>
              </div>
              <Combobox
                value={district}
                onChange={(value) => handleDistrictChange(value)}
              >
                <div className="relative">
                  <ComboboxInput
                    className={clsx(
                      "w-full rounded-lg border-none bg-blue-500 py-2 pr-8 pl-3 mb-2 text-sm text-white placeholder-white",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-300 mt-2"
                    )}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Zoning District Filters"
                  />
                  <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                    <ChevronDownIcon className="size-6 fill-blue-300 group-data-[hover]:fill-blue-500" />
                  </ComboboxButton>
                </div>

                <ComboboxOptions
                  anchor="bottom"
                  transition
                  className={clsx(
                    "w-[var(--input-width)] rounded-xl border border-black bg-gray-200 p-1 [--anchor-gap:var(--spacing-1)] empty:invisible",
                    "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                  )}
                >
                  {filteredDistricts?.map((district) => (
                    <ComboboxOption
                      key={district}
                      value={district}
                      className="group flex cursor-default items-center hover:curso gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white"
                    >
                      <CheckIcon className="invisible size-4 fill-blue-300 group-data-[selected]:visible" />
                      <div className="text-sm/6 text-black">{district}</div>
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </Combobox>
            </div>
            <div>
              <AddressFormTable formData={formData} setFormData={setFormData} />
            </div>
          </div>
          {loading && currentPage !== 1 && <InfintiteLoader />}
        </>
      )}
    </>
  );
};

export default FormTable;

import { Info, Star, DollarSign, Search, MapPin, ChevronRight, LogOut, X, User, Settings } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { useAuth } from '../../context/AuthContext';

const Sidebar = ({ isOpen, setIsOpen }) => {
    const navigate = useNavigate();
    const sidebarRef = useRef(null);
    const { isAuthenticated, userEmail, setIsAuthenticated, isAdmin } = useAuth();
    
    const navigateTo = (path) => {
        navigate(path);
        setIsOpen(false); // Close sidebar after navigation
    };

    const handleLogout = () => {
        Cookies.remove("authToken");
        setIsAuthenticated(false);
        navigateTo("/login");
    };

    // Handle Escape key and clicks outside
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape' && isOpen) {
                setIsOpen(false);
            }
        };

        const handleClickOutside = (event) => {
            if (
                isOpen && 
                sidebarRef.current && 
                !sidebarRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('keydown', handleEscape);
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, setIsOpen]);

    // Don't render anything if not authenticated
    if (!isAuthenticated) {
        return null;
    }

    return (
      <>
        {/* Mobile overlay */}
        {isOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 md:hidden z-40"
            onClick={() => setIsOpen(false)}
          ></div>
        )}
        
        {/* Sidebar */}
        <div 
          ref={sidebarRef}
          className={`fixed top-0 left-0 h-screen bg-white shadow-lg z-50 w-64 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="p-4 border-b border-gray-200">
            <div className="flex items-center mb-4">
              <div className="w-8 h-8 mr-2">
                <img src="/assets/logo.jpg" alt="NYCZoning AI Logo" className="w-full h-full object-contain" />
              </div>
              <span className="text-lg font-bold">NYCZoning AI</span>
            </div>
            
            {/* User Info */}
            <div className="flex items-center space-x-3 p-2 bg-gray-50 rounded-md">
              <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                <User size={16} className="text-blue-600" />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 truncate">{userEmail}</p>
                <p className="text-xs text-gray-500">Logged in</p>
              </div>
            </div>
          </div>
          
          <nav className="p-4">
            <ul className="space-y-2">
              <li>
                <button 
                  className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                  onClick={() => navigateTo('property-search')}
                >
                  <Search size={18} className="mr-3" />
                  <span>Property Search</span>
                </button>
              </li>
              <li>
                <button 
                  className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                  onClick={() => navigateTo('zoning-analysis')}
                >
                  <MapPin size={18} className="mr-3" />
                  <span>Address Lookup</span>
                </button>
              </li>
              <li>
                <button 
                  className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                  onClick={() => navigateTo('profile')}
                >
                  <User size={18} className="mr-3" />
                  <span>Profile</span>
                </button>
              </li>
              {isAdmin && (
                <li>
                  <button 
                    className="flex items-center w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
                    onClick={() => navigateTo('/admin/')}
                  >
                    <Settings size={18} className="mr-3" />
                    <span>Admin Dashboard</span>
                  </button>
                </li>
              )}
            </ul>
            
            {/* <div className="mt-8">
              <button 
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center justify-center"
                onClick={() => navigateTo('upgrade')}
              >
                <span>Upgrade Plan</span>
                <ChevronRight size={16} className="ml-2" />
              </button>
            </div> */}
            
            <div className="mt-4">
              <button 
                className="w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-100 flex items-center justify-center"
                onClick={handleLogout}
              >
                <LogOut size={16} className="mr-2" />
                <span>Logout</span>
              </button>
            </div>
          </nav>
        </div>
      </>
    );
  };

  export default Sidebar;
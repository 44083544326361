import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Cookies from 'js-cookie';
import { postApi } from '../../Api/Api';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSignIn, setIsEmailSignIn] = useState(false);
  const [isPendingActivation, setIsPendingActivation] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const navigateTo = (path) => {
    navigate(path);
  };

  // Email validation regex
  const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

  useEffect(() => {
    // Check for auth token in URL parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      // Set the token in cookies
      Cookies.set('authToken', token, { expires: 7 }); // Token expires in 7 days
      setIsAuthenticated(true);
      checkTermsAndRedirect(token);
    }
  }, [setIsAuthenticated, navigate]);

  const checkTermsAndRedirect = async (token) => {
    try {
      const response = await postApi({ token }, 'user/validate-token');
      if (!response.data.hasAcceptedTerms) {
        navigate('/disclaimer');
      } else {
        navigate('/property-search');
      }
    } catch (error) {
      console.error('Error checking terms:', error);
      navigate('/property-search');
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    setError("");
    setIsPendingActivation(false);
    
    if (!EMAIL_REGEX.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    try {
      const response = await postApi({ email }, `user/send-magic-link`);
      if (response.pendingActivation) {
        setIsPendingActivation(true);
      } else {
        setEmailSent(true);
      }
      setError('');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Something went wrong. Please try again.';
      if (errorMessage === 'Your account is pending activation by an administrator. You will receive an email when your account is activated.') {
        setIsPendingActivation(true);
      } else {
        setError(errorMessage);
      }
    }
    setIsLoading(false);
  };

  const handleResendVerification = async () => {
    if (!EMAIL_REGEX.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    try {
      await postApi({ email }, 'user/resend-verification');
      setVerificationEmailSent(true);
      setError('');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Something went wrong. Please try again.';
      setError(errorMessage);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setVerificationEmailSent(false);
    setIsPendingActivation(false);

    if (!EMAIL_REGEX.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!password) {
      setError('Please enter your password');
      return;
    }

    setIsLoading(true);
    try {
      const response = await postApi({ email, password }, `user/login`);
      const data = response.data;
      if (data.user && data.token) {
        Cookies.set("authToken", data.token, { expires: 1.5 / 24 });
        setIsAuthenticated(true);
        
        // Check if user has accepted terms
        if (!data.user.hasAcceptedTerms) {
          navigate('/disclaimer');
        } else {
          navigate('/property-search');
        }
      } else {
        setError('Invalid email or password');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Something went wrong. Please try again.';
      if (errorMessage === 'Your account is pending activation by an administrator') {
        setIsPendingActivation(true);
      } else {
        setError(errorMessage);
      }
    }
    setIsLoading(false);
  };

  const handleLinkedInSignIn = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/user/auth/linkedin`;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4 py-12">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
        <div className="flex justify-center mb-6">
          <img src="/assets/logo.jpg" alt="NYCZoning AI Logo" className="w-16 h-16 object-contain" />
        </div>

        <h2 className="text-2xl font-bold text-center text-gray-900 mb-6">
          Log In
        </h2>

        <form onSubmit={isEmailSignIn ? handleEmailSignIn : handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-1">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {!isEmailSignIn && (
            <div className="mb-6">
              <label className="block text-gray-700 mb-1">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
          )}

          {error && !isPendingActivation && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded-md">
              {error}
              {error === 'Please verify your email first' && (
                <div className="mt-2">
                  <button
                    onClick={handleResendVerification}
                    disabled={isLoading}
                    className="text-blue-600 hover:text-blue-800 text-sm font-medium underline"
                  >
                    {isLoading ? 'Sending...' : 'Resend verification email'}
                  </button>
                </div>
              )}
            </div>
          )}

          {emailSent && (
            <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-md">
              <div className="flex items-center mb-2">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                  <svg className="w-4 h-4 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-blue-900">Check your email</h3>
              </div>
              <p className="text-sm text-blue-800">
                We've sent you a magic link to sign in. Please check your email (and spam folder) and click the link to continue.
              </p>
            </div>
          )}

          {verificationEmailSent && (
            <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md">
              <div className="flex items-center mb-2">
                <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center mr-3">
                  <svg className="w-4 h-4 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-green-900">Verification email sent</h3>
              </div>
              <p className="text-sm text-green-800">
                We've sent you a new verification email. Please check your inbox (and spam folder) and click the verification link.
              </p>
            </div>
          )}

          {isPendingActivation && (
            <div className="mb-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
              <div className="flex items-center mb-2">
                <div className="w-8 h-8 rounded-full bg-yellow-100 flex items-center justify-center mr-3">
                  <svg className="w-4 h-4 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <h3 className="text-lg font-medium text-yellow-900">Account Pending Activation</h3>
              </div>
              <p className="text-sm text-yellow-800">
                Your account is pending activation by an administrator. You will receive an email when your account is activated and ready to use.
              </p>
            </div>
          )}

          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 mb-4"
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : isEmailSignIn ? 'Send Magic Link' : 'Log In'}
          </button>
        </form>

        <div className="text-center space-y-3">
          <button
            onClick={() => {
              setIsEmailSignIn(!isEmailSignIn);
              setError('');
              setEmailSent(false);
              setIsPendingActivation(false);
            }}
            className="w-full px-4 py-2.5 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors duration-200 font-medium"
          >
            {isEmailSignIn ? 'Sign in with Password' : 'Sign in with Magic Link'}
          </button>
          {/* <button
            onClick={handleLinkedInSignIn}
            className="w-full px-4 py-2.5 bg-white border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors duration-200 font-medium flex items-center justify-center"
          >
            <svg className="w-5 h-5 mr-2 text-[#0A66C2]" viewBox="0 0 24 24" fill="currentColor">
              <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
            </svg>
            Sign in with LinkedIn
          </button> */}
          <div className="pt-2">
            <button
              className="text-gray-600 hover:text-gray-900 text-sm font-medium"
              onClick={() => navigateTo("/register")}
            >
              Need an account? Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

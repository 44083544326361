import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { getApi, postApi } from "../../../Api/Api";
import { Popover } from "@headlessui/react";
import {
  PlusIcon,
  XMarkIcon,
  PencilSquareIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import debounce from "lodash/debounce";
import { useAuth } from "../../../context/AuthContext";
import { SubscriptionStatus, notifySubscriptionUpdate } from "./SubscriptionStatus";
import SubscriptionCard from "./userSubscriptionPage";
import Tooltip from "./Tooltip";

const PRIMARY_FILTERS = {
  SPECIAL_DISTRICT: "specialDistrict",
  SPECIAL_SUB_DISTRICT: "specialSubDistrict",
  ZONING_DISTRICT: "zoningDistrict",
  COMMERCIAL_OVERLAY: "commercialOverlay",
  COMMUNITY_DISTRICT: "communityDistrict",
  WITHIN_WIDE_ST: "withinWideSt",
  FRONTS_WIDE_ST: "frontsWideStreet",
  WITHIN_GREATER_TRANSIT_ZONE: "withinGreaterTransitZone",
  OUTSIDE_MIH: "outsideMIH",
  LOT_TYPE: "lotType",
};

const BOOLEAN_FILTERS = [
  PRIMARY_FILTERS.WITHIN_WIDE_ST,
  PRIMARY_FILTERS.FRONTS_WIDE_ST,
  PRIMARY_FILTERS.WITHIN_GREATER_TRANSIT_ZONE,
  PRIMARY_FILTERS.OUTSIDE_MIH,
];

const PrimaryFilterChip = ({
  name,
  percentage,
  onEdit,
  onDelete,
  readOnly,
}) => (
  <div className="inline-flex items-center bg-blue-100 rounded-full px-3 py-1 m-1">
    <span className="text-sm text-blue-800">
      {name}: {percentage}%
    </span>
    {!readOnly && (
      <>
        <button
          onClick={onEdit}
          className="ml-2 text-blue-600 hover:text-blue-800"
        >
          <PencilSquareIcon className="h-4 w-4" />
        </button>
        <button
          onClick={onDelete}
          className="ml-1 text-blue-600 hover:text-blue-800"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </>
    )}
  </div>
);

const PrimaryFilterSection = ({
  filterName,
  possibleValues,
  selections,
  onUpdate,
  isBoolean,
  readOnly,
}) => {
  const [editingValue, setEditingValue] = useState(null);
  const [tempValue, setTempValue] = useState("");
  const [tempPercentage, setTempPercentage] = useState(100);
  const [showPopover, setShowPopover] = useState(false);

  const currentTotal = useMemo(() => {
    return Object.entries(selections[filterName] || {}).reduce(
      (sum, [_, data]) => sum + data.percentage,
      0
    );
  }, [selections, filterName]);

  if (BOOLEAN_FILTERS.includes(filterName)) {
    const percentage = selections[filterName]?.percentage || 0;

    if (readOnly) {
      return (
        <div className="mb-4">
          <span className="text-sm font-medium text-gray-700">
            {filterName}:{" "}
            <span className="text-gray-900">
              {percentage === 100 ? "Yes" : percentage === 0 ? "No" : "Yes"}
              {percentage !== 0 &&
                percentage !== 100 &&
                ` (${percentage}% portion of lot)`}
            </span>
          </span>
        </div>
      );
    }

    return (
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          {filterName}
        </label>
        <input
          type="range"
          min="0"
          max="100"
          value={percentage}
          onChange={(e) => {
            if (!readOnly) {
              onUpdate(filterName, {
                percentage: parseInt(e.target.value),
              });
            }
          }}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        <div className="text-sm text-gray-500 mt-1">{percentage}%</div>
      </div>
    );
  }

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {filterName}
      </label>
      <div className="flex flex-wrap gap-2">
        {Object.entries(selections[filterName] || {}).map(([value, data]) => (
          <div key={value}>
            <PrimaryFilterChip
              name={value}
              percentage={data.percentage}
              onEdit={() => {
                if (!readOnly) {
                  setEditingValue(value);
                  setTempValue(value);
                  setTempPercentage(data.percentage);
                  setShowPopover(true);
                }
              }}
              onDelete={() => {
                if (!readOnly) {
                  const newSelections = { ...selections[filterName] };
                  delete newSelections[value];
                  onUpdate(filterName, newSelections);
                }
              }}
              readOnly={readOnly}
            />
            {showPopover && editingValue === value && (
              <Popover className="relative">
                <Popover.Panel
                  static
                  className="absolute z-10 w-64 p-4 mt-2 bg-white rounded-lg shadow-lg"
                >
                  <div className="space-y-4">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={tempPercentage}
                      onChange={(e) =>
                        setTempPercentage(parseInt(e.target.value))
                      }
                      className="w-full"
                    />
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">
                        {tempPercentage}%
                      </span>
                      <div className="space-x-2">
                        <button
                          onClick={() => {
                            const newSelections = { ...selections[filterName] };
                            newSelections[value] = {
                              percentage: tempPercentage,
                            };
                            onUpdate(filterName, newSelections);
                            setShowPopover(false);
                            setEditingValue(null);
                          }}
                          className="text-sm text-blue-600"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setShowPopover(false);
                            setEditingValue(null);
                          }}
                          className="text-sm text-gray-600"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
            )}
          </div>
        ))}

        {!readOnly && currentTotal < 100 && (
          <Popover className="relative">
            <Popover.Button className="inline-flex items-center bg-blue-100 rounded-full px-3 py-1">
              <PlusIcon className="h-4 w-4 text-blue-600" />
            </Popover.Button>

            <Popover.Panel className="absolute z-10 w-64 p-4 mt-2 bg-white rounded-lg shadow-lg">
              <div className="space-y-4">
                <select
                  value={tempValue}
                  onChange={(e) => setTempValue(e.target.value)}
                  className="w-full border rounded"
                >
                  <option value="">Select a value</option>
                  {possibleValues
                    .filter((val) => !selections[filterName]?.[val])
                    .map((val) => (
                      <option key={val} value={val}>
                        {val}
                      </option>
                    ))}
                </select>
                <input
                  type="range"
                  min="0"
                  max={100 - currentTotal}
                  value={tempPercentage}
                  onChange={(e) => setTempPercentage(parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">
                    {tempPercentage}%
                  </span>
                  <button
                    onClick={() => {
                      if (tempValue && tempPercentage > 0) {
                        const newSelections = { ...selections[filterName] };
                        newSelections[tempValue] = {
                          percentage: tempPercentage,
                        };
                        onUpdate(filterName, newSelections);
                        setTempValue("");
                        setTempPercentage(100 - currentTotal);
                        setShowPopover(false);
                      }
                    }}
                    className="text-sm text-blue-600"
                    disabled={!tempValue || tempPercentage === 0}
                  >
                    Add
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Popover>
        )}
      </div>
    </div>
  );
};

function translateZoningCodeRefIntoLink(text) {
  function translateToRoman(num) {
    const romanValues = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
      11: "XI",
      12: "XII",
      13: "XIII",
      14: "XIV",
    };
    return romanValues[num];
  }
  try {
    // Extract article and chapter (combined)
    const articleChapter = text.substring(0, text.indexOf("-"));
    const paragraph = text.substring(text.indexOf("-") + 1);

    // Determine article and chapter based on length
    let articleNum, chapterNum;
    if (articleChapter.length === 1) {
      throw new Error("Chapter missing");
    }
    articleNum = parseInt(
      articleChapter.substring(0, articleChapter.length - 1)
    );
    chapterNum = parseInt(
      articleChapter.substring(articleChapter.length - 1)
    );

    // Validate article, chapter, and paragraph ranges
    if (!(1 <= articleNum && articleNum <= 14)) {
      throw new Error("Article number must be between 1 and 14");
    }
    if (!(1 <= chapterNum && chapterNum <= 9)) {
      throw new Error("Chapter number must be between 1 and 9");
    }

    // Translate article number to Roman numeral
    const romanArticle = translateToRoman(articleNum);

    // https://zr.planning.nyc.gov/article-vi/chapter-4/64-00
    return `https://zr.planning.nyc.gov/article-${romanArticle}/chapter-${chapterNum}#${text}`;
  } catch (e) {
    console.error(`Error parsing zoning ref: ${e.message}`);
    return null;
  }
}

// Helper function to process infoShort text with links
const processInfoShortText = (text) => {
  if (!text) return null;
  const parts = text.split(/(\[ZR[^\]]+\])/);
  return parts.map((part, index) => {
    const match = part.match(/\[ZR(.*?)\]/);
    if (match) {
      const link = translateZoningCodeRefIntoLink(match[1]);
      return link ? (
        <a
          key={index}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          {part}
        </a>
      ) : (
        part
      );
    }
    return part;
  });
};

const FarGroup = ({
  group,
  groupKey,
  expandedSections,
  setExpandedSections,
  lotArea,
  primarySelections,
}) => {
  const isExpanded = expandedSections.has(groupKey);

  const styles = {
    cell: {
      padding: "8px 12px",
    },
    numberCell: {
      width: "8em",
      padding: "8px 12px",
    },
    attributeCell: {
      width: "20em",
      padding: "8px 12px",
    },
  };

  // Helper function to determine if a height attribute should be shown
  const shouldShowHeightAttribute = (attr) => {
    if (attr === 'SetbackWideStreet' && primarySelections.frontsWideStreet?.percentage === 0) {
      return false;
    }
    if (attr === 'SetbackNarrowStreet' && primarySelections.frontsWideStreet?.percentage === 100) {
      return false;
    }
    return true;
  };

  return (
    <div className="bg-white border-b border-gray-300">
      <div className="p-4 overflow-x-auto">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold text-md border-b border-gray-400">
            {group.displayHeading}
          </h3>
          {(group.pathGroups.some(({ percentage }) => percentage < 100) ||
            group.pathGroups.some(({ record }) =>
              record.farOutput.some((output) => output.infoShort)
            )) && (
            <button
              onClick={() => {
                const newExpanded = new Set(expandedSections);
                if (isExpanded) {
                  newExpanded.delete(groupKey);
                } else {
                  newExpanded.add(groupKey);
                }
                setExpandedSections(newExpanded);
              }}
              className="text-blue-600 hover:text-blue-800"
            >
              {isExpanded ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </button>
          )}
        </div>

        {/* Combined FAR, Height, and Yards table */}
        <div className="mt-4">
          <table className="w-full" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th className="text-left font-medium text-gray-500" style={styles.attributeCell}>
                  Attribute
                </th>
                <th className="text-left font-medium text-gray-500" style={styles.numberCell}>
                  Value
                </th>
                <th className="text-left font-medium text-gray-500" style={styles.cell}>
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {/* FAR rows */}
              {Object.entries(group.aggregated.farByUseGroup).map(([useGroup, data], idx) => (
                <React.Fragment key={`far-${idx}`}>
                  <tr className="border-t border-gray-200">
                    <td className="py-2" style={styles.attributeCell}>
                      FAR ({useGroup})
                    </td>
                    <td className="py-2" style={styles.numberCell}>
                      {data.value.toFixed(2)}
                    </td>
                    <td className="py-2" style={styles.cell} rowSpan="2">
                      {processInfoShortText(group.pathGroups[0]?.record.farOutput.find(output => output.useGroup === useGroup)?.infoShort) || "N/A"}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-200">
                    <td className="py-2" style={styles.cell}>
                      Floor Area ({useGroup})
                    </td>
                    <td className="py-2" style={styles.numberCell}>
                      {data.fa.toLocaleString()} SF
                    </td>
                  </tr>
                  {data.maxNumApartments !== null && (
                    <tr className="border-t border-gray-200">
                      <td className="py-2" style={styles.cell}>
                        Maximum Units ({useGroup})
                      </td>
                      <td className="py-2" style={styles.numberCell}>
                        {data.maxNumApartments}
                      </td>
                      <td className="py-2" style={styles.cell}>
                        {processInfoShortText(group.pathGroups[0]?.maxNumApartmentsInfoShort) || "N/A"}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              
              {/* Height rows */}
              {Object.entries(group.aggregated.heightAttributes)
                .filter(([attr]) => shouldShowHeightAttribute(attr))
                .map(([attr, heightAttrib], idx) => heightAttrib.value && (
                  <tr key={`height-${idx}`} className="border-t border-gray-200">
                    <td className="py-2" style={styles.attributeCell}>
                      {attr}
                    </td>
                    <td className="py-2" style={styles.numberCell}>
                      {typeof heightAttrib.value === 'number' ? heightAttrib.value.toFixed(0) : heightAttrib.value}
                    </td>
                    <td className="py-2" style={styles.cell}>
                      {processInfoShortText(heightAttrib.infoShort) || "N/A"}
                    </td>
                  </tr>
                ))}

              {/* Yards rows */}
              {group.aggregated.yardAttributes.map((data, idx) => (
                <tr key={`yard-${idx}`} className="border-t border-gray-200">
                  <td className="py-2" style={styles.attributeCell}>
                    {data.yardType}
                  </td>
                  <td className="py-2" style={styles.numberCell}>
                    {data.value.toFixed(0)} FT
                  </td>
                  <td className="py-2" style={styles.cell}>
                    {processInfoShortText(data.infoShort) || "N/A"}
                  </td>
                </tr>
              ))}

              {/* Parking rows */}
              {group.aggregated.parkingAttributes.map((data, idx) => (
                <tr key={`parking-${idx}`} className="border-t border-gray-200">
                  <td className="py-2" style={styles.attributeCell}>
                    {data.parkingType}
                  </td>
                  <td className="py-2" style={styles.numberCell}>
                    {data.value.toFixed(0)}
                  </td>
                  <td className="py-2" style={styles.cell}>
                    {processInfoShortText(data.infoShort) || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isExpanded && (
          <div className="mt-4 border-t border-gray-200 pt-4">
            {group.pathGroups.map(({ path, record, percentage, maxNumApartments, maxNumApartmentsInfoShort }, idx) => {
              const hasInfoShort = record.farOutput.some(
                (output) => output.infoShort
              );
              const hasPartialPercentage = percentage < 100;

              if (!hasInfoShort && !hasPartialPercentage) return null;

              const filterDisplayParts = path.filter(
                ({ percentage }) => percentage < 100
              );
              const filterDisplay = filterDisplayParts
                .map(({ filterName, value, percentage }) => {
                  let displayValue = `About ${percentage.toFixed(0)}% portion `;
                  if (BOOLEAN_FILTERS.includes(filterName)) {
                    displayValue += `${
                      value ? filterName : "not " + filterName
                    }`;
                  } else {
                    displayValue += `in ${filterName} ${value}`;
                  }
                  return displayValue;
                })
                .join(" • ");

              return (
                <div key={idx} className="mb-4">
                  {filterDisplay && (
                    <div className="font-medium text-gray-700 mb-2">
                      <u>
                        {filterDisplay}
                        {filterDisplayParts.length > 1 &&
                          ` (${percentage.toFixed(0)}% cumulative)`}
                        :
                      </u>
                    </div>
                  )}
                  {hasPartialPercentage && (
                    <div className="bg-gray-50 p-4 rounded">
                      <table className="w-full" style={{ tableLayout: "fixed" }}>
                        <thead>
                          <tr>
                            <th className="text-left font-medium text-gray-500" style={styles.attributeCell}>
                              Attribute
                            </th>
                            <th className="text-left font-medium text-gray-500" style={styles.numberCell}>
                              Value
                            </th>
                            <th className="text-left font-medium text-gray-500" style={styles.cell}>
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* FAR rows */}
                          {record.farOutput.map((output, outputIdx) => (
                            <React.Fragment key={`far-${outputIdx}`}>
                              <tr className="border-t border-gray-200">
                                <td className="py-2" style={styles.cell}>
                                  FAR ({output.useGroup})
                                </td>
                                <td className="py-2" style={styles.numberCell}>
                                  {(output.value * (percentage / 100)).toFixed(2)}
                                  <span className="text-xs text-gray-500">
                                    {" "}
                                    &nbsp;({output.value.toFixed(2)} *{" "}
                                    {percentage.toFixed(0)}%)
                                  </span>
                                </td>
                                <td className="py-2" style={styles.cell} rowSpan="2">
                                  {processInfoShortText(output.infoShort) || "N/A"}
                                </td>
                              </tr>
                              <tr className="border-t border-gray-200">
                                <td className="py-2" style={styles.cell}>
                                  Floor Area ({output.useGroup})
                                </td>
                                <td className="py-2" style={styles.numberCell}>
                                  {Math.round(
                                    output.value * lotArea * (percentage / 100)
                                  ).toLocaleString()} SF
                                </td>
                              </tr>
                              {output.maxNumApartments !== null && (
                                <tr className="border-t border-gray-200">
                                  <td className="py-2" style={styles.cell}>
                                    Maximum Units ({output.useGroup})
                                  </td>
                                  <td className="py-2" style={styles.numberCell}>
                                    {maxNumApartments}
                                  </td>
                                  <td className="py-2" style={styles.cell}>
                                    {processInfoShortText(maxNumApartmentsInfoShort) || "N/A"}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                          
                          {/* Height rows */}
                          {record.heightOutput
                            .filter(output => shouldShowHeightAttribute(output.heightAttribute))
                            .map((output, outputIdx) => (
                              <tr key={`height-${outputIdx}`} className="border-t border-gray-200">
                                <td className="py-2" style={styles.cell}>
                                  {output.heightAttribute}
                                </td>
                                <td className="py-2" style={styles.numberCell}>
                                  {output.value.toFixed(0)}{output.heightAttribute === 'Slope Over Zoning Lot' ? '' : ' FT'}
                                </td>
                                <td className="py-2" style={styles.cell}>
                                  {processInfoShortText(output.infoShort) || "N/A"}
                                </td>
                              </tr>
                            ))}

                          {/* Yards rows */}
                          {record.yardsOutput.map((output, outputIdx) => (
                            <tr key={`yard-${outputIdx}`} className="border-t border-gray-200">
                              <td className="py-2" style={styles.cell}>
                                {output.yardType}
                              </td>
                              <td className="py-2" style={styles.numberCell}>
                                {output.value.toFixed(0)} FT
                              </td>
                              <td className="py-2" style={styles.cell}>
                                {processInfoShortText(output.infoShort) || "N/A"}
                              </td>
                            </tr>
                          ))}

                          {/* Parking rows */}
                          {record.parkingOutput.map((output, outputIdx) => (
                            <tr key={`parking-${outputIdx}`} className="border-t border-gray-200">
                              <td className="py-2" style={styles.cell}>
                                {output.parkingType}
                              </td>
                              <td className="py-2" style={styles.numberCell}>
                                {output.value.toFixed(0)} FT
                              </td>
                              <td className="py-2" style={styles.cell}>
                                {processInfoShortText(output.infoShort) || "N/A"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const HeightDiagram = ({ heightData }) => {
  // Find the relevant height values from heightData
  const maxBuildingHeight = heightData.find(h => h.heightAttribute === 'Maximum Building Height')?.value || 0;
  const maxBaseHeight = heightData.find(h => h.heightAttribute === 'Maximum Base Height')?.value || 0;
  const minBaseHeight = heightData.find(h => h.heightAttribute === 'Minimum Base Height')?.value || 0;
  const setback = heightData.find(h => h.heightAttribute === 'Required Setback')?.value || 0;

  return (
    <svg viewBox="0 0 400 400" className="w-full max-w-md mx-auto">
      {/* Building height text */}
      <text x="100" y="200" transform="rotate(-90, 100, 200)" className="text-sm">
        Building {maxBuildingHeight} FT Max
      </text>

      {/* Base height text */}
      <text x="150" y="200" transform="rotate(-90, 150, 200)" className="text-sm">
        Base {minBaseHeight} FT Min {maxBaseHeight} FT Max
      </text>

      {/* Building outline */}
      <path
        d={`
          M 200 300
          L 200 150
          L 220 150
          L 220 100
          L 300 100
          L 300 150
          L 320 150
          L 320 300
          Z
        `}
        fill="none"
        stroke="black"
        strokeWidth="2"
      />

      {/* Setback measurement */}
      <text x="220" y="145" className="text-sm">
        {setback} FT
      </text>
    </svg>
  );
};

const DynamicForm = ({ mode }) => {
  const [selections, setSelections] = useState({ FAR: {} });
  const [primarySelections, setPrimarySelections] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [lotArea, setLotArea] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);
  const [selectedLots, setSelectedLots] = useState([]);
  const [expandedSections, setExpandedSections] = useState(new Set());
  const [isSearching, setIsSearching] = useState(false);
  const [bblMatches, setBblMatches] = useState([]);
  const [isLoadingBbl, setIsLoadingBbl] = useState(false);
  const [metadataRecords, setMetadataRecords] = useState(null);
  const [possibleFilterPaths, setPossibleFilterPaths] = useState([]);
  const [farGroups, setFarGroups] = useState([]);
  const [heightGroups, setHeightGroups] = useState([]);
  const [yardsGroups, setYardsGroups] = useState([]);
  const [parkingGroups, setParkingGroups] = useState([]);
  const [useGroups, setUseGroups] = useState([]);
  const initialFetchAttempted = useRef(false);
  const [analysisLabel, setAnalysisLabel] = useState("");
  const [analysisStatus, setAnalysisStatus] = useState(null);
  const [analysisId, setAnalysisId] = useState(null);
  const [reviewedAt, setReviewedAt] = useState(null);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [quotaExceeded, setQuotaExceeded] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const { token, isAdmin } = useAuth();
  const [operatorNotes, setOperatorNotes] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Check URL for debug parameter on component mount
    const debugParam = searchParams.get('debug');
    if (debugParam === '1' && isAdmin) {
      setDebugMode(true);
    }
  }, []);

  const handleDebugToggle = (checked) => {
    setDebugMode(checked);
    if (checked) {
      searchParams.set('debug', '1');
    } else {
      searchParams.delete('debug');
    }
    setSearchParams(searchParams);
  };

  const handleSearch = async (e, lotId, address, addToAssemblage = false) => {
    e?.preventDefault();
    if (!lotId || !address) return;

    try {
      const newLots =
        addToAssemblage && !selectedLots.some((lot) => lot.lotId === lotId)
          ? [...selectedLots, { lotId: lotId, address }]
          : [{ lotId: lotId, address }];

      setSelectedLots(newLots);
      setSearchInput("");
    } catch (error) {
      console.error("Error in handleSearch:", error);
      setErrorMessage(error.message);
    }
  };

  const getBblMatches = async (bbl) => {
    const response = await fetch(
      `https://planninglabs.carto.com/api/v2/sql?q=SELECT%20address,zipcode,%20bbl%20FROM%20dcp_mappluto%20WHERE%20CAST(bbl%20AS%20text)%20like%20%27${bbl}%25%27%20LIMIT%205&format=json`
    );
    const data = await response.json();
    if (!data) return [];
    return data.rows.map((row) => ({
      bbl: row.bbl,
      address: `${row.address}, ${row.zipcode}`,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("selectedLots", selectedLots);
      if (id) return;
      if (selectedLots.length > 0) {
        try {
          setIsLoading(true);
          setMetadataRecords(null);
          await fetchAndUpdateMetadata(selectedLots);
        } catch (error) {
          console.error("Error in fetchAndUpdateMetadata:", error);
          setErrorMessage(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [selectedLots]);

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData starting");
      setIsLoading(true);
      try {
        const lotIdsParam = searchParams.get("lotIds");
        const addressParam = searchParams.get("address");
        console.log("mode", mode);

        if (id) {
          const response = await getApi(
            `operator/zoning-analysis/${id}`,
            token
          );
          console.log("id response", response);
          setAnalysisId(id);
          setAnalysisLabel(response.analysisMetadata.label);
          setAnalysisStatus(response.analysisMetadata.status);
          setReviewedAt(response.analysisMetadata.reviewedAt);
          setOperatorNotes(response.analysisMetadata.notes || "");
          setSelectedLots(
            response.lotIds.map((lotId, index) => ({
              lotId: lotId,
              address: response.addresses[index],
            }))
          );
          setPrimarySelections(response.primarySelections);
          setLotArea(response.lotArea);

          console.log("response.sendFilter", response.sendFilter);
          setMetadataRecords(response.sendFilter);
          setFarGroups(response.zoningOutput["far"] || []);
          setHeightGroups(response.zoningOutput["heights"] || []);
          setYardsGroups(response.zoningOutput["yards"] || []);
          setParkingGroups(response.zoningOutput["parking"] || []);
          setUseGroups(response.zoningOutput["useGroupOutput"] || []);
        } else if (lotIdsParam) {
          const lotIds = lotIdsParam.split(",");
          const newLots = [];
          for (const lotId of lotIds) {
            const bblMatches = await getBblMatches(lotId);
            if (bblMatches.length > 0) {
              newLots.push({ lotId: lotId, address: bblMatches[0].address });
            }
          }
          setSelectedLots(newLots);
        } else if (addressParam) {
          console.log("addressParam", addressParam);
          const response = await fetch(
            `https://geosearch.planninglabs.nyc/v2/autocomplete?text=${encodeURIComponent(
              addressParam
            )}`
          );
          const data = await response.json();
          if (data.features.length > 0) {
            console.log("data", data);
            const addressData = data.features[0];
            setSelectedLots([{
              lotId: addressData.properties.addendum.pad.bbl,
              address: addressData.properties.label,
            }]);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrorMessage("Error loading from URL parameters");
      } finally {
        setIsLoading(false);
      }
    };

    // Only fetch data if we haven't attempted it yet
    const lotIdsParam = searchParams.get("lotIds");
    const addressParam = searchParams.get("address");
    if ((id || lotIdsParam || addressParam) && !initialFetchAttempted.current) {
      initialFetchAttempted.current = true;
      fetchData();
    }
  }, [id, searchParams]);

  const computeFilterPaths = (records) => {
    const paths = new Map();

    records.forEach((record) => {
      let currentLevel = paths;
      const filters = record.dynamicFilters;

      for (const [filter, value] of Object.entries(filters)) {
        if (!currentLevel.has(filter)) {
          currentLevel.set(filter, new Map());
        }

        let valueString;
        if (Array.isArray(value)) {
          valueString = JSON.stringify(value.sort());
        } else {
          valueString = JSON.stringify(value);
        }
        if (!currentLevel.get(filter).has(valueString)) {
          currentLevel.get(filter).set(valueString, new Map());
        }

        currentLevel = currentLevel.get(filter).get(valueString);
      }
    });

    return paths;
  };

  useEffect(() => {
    if (metadataRecords) {
      const paths = computeFilterPaths(metadataRecords.far);
      console.log("Computed filter paths:", paths);
      setPossibleFilterPaths(paths);
    }
  }, [metadataRecords, selections, primarySelections]);

  const fetchAndUpdateMetadata = async (lots) => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setQuotaExceeded(false);

      const response = await getApi(
        `operator/operator-filters?lotIds=${encodeURIComponent(
          JSON.stringify(lots.map((lot) => lot.lotId))
        )}`,
        token
      );
      console.log("API Response:", response);

      if (response.isQuotaExceeded) {
        setQuotaExceeded(true);
        setMetadataRecords(null);
        setFarGroups([]);
        setHeightGroups([]);
        setYardsGroups([]);
        setParkingGroups([]);
        setUseGroups([]);
        setLotArea(null);
        setPrimarySelections({});
        return;
      }

      if (response.message) {
        throw new Error(response.message);
      }

      if (response) {
        console.log("Setting metadata records:", response.sendFilter);
        setMetadataRecords(response.sendFilter);
        setLotArea(response.lotArea);
        setPrimarySelections(response.primarySelections);
        setFarGroups(response.zoningOutput["far"]);
        setHeightGroups(response.zoningOutput["heights"]);
        setYardsGroups(response.zoningOutput["yards"]);
        setParkingGroups(response.zoningOutput["parking"]);
        setUseGroups(response.zoningOutput["useGroupOutput"]);
        notifySubscriptionUpdate();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage(
        'An error occurred while fetching data: "' +
          error.message +
          '". Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Handle selection of a filter value
  const handleFilterSelect = (sheetName, filterPath, value) => {
    console.log("handleFilterSelect", filterPath, value);
    setSelections((prev) => ({
      ...prev,
      [sheetName]: {
        ...prev[sheetName],
        [filterPath]: value,
      },
    }));
  };

  // Update getAvailableFilters to work with new structure
  const getAvailableFilters = (currentSelections) => {
    const filters = new Map();

    const isValueSelected = (filterName, value) => {
      const selectedValue = currentSelections["FAR"]?.[filterName];

      // If no selection or "All", this path is valid
      const isEmptyArray =
        Array.isArray(selectedValue) && selectedValue.length === 0;
      if (!selectedValue || selectedValue === "All" || isEmptyArray) {
        return true;
      }

      // For multi-select filters
      if (Array.isArray(selectedValue)) {
        return selectedValue.includes(value);
      }

      // For single-select filters
      return selectedValue === value;
    };

    const addFiltersFromPath = (pathMap) => {
      pathMap.forEach((valueMap, filterName) => {
        // Initialize filter set if needed
        filters.set(filterName, filters.get(filterName) || new Set());

        valueMap.forEach((nextMap, valueMaybeJson) => {
          const value = JSON.parse(valueMaybeJson);
          if (Array.isArray(value)) {
            value.forEach((v) => filters.get(filterName).add(v));
          } else {
            filters.get(filterName).add(value);
          }
          // Only process this path if it matches current selections
          if (isValueSelected(filterName, value)) {
            addFiltersFromPath(nextMap);
          }
        });
      });
    };

    if (possibleFilterPaths) {
      addFiltersFromPath(possibleFilterPaths);
    }

    return filters;
  };

  // Update renderDynamicFilters to handle nested structure
  const renderDynamicFilters = () => {
    if (!metadataRecords) return null;

    const labels = {
      "Filter 2 (User to Select Preference)": "Use Group",
      "Filter2Sub": "Additional use 1",
      "Filter 3": "Additional use 2",
    };

    const availableFilters = getAvailableFilters(selections);
    // console.log("Available filters:", availableFilters);
    // console.log("Selections:", selections);

    return (
      <div className="mb-8">
        {availableFilters.size === 0 ? (
          <div></div>
        ) : (
          <>
            {/* <h2 className="text-lg font-semibold mb-4">Dynamic Filters</h2> */}
            <div className="space-y-4">
              {Array.from(availableFilters).map(
                ([filterName, filterValues]) => {
                  const isMultiSelect = filterName === "Filter2Sub";
                  const valueOptions = Array.from(filterValues);
                  // console.log(
                  //   "Filter:",
                  //   filterName,
                  //   valueOptions,
                  //   selections["FAR"]?.[filterName]
                  // );

                  return (
                    <div
                      key={filterName}
                      className="p-4 bg-white border rounded-lg shadow-sm"
                    >
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {labels[filterName] || filterName}
                      </label>
                      {isMultiSelect ? (
                        <div className="space-y-2">
                          {valueOptions.map((value) => (
                            <label
                              key={value}
                              className="flex items-center space-x-2"
                            >
                              <input
                                type="checkbox"
                                checked={selections["FAR"]?.[
                                  filterName
                                ]?.includes(value)}
                                onChange={() => {
                                  const currentValues =
                                    selections["FAR"]?.[filterName] || [];
                                  const newValues = currentValues.includes(
                                    value
                                  )
                                    ? currentValues.filter((v) => v !== value)
                                    : [...currentValues, value];
                                  handleFilterSelect(
                                    "FAR",
                                    filterName,
                                    newValues
                                  );
                                }}
                                className="form-checkbox h-4 w-4 text-blue-600"
                              />
                              <span className="text-sm text-gray-700">
                                {value}
                              </span>
                            </label>
                          ))}
                        </div>
                      ) : (
                        <select
                          value={selections["FAR"]?.[filterName] || ""}
                          onChange={(e) =>
                            handleFilterSelect(
                              "FAR",
                              filterName,
                              e.target.value
                            )
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                          <option value="">All</option>
                          {valueOptions.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  // Update handleSubmit to work with new structure
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    try {
      // postApi("zoning-analysis", {
      //   dynamicSelections,
      // });
    } catch (error) {
      console.error("Error submitting:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  // Add this useEffect for handling clicks outside the suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Update the debounced search function to track loading state
  const debouncedSearch = useCallback(
    debounce(async (text) => {
      if (text.length < 3) {
        setSuggestions([]);
        setIsSearching(false);
        return;
      }

      try {
        setIsSearching(true);
        const response = await fetch(
          `https://geosearch.planninglabs.nyc/v2/autocomplete?text=${encodeURIComponent(
            text
          )}`
        );
        const data = await response.json();
        setSuggestions(data.features);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    setShowSuggestions(true);
    debouncedSearch(value);
  };

  const handleSuggestionClick = (suggestion, shouldAdd = false) => {
    const lotId = suggestion.properties.addendum.pad.bbl;

    if (!selectedLots.some((lot) => lot.lotId === lotId)) {
      setSearchInput("");
      setShowSuggestions(false);
      handleSearch(null, lotId, suggestion.properties.label, shouldAdd);
    }
  };

  const handleAddressRemove = async (lotIdToRemove) => {
    const newLots = selectedLots.filter((lot) => lot.lotId !== lotIdToRemove);
    setSelectedLots(newLots);

    if (newLots.length === 0) {
      // Reset state if no lots left
      setMetadataRecords(null);
      setLotArea(null);
      setPrimarySelections({});
    }
  };

  // Add or update these helper functions
  const isValidBBLPrefix = (bbl) => {
    if (!bbl || !/^\d+$/.test(bbl)) return false;

    // Check first digit (borough) if entered
    if (bbl.length >= 1 && !/^[1-5]$/.test(bbl[0])) return false;

    // Check block portion (next 5 digits) if any are entered
    if (bbl.length > 1) {
      const blockPart = bbl.slice(1, Math.min(6, bbl.length));
      if (!/^\d+$/.test(blockPart)) return false;
    }

    // Check lot portion (last 4 digits) if any are entered
    if (bbl.length > 6) {
      const lotPart = bbl.slice(6);
      if (!/^\d+$/.test(lotPart)) return false;
    }

    return true;
  };

  // Update the debounced BBL search
  const debouncedBblSearch = useCallback(
    debounce(async (bbl) => {
      if (!isValidBBLPrefix(bbl)) {
        setBblMatches([]);
        return;
      }

      try {
        setIsLoadingBbl(true);
        setBblMatches(await getBblMatches(bbl));
      } catch (error) {
        console.error("Error fetching BBL addresses:", error);
        setBblMatches([]);
      } finally {
        setIsLoadingBbl(false);
      }
    }, 300),
    []
  );

  // Update useEffect to use new validation
  useEffect(() => {
    if (/^\d+$/.test(searchInput)) {
      debouncedBblSearch(searchInput);
    } else {
      setBblMatches([]);
    }
  }, [searchInput, debouncedBblSearch]);

  // Add this helper function
  const highlightBBLPrefix = (bbl, prefix) => {
    const bblString = bbl.toString();
    return (
      <span>
        <span className="font-bold">{bblString.slice(0, prefix.length)}</span>
        {bblString.slice(prefix.length)}
      </span>
    );
  };

  const selectionMatchesValue = (filterName, selectedValue, recordValue) => {
    if (selectedValue === undefined) {
      return true;
    }
    if (filterName === "Filter2Sub") {
      return selectedValue.length === 0 || selectedValue.some((opt) => recordValue.includes(opt));
    } else {
      return !selectedValue || selectedValue === 'All' || recordValue === selectedValue;
    }
  };

  // Add this new function after the HeightDiagram component
  const mergeFarAndHeightData = (farGroups, heightGroups, yardsGroups, parkingGroups) => {
    console.log("=== Starting mergeFarAndHeightData ===");
    console.log("farGroups:", farGroups);
    console.log("heightGroups:", heightGroups);
    console.log("yardsGroups:", yardsGroups);
    console.log("parkingGroups:", parkingGroups);
    const mergedGroups = new Map();

    // Helper function to get or create a group
    const getOrCreateGroup = (key, displayHeading) => {
      if (!mergedGroups.has(key)) {
        mergedGroups.set(key, {
          displayHeading,
          pathGroups: [],
          aggregated: {
            farByUseGroup: {},
            heightAttributes: {},
            yardAttributes: [],
            parkingAttributes: []
          },
          filters: {}
        });
      }
      return mergedGroups.get(key);
    };

    // Process FAR groups
    if (farGroups && typeof farGroups === 'object') {
      Object.entries(farGroups).forEach(([key, group]) => {
        const mergedGroup = getOrCreateGroup(key, group.displayHeading);
        mergedGroup.pathGroups = group.pathGroups;
        mergedGroup.aggregated.farByUseGroup = group.aggregated.farByUseGroup;
        mergedGroup.filters = group.filters || {};
      });
    }

    // Process height groups
    if (heightGroups && typeof heightGroups === 'object') {
      Object.entries(heightGroups).forEach(([key, group]) => {
        const mergedGroup = getOrCreateGroup(key, group.displayHeading);
        
        const pathMap = new Map();
        mergedGroup.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          pathMap.set(pathKey, pathGroup);
        });

        group.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          if (pathMap.has(pathKey)) {
            const existingGroup = pathMap.get(pathKey);
            existingGroup.record.heightOutput = pathGroup.record.heightOutput;
          } else {
            pathMap.set(pathKey, pathGroup);
          }
        });

        mergedGroup.pathGroups = Array.from(pathMap.values());
        mergedGroup.filters = {
          ...mergedGroup.filters,
          ...(group.filters || {})
        };
        
        mergedGroup.aggregated.heightAttributes = group.aggregated.heightAttributes || {};
      });
    }

    // Process yards groups
    if (yardsGroups && typeof yardsGroups === 'object') {
      console.log('merging yardsGroups:', yardsGroups);
      Object.entries(yardsGroups).forEach(([key, group]) => {
        const mergedGroup = getOrCreateGroup(key, group.displayHeading);
        
        const pathMap = new Map();
        mergedGroup.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          pathMap.set(pathKey, pathGroup);
        });

        group.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          if (pathMap.has(pathKey)) {
            const existingGroup = pathMap.get(pathKey);
            existingGroup.record.yardsOutput = pathGroup.record.yardsOutput;
          } else {
            pathMap.set(pathKey, pathGroup);
          }
        });

        mergedGroup.pathGroups = Array.from(pathMap.values());
        mergedGroup.filters = {
          ...mergedGroup.filters,
          ...(group.filters || {})
        };
        
        mergedGroup.aggregated.yardAttributes = group.aggregated.yardAttributes || [];
      });
    }

    // Process parking groups
    if (parkingGroups && typeof parkingGroups === 'object') {
      console.log('merging parkingGroups:', parkingGroups);
      Object.entries(parkingGroups).forEach(([key, group]) => {
        const mergedGroup = getOrCreateGroup(key, group.displayHeading);
        
        const pathMap = new Map();
        mergedGroup.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          pathMap.set(pathKey, pathGroup);
        });

        group.pathGroups.forEach(pathGroup => {
          const pathKey = JSON.stringify(pathGroup.path.map(p => `${p.filterName}:${p.value}`));
          if (pathMap.has(pathKey)) {
            const existingGroup = pathMap.get(pathKey);
            existingGroup.record.parkingOutput = pathGroup.record.parkingOutput;
          } else {
            pathMap.set(pathKey, pathGroup);
          }
        });

        mergedGroup.pathGroups = Array.from(pathMap.values());
        mergedGroup.filters = {
          ...mergedGroup.filters,
          ...(group.filters || {})
        };
        
        mergedGroup.aggregated.parkingAttributes = group.aggregated.parkingAttributes || [];
      });
    }

    return mergedGroups;
  };

  // Update the renderFarResults function to use merged data
  const renderFarResults = () => {
    console.log("=== Starting renderFarResults ===");
    console.log("farGroups:", farGroups);
    console.log("heightGroups:", heightGroups);
    console.log("yardsGroups:", yardsGroups);
    console.log("parkingGroups:", parkingGroups);
    console.log("Current selections:", selections);
    
    const mergedGroups = mergeFarAndHeightData(farGroups, heightGroups, yardsGroups, parkingGroups);
    
    const entries = Array.from(mergedGroups.entries());
    console.log("Number of entries to filter:", entries.length);
    
    const filteredFarGroups = entries.filter(([groupKey, group]) => {
      console.log("=== Processing group ===");
      console.log("groupKey:", groupKey);
      console.log("group:", group);
      
      try {
        // Parse the JSON string key into an object
        const groupFilters = JSON.parse(groupKey);
        console.log("Parsed group filters:", groupFilters);
        
        // If no filters, include the group
        if (!groupFilters || Object.keys(groupFilters).length === 0) {
          console.log("No filters found, including group");
          return true;
        }

        // Check if all filters match current selections
        const matches = Object.entries(groupFilters).every(([filterName, filterValue]) => {
          const selectedValue = selections["FAR"]?.[filterName];
          console.log(`Checking filter ${filterName}:`, {
            filterValue,
            selectedValue,
            matches: selectionMatchesValue(filterName, selectedValue, filterValue)
          });
          return selectionMatchesValue(filterName, selectedValue, filterValue);
        });
        
        console.log("Group matches:", matches);
        return matches;
      } catch (error) {
        console.error("Error parsing group key:", error);
        return false;
      }
    });

    console.log("=== After filtering ===");
    console.log("filteredFarGroups:", filteredFarGroups);

    // Helper function to get sort priority for Filter 2 values
    const getFilter2Priority = (value) => {
      const priorities = {
        'Residential': 0,
        'Commercial': 1,
        'Community Facility': 2
      };
      return priorities[value] ?? 3;
    };

    // Sort the filtered groups
    // TODO: not sure if this is correct
    const sortedFarGroups = filteredFarGroups.sort(([keyA, groupA], [keyB, groupB]) => {
      const filtersA = Object.entries(groupA.filters);
      const filtersB = Object.entries(groupB.filters);
      
      // Compare each filter field in order
      const minLength = Math.min(filtersA.length, filtersB.length);
      
      for (let i = 0; i < minLength; i++) {
        const [nameA, valueA] = filtersA[i];
        const [nameB, valueB] = filtersB[i];
        
        // If this is Filter 2, use priority sorting
        if (nameA === 'Filter 2 (User to Select Preference)' && nameB === 'Filter 2 (User to Select Preference)') {
          const priorityA = getFilter2Priority(valueA);
          const priorityB = getFilter2Priority(valueB);
          if (priorityA !== priorityB) return priorityA - priorityB;
        }
      }
      
      // If all common fields are equal, shorter arrays come first
      return filtersA.length - filtersB.length;
    });

    console.log('sortedFarGroups', sortedFarGroups);
    console.log('useGroups', useGroups);
    return (
      <div className="mt-8 border border-gray-300">
        <div className="text-center border-b border-gray-300 p-2">
          <h2 className="text-lg font-bold text-blue-900">
            ZONING CALCULATIONS •{" "}
            {selectedLots.map((lot) => lot.address).join(" + ")} • Lot Area ={" "}
            {lotArea?.toLocaleString()} SF
          </h2>
        </div>
        {(!sortedFarGroups.length && !useGroups.length) ? (
          <div className="p-4 text-center text-gray-600">
            No zoning rules found for the selected criteria.
          </div>
        ) : (
          sortedFarGroups.map(([groupKey, group], index) => (
            <FarGroup
              key={index}
              group={group}
              groupKey={groupKey}
              expandedSections={expandedSections}
              setExpandedSections={setExpandedSections}
              lotArea={lotArea}
              primarySelections={primarySelections}
            />
          ))
        )}
        {useGroups && <UseGroupTable useGroupData={useGroups} />}
      </div>
    );
  };

  const handleSubmitForReview = async () => {
    try {
      setIsLoading(true);

      const response = await postApi(
        {
          lotIds: selectedLots.map((lot) => lot.lotId),
          address: selectedLots.map((lot) => lot.address),
          label: analysisLabel,
          lotAreaSqft: lotArea,
          zoningAttributes: primarySelections,
          userSelections: selections,
          rules: metadataRecords,
          notes: operatorNotes,
        },
        "operator/zoning-analysis",
        token
      );

      if (response) {
        setAnalysisId(response.analysis._id);
        setAnalysisStatus(response.analysis.status);
        // Navigate to the new URL with the analysis ID
        navigate(`/zoning-analysis/${response.analysis._id}`);
      }
    } catch (error) {
      setErrorMessage("Failed to submit analysis for review");
      console.error("Submit error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMarkAsReviewed = async () => {
    try {
      setIsLoading(true);
      console.log('response', primarySelections, operatorNotes);
      const response = await postApi(
        {
          primarySelections,
          notes: operatorNotes,
        },
        `operator/zoning-analysis/${analysisId}/review`,
        token
      );

      if (response) {
        setAnalysisStatus("reviewed");
        setReviewedAt(
          response.analysis.reviews[response.analysis.reviews.length - 1]
            .reviewedAt
        );
      }
    } catch (error) {
      setErrorMessage("Failed to mark analysis as reviewed");
      console.error("Review status update error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update debounced version to use all primary selections
  const debouncedPrimaryFilterUpdate = useCallback(
    debounce(async (newPrimarySelections, selectedLots, lotArea, token, callbacks) => {
      const { setIsLoading, setPrimarySelections, setFarGroups, setHeightGroups, setYardsGroups, setParkingGroups, setErrorMessage } = callbacks;
      try {
        setIsLoading(true);

        const response = await getApi(
          `operator/zoning-analysis-recompute?${new URLSearchParams({
            lotIds: JSON.stringify(selectedLots.map(lot => lot.lotId)),
            primarySelections: JSON.stringify(newPrimarySelections),
            lotArea: lotArea.toString(),
          })}`,
          token
        );

        if (response) {
          // setPrimarySelections(newPrimarySelections);
          setFarGroups(response.far);
          setHeightGroups(response.heights);
          setYardsGroups(response.yards);
          setParkingGroups(response.parking);
          setUseGroups(response.useGroupOutput);
        }
      } catch (error) {
        console.error('Failed to update calculations:', error);
        setErrorMessage('Failed to update calculations');
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );

  // Update the handlePrimaryFilterUpdate function to send all primary selections
  const handlePrimaryFilterUpdate = (name, value) => {
    if (mode === "operator" || debugMode) {
      const newPrimarySelections = {
        ...primarySelections,
        [name]: value,
      };

      setPrimarySelections(newPrimarySelections);

      debouncedPrimaryFilterUpdate(
        newPrimarySelections,
        selectedLots, 
        lotArea,
        token,
        {
          setIsLoading,
          setPrimarySelections,
          setFarGroups,
          setHeightGroups,
          setYardsGroups,
          setParkingGroups,
          setUseGroups,
          setErrorMessage
        }
      );
    }
  };

  // Update the renderPrimaryFilters function to use the new handler
  const renderPrimaryFilters = () => {
    const possibleValues = {};
    Object.values(PRIMARY_FILTERS).forEach((filterName) => {
      if (BOOLEAN_FILTERS.includes(filterName)) {
        possibleValues[filterName] = [];
      } else if (primarySelections[filterName]) {
        possibleValues[filterName] = Object.keys(primarySelections[filterName]);
      }
    });

    return (
      <div className="mb-8 border-b pb-6">
        <h2 className="text-lg font-semibold mb-4">Zoning Lot Attributes</h2>
        {Object.entries(possibleValues).map(([filterName, values]) => (
          <PrimaryFilterSection
            key={filterName}
            filterName={filterName}
            possibleValues={values}
            selections={primarySelections}
            onUpdate={handlePrimaryFilterUpdate}  // Updated to use new handler
            isBoolean={BOOLEAN_FILTERS.includes(filterName)}
            readOnly={mode === "user" && !debugMode}
          />
        ))}
      </div>
    );
  };

  // Add this function to update remaining lots
  const handleSubscriptionUpdate = (isActive) => {
    setSubscriptionActive(isActive);
  };

  // Update the search section render logic
  const renderSearchSection = () => {
    return (
      <div className="mb-6 relative" ref={searchRef}>
            <form className="mb-1">
              <div className="flex flex-col sm:flex-row gap-2">
                <input
                  type="text"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                  onFocus={() => setShowSuggestions(true)}
                  placeholder="Enter address or BBL (Borough Block Lot)"
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            </form>

            {/* Suggestions dropdown */}
            {showSuggestions && searchInput.length >= 1 && (
              <div className="absolute z-50 w-full bg-white mt-1 border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                {/^\d+$/.test(searchInput) && (
                  // BBL section
                  <>
                    <div className="px-4 py-2 bg-gray-100 font-semibold">
                      Search by BBL
                    </div>
                    <div
                      className={`${
                        !isValidBBLPrefix(searchInput) ? "px-4 py-2" : ""
                      }`}
                    >
                      {!isValidBBLPrefix(searchInput) ? (
                        <div className="text-sm text-red-400">
                          Invalid BBL format
                        </div>
                      ) : isLoadingBbl ? (
                        <div className="px-4 py-4 text-center text-gray-500">
                          <svg
                            className="animate-spin h-5 w-5 mx-auto mb-2"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <span className="text-sm">
                            Looking up addresses...
                          </span>
                        </div>
                      ) : bblMatches.length > 0 ? (
                        bblMatches.map((match) => {
                          const isSelected = selectedLots.some(
                            (lot) => lot.lotId === match.bbl
                          );
                          return (
                            <div
                              key={match.bbl}
                              className={`px-4 py-2 ${
                                isSelected
                                  ? "bg-gray-100 cursor-not-allowed text-gray-400"
                                  : "hover:bg-gray-100 cursor-pointer"
                              }`}
                            >
                              <div
                                className="flex justify-between items-center"
                                onClick={() => {
                                  if (!isSelected) {
                                    handleSearch(
                                      null,
                                      match.bbl,
                                      match.address
                                    );
                                  }
                                }}
                              >
                                <div>
                                  <div className="text-sm">{match.address}</div>
                                  <div className="text-xs text-gray-500 font-mono">
                                    {highlightBBLPrefix(match.bbl, searchInput)}
                                  </div>
                                </div>
                                {!isSelected && (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSearch(
                                        null,
                                        match.bbl,
                                        match.address,
                                        true
                                      );
                                    }}
                                    className="text-green-600 hover:text-green-800 text-sm"
                                  >
                                    Add to Assemblage
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="px-4 py-2 text-sm text-red-400">
                          No matching addresses found
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Address suggestions section - always show when there's input */}
                <div className="px-4 py-2 bg-gray-100 font-semibold">
                  Search by Address
                </div>
                {searchInput.length < 3 ? (
                  // Message when input is too short
                  <div className="px-4 py-4 text-center text-gray-500">
                    <span className="text-sm">
                      Please enter at least 3 characters to search addresses
                    </span>
                  </div>
                ) : isSearching ? (
                  // Loading state - only show when actively searching
                  <div className="px-4 py-4 text-center text-gray-500">
                    <svg
                      className="animate-spin h-5 w-5 mx-auto mb-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span className="text-sm">Searching addresses...</span>
                  </div>
                ) : suggestions.length > 0 ? (
                  suggestions.map((suggestion) => {
                    const address = suggestion.properties.label;
                    const bbl = suggestion.properties.addendum.pad.bbl;
                    const isSelected = selectedLots.some(
                      (lot) => lot.lotId === bbl
                    );
                    return (
                      <div
                        key={suggestion.properties.id}
                        className={`px-4 py-2 ${
                          isSelected
                            ? "bg-gray-100 cursor-not-allowed text-gray-400"
                            : "hover:bg-gray-100 cursor-pointer"
                        }`}
                      >
                        <div
                          className="flex justify-between items-center"
                          onClick={() => {
                            if (!isSelected) {
                              handleSuggestionClick(suggestion);
                            }
                          }}
                        >
                          <div>
                            <div className="text-sm">{address}</div>
                            <div className="text-xs text-gray-500">{bbl}</div>
                          </div>
                          {!isSelected && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSuggestionClick(suggestion, true);
                              }}
                              className="text-green-600 hover:text-green-800 text-sm"
                            >
                              Add to Assemblage
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  // No results found
                  <div className="px-4 py-4 text-center text-gray-500">
                    <span className="text-sm">No addresses found</span>
                  </div>
                )}
              </div>
            )}
          </div>
    );
  };

  const UseGroupTable = ({ useGroupData }) => {
    if (!useGroupData) return null;
    console.log('useGroupData', useGroupData);
    // Group by use group to handle building type variants
    // const groupedData = useGroupData.reduce((acc, entry) => {
    //   const key = entry.useGroup;
    //   if (!acc[key]) acc[key] = [];
    //   acc[key].push(entry);
    //   return acc;
    // }, {});

    return (
      <div className="mt-4">
        {/* <h3 className="text-lg font-semibold mb-2">Use Groups Analysis</h3> */}
        {/* <div className="overflow-x-auto"> */}
          <table className="w-full divide-y divide-gray-200" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Use Group
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Permitted As-of-Right
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Special Permit Required
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Not Permitted
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Size Limitations
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.values(useGroupData).map((useGroup) => useGroup.aggregated.useGroups[0].useGroups.map((entry, idx) => (
                <tr key={`${useGroup.displayHeading}-${idx}`} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                  {(
                      <td
                        className="px-6 py-4 text-sm font-medium text-gray-900"
                        // rowSpan={useGroup.length}
                      >
                        {useGroup.displayHeading}: {entry.useGroup}
                      </td>
                    )}
                    <td className="px-6 py-4 text-sm text-gray-500">
                      <div className="space-y-1">
                        {entry.permittedAsOfRight ? (
                          <div className="flex items-center">
                            <CheckCircleIcon className="w-4 h-4 text-green-500" />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <XCircleIcon className="w-4 h-4 text-red-500" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {entry.specialPermitRequired ? (
                        <div className="flex items-center">
                          <CheckCircleIcon className="w-4 h-4 text-green-500" />
                        </div>
                        ) : (
                          <div className="flex items-center">
                            <XCircleIcon className="w-4 h-4 text-red-500" />
                          </div>
                        )}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                    {entry.notPermitted ? (
                        <div className="flex items-center">
                          <CheckCircleIcon className="w-4 h-4 text-green-500" />
                        </div>
                        ) : (
                          <div className="flex items-center">
                            <XCircleIcon className="w-4 h-4 text-red-500" />
                          </div>
                        )}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {entry.additionalConditions}
                    </td>
                  </tr>
                )))}
            </tbody>
          </table>
        {/* </div> */}
      </div>
    );
  };

  return (
    <div className="min-h-screen grid grid-rows-[auto_1fr]">
      {/* Fixed header */}
      <div className="sticky top-0 bg-white shadow-md z-[9]">
        <div className="flex flex-col sm:flex-row justify-between items-center p-4">
          <div className="flex-1 w-full sm:w-auto mb-2 sm:mb-0 flex items-center gap-4">
            {mode === "user" && (
              <SubscriptionStatus 
                token={token} 
                onSubscriptionUpdate={handleSubscriptionUpdate}
              />
            )}
            {/* Add debug toggle for admin users */}
            {isAdmin && (
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={debugMode}
                  onChange={(e) => handleDebugToggle(e.target.checked)}
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="text-sm text-gray-700">Debug (admin only)</span>
              </label>
            )}
          </div>
          {/* Submit Form */}
          <form onSubmit={handleSubmit} className="w-full sm:w-auto">
            <div className="flex flex-col sm:flex-row items-center gap-4">
              {mode === "user" && (
                <>
                  {analysisStatus === "reviewed" ? (
                    <div className="text-green-600 px-4 text-sm">
                      Reviewed on {new Date(reviewedAt).toLocaleString()}
                    </div>
                  ) : (
                    <>
                      <input
                        type="text"
                        value={analysisLabel}
                        onChange={(e) => setAnalysisLabel(e.target.value)}
                        placeholder="Analysis Label (Optional)"
                        className="w-full sm:w-[300px] p-2 border border-gray-300 rounded"
                        disabled={
                          analysisStatus === "pending" ||
                          !selectedLots.length ||
                          isLoading
                        }
                      />
                      <button
                        onClick={handleSubmitForReview}
                        disabled={
                          !selectedLots.length ||
                          analysisStatus === "pending" ||
                          isLoading
                        }
                        className={`w-full sm:w-auto px-4 py-2 rounded ${
                          !selectedLots.length ||
                          analysisStatus === "pending" ||
                          isLoading
                            ? "bg-gray-300 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-700 text-white"
                        }`}
                      >
                        {analysisStatus === "pending"
                          ? "Under Review"
                          : "Submit for Review"}
                      </button>
                    </>
                  )}
                </>
              )}
              {mode === "operator" && (
                <>
                  {analysisStatus === "reviewed" ? (
                    <div className="text-green-600 px-4">
                      Reviewed on {new Date(reviewedAt).toLocaleString()}
                    </div>
                  ) : (
                    analysisStatus === "pending" && (
                      <button
                        onClick={handleMarkAsReviewed}
                        disabled={isLoading}
                        className={`px-4 py-2 rounded ${
                          isLoading
                            ? "bg-gray-300 cursor-not-allowed"
                            : "bg-green-600 hover:bg-green-700 text-white"
                        }`}
                      >
                        Save & Mark as Reviewed
                      </button>
                    )
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* Main content */}
      <div className="container mx-auto px-4 py-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Address Lookup</h1>
        {!id && renderSearchSection()}

        {/* Selected addresses with improved mobile layout */}
        {selectedLots.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4 mb-4">
            {selectedLots.map(({ lotId, address }) => (
              <div
                key={lotId}
                className="flex items-center bg-blue-100 rounded-full px-3 py-1 w-full sm:w-auto"
              >
                <span className="text-sm text-blue-800 truncate">
                  {lotId} ({address})
                </span>
                <button
                  onClick={() => handleAddressRemove(lotId)}
                  className="ml-2 text-blue-600 hover:text-blue-800 shrink-0"
                  disabled={isLoading || id}
                  hidden={id}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Add notes section for operator mode */}
        {mode === "operator" && analysisStatus !== "reviewed" && (
          <div className="w-full mb-4">
            <textarea
              value={operatorNotes}
              onChange={(e) => setOperatorNotes(e.target.value)}
              placeholder="Add notes for the user (optional)"
              className="w-full p-2 border border-gray-300 rounded h-24 resize-none"
            />
          </div>
        )}
        
        {/* Show notes for users when viewing reviewed analysis */}
        {mode === "user" && analysisStatus === "reviewed" && operatorNotes && (
          <div className="w-full mb-4 bg-blue-50 p-4 rounded-lg">
            <h3 className="font-semibold text-blue-900 mb-2">Reviewer Notes:</h3>
            <div className="text-blue-800 whitespace-pre-wrap">
              {operatorNotes}
            </div>
          </div>
        )}

        {quotaExceeded && (
          <div className="text-center py-8">
            <h2 className="text-xl font-semibold mb-4">No Remaining Searches</h2>
            <p className="text-gray-600 mb-4">
              You've used all your available lot searches, or your subscription has expired.
              Please see for subscription options below.
              You can still search for previously analyzed lots.
            </p>
            <SubscriptionCard />
          </div>
        )}

        {errorMessage && (
          <div className="mb-4 text-red-600">{errorMessage}</div>
        )}

        {isLoading ? (
          <div>Loading...</div>
        ) : selectedLots.length && !quotaExceeded ? (
          <>
            {(mode !== "user" || debugMode) && renderPrimaryFilters()}

            {/* Dynamic Filters */}
            {renderDynamicFilters()}

            {/* Combined FAR and Height Results */}
            {renderFarResults()}
          </>
        ) : !isLoading && !selectedLots.length && subscriptionActive ? (
          <div>Enter an address to begin</div>
        ) : ''}

        {/* Add debug section at the bottom */}
        {debugMode && isAdmin && (
          <div className="mt-8 border-t pt-8">
            <h2 className="text-lg font-semibold mb-4">Debug Information</h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium mb-2">Raw Metadata Response:</h3>
                <pre className="bg-gray-100 p-4 rounded-lg overflow-auto max-h-[500px] text-sm whitespace-pre-wrap break-words">
                  {JSON.stringify({
                    lotArea,
                    primarySelections,
                    metadataRecords,
                    zoningOutput: Object.entries(farGroups).map(([key, value]) => ({
                      key,
                      ...value,
                    })),
                  }, null, 2)}
                </pre>
              </div>
              <div>
                <h3 className="font-medium mb-2">Current Selections:</h3>
                <pre className="bg-gray-100 p-4 rounded-lg overflow-auto max-h-[500px] text-sm whitespace-pre-wrap break-words">
                  {JSON.stringify(selections, null, 2)}
                </pre>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicForm;

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Link,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '../../../context/AuthContext';
import { getApi } from '../../../Api/Api';

const AddressDataPage = () => {
  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const { token } = useAuth();

  const fetchAddressData = async () => {
    try {
      setLoading(true);
      const response = await getApi(
        `admin/address-data?page=${page + 1}&limit=${rowsPerPage}`,
        token,
      );

      console.log(response);

      setAddressData(response.data);
      setTotalCount(response.totalDocuments);
    } catch (error) {
      console.error('Error fetching address data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Address Data
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Address (BBL)</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Lot Area</TableCell>
              <TableCell>Zoning Districts</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addressData.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.address} ({row.lotId})</TableCell>
                <TableCell>{row.version}</TableCell>
                <TableCell>{row.lotArea?.toLocaleString()}</TableCell>
                <TableCell>{row.zoningDistricts?.join(', ')}</TableCell>
                <TableCell>
                  {row.createdAt && formatDistanceToNow(new Date(row.createdAt), { addSuffix: true })}
                </TableCell>
                <TableCell>
                  {row.operatorFilterUrl && (
                    <Link href={row.operatorFilterUrl} target="_blank" rel="noopener noreferrer">
                      View Operator Filter
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};

export default AddressDataPage;

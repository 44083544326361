import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ApplyChanges from "./ApplyChanges";

const DesignSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const [additionalSections, setAdditionalSections] = useState([]);

  const addSection = () => {
    const newId = additionalSections.length
      ? additionalSections[additionalSections.length - 1].id + 1
      : 1;
    setAdditionalSections([
      ...additionalSections,
      { id: newId, label: `New Section ${newId}` },
    ]);
  };

  const removeSection = (id) => {
    setAdditionalSections(
      additionalSections.filter((section) => section.id !== id)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  useEffect(() => {
    let addODC = additionalSections.map((section) => section.value);

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        addODC: addODC,
      },
    }));
  }, [additionalSections, currentTable]);

  const handleInputChange = (id, field, newValue) => {
    if (field === "value") {
      newValue = newValue.replace(/[^0-9.-]/g, "");

      if (newValue === "" || isNaN(newValue)) {
        newValue = "0";
      }
    }
    setAdditionalSections(
      additionalSections.map((section) =>
        section.id === id ? { ...section, [field]: newValue } : section
      )
    );
  };

  return (
    <>
      <div className="px-4 pb-3 text-gray-600">
        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-1/3">Design Cost</label>
          <input
            type="text"
            name="designCost"
            className="border-[1px] px-1 rounded w-1/3 border-black"
            value={toggleData?.designCost || "--"}
            onChange={handleChange}
          />
          <span className="w-1/4 text-center">$/SF</span>
        </div>

        {additionalSections.map((section) => (
          <div key={section.id}>
            <div className="flex items-center my-2 justify-end mt-5">
              <div className="flex flex-col w-full">
                <div className="flex space-x-2 w-full justify-between">
                  <span className="w-1/4">Name</span>
                  <input
                    type="text"
                    name="name"
                    value={section.name}
                    onChange={(e) =>
                      handleInputChange(
                        section.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    className="border-[1px] px-1 rounded w-1/4 border-black"
                  />
                  <span className="w-1/4">Value</span>
                  <input
                    type="text"
                    name="value"
                    value={section.value}
                    onChange={(e) =>
                      handleInputChange(
                        section.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    className="border-[1px] px-1 rounded w-1/4 border-black"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => removeSection(section.id)}
                className="text-blue-500 text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        ))}

        <button
          onClick={addSection}
          className="flex items-center mt-4 text-blue-500"
        >
          <FaPlus className="mr-2" /> Add
        </button>
      </div>

      <div className="px-4 pb-3 text-gray-600">
        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-1/3">Total Design Cost</label>
          <input
            type="text"
            className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
            disabled={true}
            value={toggleData?.TotalDesignCost || "--"}
          />
          <span className="w-1/4 text-center">$</span>
        </div>
        <ApplyChanges
          updateCapData={updateCapData}
          capData={capData}
          currentTable={currentTable}
          isUpdateCapDataLoading={isUpdateCapDataLoading}
        />
      </div>
    </>
  );
};

export default DesignSection;

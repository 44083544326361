import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [admin, setAdmin] = useState(false);

  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("authToken");
    navigate("/admin-login");
  };

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      setAdmin(true);
    }
  }, []);

  const menuItems = [
    { to: "/about", label: "About" },
    { to: "/services", label: "Services" },
    { to: "/contact", label: "Contact" },
    ...(admin
      ? [
          { to: "/operator", label: "Admin" },
          { to: "#", label: "Logout", onClick: handleLogout },
        ]
      : [{ to: "/admin-login", label: "Login" }]),
  ];

  return (
    <nav className="bg-white shadow-lg fixed top-0 left-0 z-10 w-full">
      <div className="mx-auto px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center">
              <span className="font-bold text-gray-900 text-2xl">
                NYCZoning AI
              </span>
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {menuItems.map((item) =>
                item.onClick ? (
                  <button
                    key={item.label}
                    onClick={item.onClick}
                    className="text-gray-800 hover:text-blue-500 px-3 py-2 rounded-md text-lg font-semibold transition duration-300"
                  >
                    {item.label}
                  </button>
                ) : (
                  <Link
                    key={item.to}
                    to={item.to}
                    className="text-gray-800 hover:text-blue-500 px-3 py-2 rounded-md text-lg font-semibold transition duration-300"
                  >
                    {item.label}
                  </Link>
                )
              )}
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`${isMenuOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isMenuOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${isMenuOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {menuItems.map((item) =>
            item.onClick ? (
              <button
                key={item.label}
                onClick={item.onClick}
                className="text-gray-500 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium transition duration-300"
              >
                {item.label}
              </button>
            ) : (
              <Link
                key={item.to}
                to={item.to}
                className="text-gray-500 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium transition duration-300"
              >
                {item.label}
              </Link>
            )
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

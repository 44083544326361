import React, { useState } from "react";
import ListingSection from "./components/ListingSection";
import DemoSection from "./components/DemoSection";
import ConstructionSection from "./components/ConstructionSection";
import AdditionalConstructionSection from "./components/AdditionalConstructionSection";
import DesignSection from "./components/DesignSection";
import RevenueSection from "./components/RevenueSection";
import ManagementSection from "./components/ManagementSection";
import BreakDownOvertime from "./components/BreakDownOvertime";
import RentalPropertyCalculator from "./components/RentalPropertyCalculator";
import { SquareX } from "lucide-react";
import ConstructionLoan from "./components/ConstructionLoan";
import RenovationSection from "./components/RenovationSection";
import PurchaseLoan from "./components/PurchaseLoan.js";
import SaleSection from "./components/SaleSection.js";

const CapModal = ({
  isOpen,
  onClose,
  capData,
  setCapData,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const [openSection, setOpenSection] = useState(null);
  const [currentTable, setCurrentTable] = useState("rental");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  if (!isOpen) return null;

  let toggleData;
  if (currentTable === "rental") {
    toggleData = capData?.rental;
  } else if (currentTable === "condo") {
    toggleData = capData?.condo;
  } else if (currentTable === "existing") {
    toggleData = capData?.existing;
  }

  let capRate = toggleData?.CapRate?.[0] ?? toggleData?.CapRate ?? "--";
  capRate = typeof capRate === "number" ? capRate.toFixed(2) : capRate;

  let deptYield = toggleData?.DeptYield?.[0] ?? toggleData?.DeptYield ?? "--";
  deptYield = typeof deptYield === "number" ? deptYield.toFixed(2) : deptYield;

  let IRR = toggleData?.IRR?.[0] ?? toggleData?.IRR ?? "--";
  IRR = typeof IRR === "number" ? IRR.toFixed(2) : IRR;

  let CashOnCashReturn =
    toggleData?.CashOnCashReturn?.[0] ?? toggleData?.CashOnCashReturn ?? "--";
  CashOnCashReturn =
    typeof CashOnCashReturn === "number"
      ? CashOnCashReturn.toFixed(2)
      : CashOnCashReturn;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg overflow-auto max-h-[90vh]">
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-4">
          <div className="p-4 flex items-center justify-between ">
            <div className="flex flex-col text-sm text-gray-600 items-center">
              <p>CAP Rate</p>
              <p>{capRate}%</p>
            </div>

            <div className="flex flex-col text-sm text-gray-600 items-center">
              <p>IRR</p>
              <p>{IRR}%</p>
            </div>

            <div className="flex flex-col text-sm text-gray-600 items-center">
              <p>Dept Yield</p>
              <p>{deptYield}%</p>
            </div>

            <div className="flex flex-col text-sm text-gray-600 items-center">
              <p>Cash on cash return</p>
              <p>{CashOnCashReturn}%</p>
            </div>
            <SquareX className="hover:cursor-pointer" onClick={onClose} />
          </div>

          <div className="p-4 flex items-center justify-between border-b">
            <div className="flex flex-row text-sm text-gray-600 gap-2 items-center">
              <p>Develop Rental</p>
              <input
                type="checkbox"
                name="table"
                onClick={() => setCurrentTable("rental")}
                checked={currentTable === "rental"}
                className="form-checkbox h-4 w-4 text-gray-600"
              />
            </div>

            <div className="flex flex-row text-sm text-gray-600 gap-2 items-center">
              <p>Develop Condo</p>
              <input
                type="checkbox"
                name="table"
                onClick={() => setCurrentTable("condo")}
                checked={currentTable === "condo"}
                className="form-checkbox h-4 w-4 text-gray-600"
              />
            </div>

            <div className="flex flex-row text-sm text-gray-600 gap-2 items-center">
              <p>Keep Existing</p>
              <input
                type="checkbox"
                name="table"
                onClick={() => setCurrentTable("existing")}
                checked={currentTable === "existing"}
                className="form-checkbox h-4 w-4 text-gray-600"
              />
            </div>
          </div>

          <div className="divide-y">
            <div>
              <button
                onClick={() => toggleSection("listing")}
                className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                  openSection === "listing"
                    ? "bg-blue-100 text-blue-500"
                    : "text-gray-700"
                } hover:bg-blue-50 gap-x-4`}
              >
                <span className="flex-1 min-w-[200px]">Listings</span>
                <input
                  className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                  value={`${
                    toggleData?.price_sf != null
                      ? toggleData?.price_sf.toFixed(2)
                      : "--"
                  } $`}
                />
                <span className="min-w-[30px]">
                  {openSection === "listing" ? "-" : "+"}
                </span>
              </button>
              {openSection === "listing" && (
                <ListingSection
                  zipCode={capData?.zipCode || ""}
                  capData={capData}
                  toggleData={toggleData}
                  setCapData={setCapData}
                  currentTable={currentTable}
                  updateCapData={updateCapData}
                  isUpdateCapDataLoading={isUpdateCapDataLoading}
                />
              )}
            </div>

            {currentTable !== "existing" && (
              <div>
                <button
                  onClick={() => toggleSection("demo")}
                  className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                    openSection === "demo"
                      ? "bg-blue-100 text-blue-500"
                      : "text-gray-700"
                  } hover:bg-blue-50 gap-x-4`}
                >
                  <span className="flex-1 min-w-[200px]">Demo Work</span>
                  <input
                    className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                    value={`${
                      toggleData?.EstimatedDemoCost != null
                        ? toggleData?.EstimatedDemoCost.toFixed(2)
                        : "--"
                    } $`}
                  />
                  <span className="min-w-[30px]">
                    {openSection === "demo" ? "-" : "+"}
                  </span>
                </button>
                {openSection === "demo" && (
                  <DemoSection
                    toggleData={toggleData}
                    capData={capData}
                    setCapData={setCapData}
                    currentTable={currentTable}
                    updateCapData={updateCapData}
                    isUpdateCapDataLoading={isUpdateCapDataLoading}
                  />
                )}
              </div>
            )}

            {currentTable !== "existing" && (
              <div>
                <button
                  onClick={() => toggleSection("construction")}
                  className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                    openSection === "construction"
                      ? "bg-blue-100 text-blue-500"
                      : "text-gray-700"
                  } hover:bg-blue-50 gap-x-4`}
                >
                  <span className="flex-1 min-w-[200px]">
                    Construction Cost
                  </span>
                  <input
                    className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                    value={`${
                      toggleData?.ECC != null
                        ? toggleData?.ECC.toFixed(2)
                        : "--"
                    } $`}
                  />
                  <span className="min-w-[30px]">
                    {openSection === "construction" ? "-" : "+"}
                  </span>
                </button>
                {openSection === "construction" && (
                  <ConstructionSection
                    capData={capData}
                    toggleData={toggleData}
                    setCapData={setCapData}
                    currentTable={currentTable}
                    updateCapData={updateCapData}
                    isUpdateCapDataLoading={isUpdateCapDataLoading}
                  />
                )}
              </div>
            )}

            {currentTable === "existing" && (
              <>
                <div>
                  <button
                    onClick={() => toggleSection("renovationsection")}
                    className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                      openSection === "renovationsection"
                        ? "bg-blue-100 text-blue-500"
                        : "text-gray-700"
                    } hover:bg-blue-50 gap-x-4`}
                  >
                    <span className="flex-1 min-w-[200px]">
                      Renovation Cost
                    </span>
                    <input
                      className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                      value={`${
                        toggleData?.ECC != null
                          ? toggleData?.ECC.toFixed(2)
                          : "--"
                      } $`}
                    />
                    <span className="min-w-[30px]">
                      {openSection === "renovationsection" ? "-" : "+"}
                    </span>
                  </button>
                  {openSection === "renovationsection" && (
                    <RenovationSection
                      capData={capData}
                      toggleData={toggleData}
                      setCapData={setCapData}
                      currentTable={currentTable}
                      updateCapData={updateCapData}
                      isUpdateCapDataLoading={isUpdateCapDataLoading}
                    />
                  )}
                </div>

                <div>
                  <button
                    onClick={() => toggleSection("purchaseloan")}
                    className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                      openSection === "purchaseloan"
                        ? "bg-blue-100 text-blue-500"
                        : "text-gray-700"
                    } hover:bg-blue-50 gap-x-4`}
                  >
                    <span className="flex-1 min-w-[200px]">Purchase Loan</span>
                    <input
                      className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                      value={`${
                        toggleData?.rennovationLoan != null
                          ? toggleData?.rennovationLoan.toFixed(2)
                          : "--"
                      } $`}
                    />
                    <span className="min-w-[30px]">
                      {openSection === "purchaseloan" ? "-" : "+"}
                    </span>
                  </button>
                  {openSection === "purchaseloan" && (
                    <PurchaseLoan
                      capData={capData}
                      toggleData={toggleData}
                      setCapData={setCapData}
                      currentTable={currentTable}
                      updateCapData={updateCapData}
                      isUpdateCapDataLoading={isUpdateCapDataLoading}
                    />
                  )}
                </div>
              </>
            )}

            {currentTable !== "existing" && (
              <>
                <div>
                  <button
                    onClick={() => toggleSection("additionalconstruction")}
                    className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                      openSection === "additionalconstruction"
                        ? "bg-blue-100 text-blue-500"
                        : "text-gray-700"
                    } hover:bg-blue-50 gap-x-4`}
                  >
                    <span className="flex-1 min-w-[200px]">
                      Additional Construction Work
                    </span>
                    <input
                      className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                      value={`${
                        toggleData?.TotalConstructionCost != null
                          ? toggleData?.TotalConstructionCost.toFixed(2)
                          : "--"
                      } $`}
                    />
                    <span className="min-w-[30px]">
                      {openSection === "additionalconstruction" ? "-" : "+"}
                    </span>
                  </button>

                  {openSection === "additionalconstruction" && (
                    <AdditionalConstructionSection
                      capData={capData}
                      toggleData={toggleData}
                      setCapData={setCapData}
                      currentTable={currentTable}
                      updateCapData={updateCapData}
                      isUpdateCapDataLoading={isUpdateCapDataLoading}
                    />
                  )}
                </div>
                <div>
                  <button
                    onClick={() => toggleSection("constructionloan")}
                    className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                      openSection === "constructionloan"
                        ? "bg-blue-100 text-blue-500"
                        : "text-gray-700"
                    } hover:bg-blue-50 gap-x-4`}
                  >
                    <span className="flex-1 min-w-[200px]">
                      Construction Loan
                    </span>
                    <input
                      className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                      value={`${
                        toggleData?.ConstructionLoan != null
                          ? toggleData?.ConstructionLoan.toFixed(2)
                          : "--"
                      } $`}
                    />
                    <span className="min-w-[30px]">
                      {openSection === "constructionloan" ? "-" : "+"}
                    </span>
                  </button>
                  {openSection === "constructionloan" && (
                    <ConstructionLoan
                      capData={capData}
                      toggleData={toggleData}
                      setCapData={setCapData}
                      currentTable={currentTable}
                      updateCapData={updateCapData}
                      isUpdateCapDataLoading={isUpdateCapDataLoading}
                    />
                  )}
                </div>
              </>
            )}

            <div>
              <button
                onClick={() => toggleSection("design")}
                className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                  openSection === "design"
                    ? "bg-blue-100 text-blue-500"
                    : "text-gray-700"
                } hover:bg-blue-50 gap-x-4`}
              >
                <span className="flex-1 min-w-[200px]">Design</span>
                <input
                  className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                  value={`${
                    toggleData?.TotalDesignCost != null
                      ? toggleData?.TotalDesignCost.toFixed(2)
                      : "--"
                  } $`}
                />
                <span className="min-w-[30px]">
                  {openSection === "design" ? "-" : "+"}
                </span>
              </button>
              {openSection === "design" && (
                <DesignSection
                  capData={capData}
                  toggleData={toggleData}
                  setCapData={setCapData}
                  currentTable={currentTable}
                  updateCapData={updateCapData}
                  isUpdateCapDataLoading={isUpdateCapDataLoading}
                />
              )}
            </div>

            {currentTable !== "condo" && (
              <div>
                <button
                  onClick={() => toggleSection("revenue")}
                  className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                    openSection === "revenue"
                      ? "bg-blue-100 text-blue-500"
                      : "text-gray-700"
                  } hover:bg-blue-50 gap-x-4`}
                >
                  <span className="flex-1 min-w-[200px]">Rental Revenue</span>
                  <input
                    className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                    value={`${
                      toggleData?.TOTYR != null
                        ? toggleData?.TOTYR.toFixed(2)
                        : "--"
                    } $`}
                  />
                  <span className="min-w-[30px]">
                    {openSection === "revenue" ? "-" : "+"}
                  </span>
                </button>
                {openSection === "revenue" && (
                  <RevenueSection
                    capData={capData}
                    toggleData={toggleData}
                    setCapData={setCapData}
                    currentTable={currentTable}
                    updateCapData={updateCapData}
                    isUpdateCapDataLoading={isUpdateCapDataLoading}
                  />
                )}
              </div>
            )}

            {currentTable === "condo" && (
              <div>
                <button
                  onClick={() => toggleSection("sale")}
                  className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                    openSection === "sale"
                      ? "bg-blue-100 text-blue-500"
                      : "text-gray-700"
                  } hover:bg-blue-50 gap-x-4`}
                >
                  <span className="flex-1 min-w-[200px]">Sale Revenue</span>
                  <input
                    className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                    value={`${
                      toggleData?.totalCondoRevenue != null
                        ? toggleData?.totalCondoRevenue.toFixed(2)
                        : "--"
                    } $`}
                  />
                  <span className="min-w-[30px]">
                    {openSection === "sale" ? "-" : "+"}
                  </span>
                </button>
                {openSection === "sale" && (
                  <SaleSection
                    capData={capData}
                    toggleData={toggleData}
                    setCapData={setCapData}
                    currentTable={currentTable}
                    updateCapData={updateCapData}
                    isUpdateCapDataLoading={isUpdateCapDataLoading}
                  />
                )}
              </div>
            )}

            {currentTable !== "condo" && (
              <div>
                <button
                  onClick={() => toggleSection("management")}
                  className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                    openSection === "management"
                      ? "bg-blue-100 text-blue-500"
                      : "text-gray-700"
                  } hover:bg-blue-50 gap-x-4`}
                >
                  <span className="flex-1 min-w-[200px]">Management Costs</span>
                  <input
                    className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                    value={`${
                      toggleData?.TotalManagementCostValue != null
                        ? toggleData?.TotalManagementCostValue.toFixed(2)
                        : "--"
                    } $`}
                  />
                  <span className="min-w-[30px]">
                    {openSection === "management" ? "-" : "+"}
                  </span>
                </button>
                {openSection === "management" && (
                  <ManagementSection
                    capData={capData}
                    toggleData={toggleData}
                    setCapData={setCapData}
                    currentTable={currentTable}
                    updateCapData={updateCapData}
                    isUpdateCapDataLoading={isUpdateCapDataLoading}
                  />
                )}
              </div>
            )}

            <div>
              <button
                onClick={() => toggleSection("breakdown")}
                className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                  openSection === "breakdown"
                    ? "bg-blue-100 text-blue-500"
                    : "text-gray-700"
                } hover:bg-blue-50 gap-x-1`}
              >
                <span className="flex-1 min-w-[200px]">
                  Break Down Overtime
                </span>
                <input
                  className="bg-transparent border-none text-right focus:outline-none pointer-events-none select-none min-w-[100px] flex-1"
                  value={toggleData?.years || 20}
                />
                Years
                <span className="min-w-[30px]">
                  {openSection === "breakdown" ? "-" : "+"}
                </span>
              </button>
              {openSection === "breakdown" && (
                <BreakDownOvertime
                  capData={capData}
                  toggleData={toggleData}
                  setCapData={setCapData}
                  currentTable={currentTable}
                  updateCapData={updateCapData}
                  isUpdateCapDataLoading={isUpdateCapDataLoading}
                />
              )}
            </div>

            <div>
              <button
                onClick={() => toggleSection("rental")}
                className={`w-full text-left px-4 py-3 flex justify-start items-center ${
                  openSection === "rental"
                    ? "bg-blue-100 text-blue-500"
                    : "text-gray-700"
                } hover:bg-blue-50 gap-x-4`}
              >
                <span className="flex-1 min-w-[200px]">
                  Rental Property Calculator
                </span>
                <span className="min-w-[30px]">
                  {openSection === "rental" ? "-" : "+"}
                </span>
              </button>
              {openSection === "rental" && (
                <RentalPropertyCalculator
                  capData={toggleData}
                  setCapData={setCapData}
                  currentTable={currentTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapModal;

import React, { useState } from 'react';
import { SiChatbot } from "react-icons/si";
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbot from '../../pages/Home/index';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Menu, X, ChevronRight, Search, MapPin, Info, Star, DollarSign, Users, UserPlus, Lock, LogOut, ArrowRight, Check, ChevronDown, ChevronUp } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

const Layout = ({ children }) => {
  const [showChat, setShowChat] = useState(false);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleChatClick = () => {
    setShowChat(true);
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  const isSpecialRoute = location.pathname === '/admin' || location.pathname === '/operator';

  return (
    <div className="h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white shadow-sm z-20">
        <div className="p-4 flex items-center border-b border-gray-200">
          {isAuthenticated && (
            <button
              className="text-gray-600 hover:text-gray-900 mr-4"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <Menu size={24} />
            </button>
          )}
          <div className="flex items-center">
            <img src="/assets/logo.jpg" alt="NYCZoning AI Logo" className="w-8 h-8 mr-2 object-contain" />
            <span className="text-xl font-bold">NYCZoning AI</span>
          </div>
        </div>
      </header>
      
      {/* Sidebar */}
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      {/* {!isSpecialRoute && <Navbar />} */}
      <main className="flex-1 overflow-auto">
        {children}
      </main>
      {/* <div className="fixed bottom-10 right-10 z-50">
        {!showChat && (
          <div
            className="w-16 h-16 border-[1px] bg-[#00FFFF] hover:cursor-pointer cursor-pointer border-[#33ECFF] rounded-full flex justify-center items-center text-4xl text-[#0000FF]"
            onClick={handleChatClick}
          >
            <SiChatbot />
          </div>
        )}
        {showChat && <Chatbot showChat={showChat} handleCloseChat={handleCloseChat} />}
      </div> */}
    </div>
  );
};

export default Layout;
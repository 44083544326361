import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const FormNavbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("authToken");
    navigate("/admin-login");
  };

  const handleFormFill = () => {
    navigate("/operator");
  };

  const handleGoBack = () => {
    navigate("/admin");
  };
  return (
    <nav className="w-full max-w-screen px-4 py-2 mx-auto bg-white shadow-md rounded-md lg:px-8 lg:py-3 flex justify-between items-center fixed top-0 left-0 z-10">
      <span className="text-3xl font-sans font-bold">Admin Panel</span>

      <div>
        <button
          className="text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          onClick={handleGoBack}
        >
          Go Back to Admin Panel
        </button>
        <button
          className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          onClick={handleFormFill}
        >
          Form Fill
        </button>

        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default FormNavbar;

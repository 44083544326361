import React from "react";
import { Routes, Route } from 'react-router-dom';
import NotFound from "./pages/404";
import LoginPage from "./website/pages/Login";
import RegisterPage from "./website/pages/Register";
import DisclaimerPage from "./website/pages/DisclaimerPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Admin/Dashboard";
import EmailVerified from "./pages/User/EmailVerify";
import DynamicForm from "./pages/Home/components/filterForm";
import UserDynamicForm from "./pages/Home/components/userFilterForm";
import PropertySearch from "./website/pages/Home/PropertySearch";
import Home from "./website/pages/Home/Home";
import Layout from "./website/components/Layout";
import DynamicFormWebsite from "./website/components/FilterForm";
import AdminPage from "./website/pages/Admin/Admin";
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute, AdminRoute } from './components/protectedRoute';
import AddressDataPage from "./pages/Admin/Dashboard/AddressDataPage";
import { Paywall } from "./pages/Home/components/SubscriptionStatus";
// import BuildingEditor from "./pages/BuildingEditor";
import LandingPage from "./website/pages/Home/LandingPage";
import ProfilePage from './website/pages/Profile';

const App = () => {
  return (
    <AuthProvider>
      <Layout>
        <Routes>
          {/* <Route path="/admin-login" element={<Login />} />
          <Route path="/admin-register" element={<ProtectedRoute element={Register} />} /> */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <Dashboard />
              </AdminRoute>
            }
          />
          <Route
            path="/admin/address-data"
            element={
              <AdminRoute>
                <AddressDataPage />
              </AdminRoute>
            }
          />
          <Route path="/operator" element={<ProtectedRoute element={AdminPage} />} />

          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/disclaimer" element={
            <ProtectedRoute>
              <DisclaimerPage />
            </ProtectedRoute>
          } />
          {/* <Route path="/building-editor" element={<BuildingEditor />} /> */}
          <Route path="/property-search" element={
            <ProtectedRoute>
              <Paywall mapView={true}>
                <PropertySearch />
              </Paywall>
            </ProtectedRoute>
          } />
          
          <Route path="/zoning-analysis/:id" element={
            <ProtectedRoute>
              <DynamicForm mode="user" />
            </ProtectedRoute>
          } />
          {/* Handles ?address=123 Main St, New York, NY 10001 */}
          <Route path="/zoning-analysis" element={
            <ProtectedRoute>
              <DynamicForm mode="user" />
            </ProtectedRoute>
          } />
          <Route path="/review/:id" element={
            <ProtectedRoute>
              <DynamicForm mode="operator" />
            </ProtectedRoute>
          } />

          {/* <Route path="operator-filter/:id" element={<DynamicForm />} /> */}
          <Route path="user-filter/:id" element={<UserDynamicForm />} />
          <Route path="/verifyEmail" element={<EmailVerified />} />
          <Route path="*" element={<NotFound />} />

          {/* Admin Routes */}
          {/* <Route path="/admin/*" element={
            <AdminRoute>
              <ProtectedRoute element={Dashboard} />
            </AdminRoute>
          } /> */}
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Layout>

      <ToastContainer />
    </AuthProvider>
  );
};

export default App;

import React from "react";

const ApplyChanges = ({
  updateCapData,
  capData,
  currentTable,
  isUpdateCapDataLoading,
}) => {
  return (
    <div className="flex justify-center">
      <button
        onClick={() => updateCapData(capData.address, currentTable)}
        className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 flex items-center"
      >
        <span>
          {isUpdateCapDataLoading ? "APPLYING CHANGES" : "APPLY CHANGES"}
        </span>
        {isUpdateCapDataLoading && (
          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white ml-2"></div>
        )}
      </button>
    </div>
  );
};

export default ApplyChanges;

import React from "react";
import { MapPin, Bed, Bath, Square } from "lucide-react";

const ImageSection = ({ imgUrls }) => (
    <div className="flex h-64">
      <div className="w-full border-r border-gray-200">
        {imgUrls && imgUrls[0] ? (
          <img
            src={imgUrls[0]}
            alt="Main property view"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-300 flex items-center justify-center">
            <span className="text-gray-600">No Image Available</span>
          </div>
        )}
      </div>
    </div>
  );
  

const HeaderSection = ({ name, zipcode, price }) => (
  <div className="flex justify-between items-center mb-4">
    <div className="flex flex-row items-center justify-between w-full">
      <h2 className="text-base sm:text-lg font-bold text-gray-800 my-2">
        {name} {zipcode}
      </h2>
      <span className="text-base sm:text-lg  text-black">{price}</span>
    </div>
  </div>
);

const InfoSection = ({
  lotArea,
  zoningDistricts,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  status,
  scrapLink,
  formLink,fetchStatuses
}) => {
  const formattedZoningDistricts = Array.isArray(zoningDistricts)
    ? zoningDistricts?.join(", ")
    : zoningDistricts;

    const uniqueFARs = FARs && FARs.length > 0 ? [...new Set(FARs)].join(", ") : "TBD";
    const uniqueFAs = FAs && FAs.length > 0 ? [...new Set(FAs)].join(", ") : "TBD";
    const uniqueMaxApartments = maxApartments && maxApartments.length > 0 ? [...new Set(maxApartments)] : ["TBD"];
  
  return (
    <div className="flex flex-wrap items-center gap-4">
      <div className="flex items-center gap-2">
        <span className="text-gray-600 text-sm sm:text-md">
          Residential FA:
        </span>
        <span className="font-bold text-sm sm:text-md">{uniqueFAs}</span>
      </div>

      <div className="hidden">
        <span className="text-gray-600 text-sm sm:text-md">
          Listing Updated:
        </span>
        <span className="font-bold text-sm sm:text-md">{listingUpdated}</span>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Lot Area:</p>
        <p className="font-bold text-sm sm:text-md">{lotArea || "--"}</p>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Max Apartments:</p>
        <p className="font-bold text-sm sm:text-md">{uniqueMaxApartments}</p>
      </div>

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md">Zoning Districts:</p>
        <p className="font-bold text-sm sm:text-md">
          {formattedZoningDistricts || "--"}
        </p>
      </div>

      {scrapLink && (
        <div className="flex items-center gap-1">
          <p className="text-gray-600 text-sm sm:text-md">Address Link:</p>
          <a href={scrapLink} target="_blank">
            <p className="font-bold text-sm sm:text-md text-blue-500 underline hover:text-blue-700  ">
              View Map
            </p>
          </a>
        </div>
      )}

      {formLink && (
        <div className="flex items-center gap-1">
          <p className="text-gray-600 text-sm sm:text-md">Form Link:</p>
          <a href={formLink} target="_blank">
            <p className="font-bold text-sm sm:text-md text-blue-500 underline hover:text-blue-700 ">
              Fill Form
            </p>
          </a>
        </div>
      )}

      <div className="flex items-center gap-1">
        <p className="text-gray-600 text-sm sm:text-md" onClick={fetchStatuses} >Status:</p>
        <p className="font-bold text-sm sm:text-md">{status || "pending"}</p>
      </div>
    </div>
  );
};

const ButtonSection = ({ handleClick }) => (
  <div className="flex items-center justify-center mt-4">
    <button
      onClick={handleClick}
      className="bg-blue-500 w-full text-white rounded-md font-semibold hover:bg-blue-700 transition-colors px-3 py-2 text-sm md:text-base"
    >
      View Property
    </button>
  </div>
);

const AdminCard = ({ data,fetchStatuses }) => {
  const propertyUrls = data?.propertyInfo?.propertyUrls;
  const handleClick = () => {
    if (propertyUrls && propertyUrls.length > 0) {
      window.open(propertyUrls[0], "_blank");
    }
  };

  const lotArea = data?.lotArea;
  const zoningDistricts = data?.zoningDistrict;
  const imgUrls = data?.propertyInfo?.imgUrls;
  const { FARs, maxApartmentsAllowed, FAs } = data?.zolaData;
  const listingUpdated = data?.propertyInfo?.listingUpdated;
  const name = data?.address;
  const scrapLink = data?.scrapLink;
  const formLink = data?.formLink;
  const zipcode = data?.propertyInfo?.address?.postalCode;
  const price = data?.propertyInfo?.price;
  const status = data?.status;
  return (
    <>
      <div  className="bg-white shadow-lg rounded-lg overflow-hidden w-full flex flex-col">
        <ImageSection imgUrls={imgUrls} />
        <div className="p-4 md:p-6 flex flex-col">
          <HeaderSection name={name} price={price} />
          <InfoSection
            lotArea={lotArea}
            zoningDistricts={zoningDistricts}
            FARs={FARs}
            FAs={FAs}
            maxApartments={maxApartmentsAllowed}
            listingUpdated={listingUpdated}
            scrapLink={scrapLink}
            formLink={formLink}
            status={status}
            fetchStatuses={fetchStatuses}
          />
          <ButtonSection handleClick={handleClick} />
        </div>
      </div>
    </>
  );
};

export default AdminCard;

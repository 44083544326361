import React from "react";
import ApplyChanges from "./ApplyChanges";

const DemoSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }
    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  return (
<div className="px-4 pb-3 text-gray-600">
  <div className="flex flex-row items-center my-4 justify-between">
    <label className="w-[30%]">Demo Scope</label>
    <div className="flex items-center w-[30%]">
      <input
        type="text"
        name="demoScope"
        className="border-[1px] px-1 rounded w-full border-gray-400 bg-gray-100"
        value={toggleData?.demoScope || "--"}
        disabled={true}
      />
      <span>%</span>
    </div>
    <div className="flex items-center w-[35%]">
      <input
        type="text"
        name="GFA"
        className="border-[1px] px-1 rounded w-full border-black"
        value={toggleData?.GFA || "--"}
        onChange={handleChange}
      />
      <span>SF</span>
    </div>
  </div>

  <div className="flex flex-row items-center my-2 justify-between">
    <label className="w-[30%]">Demo Unit Cost</label>
    <div className="flex items-center w-[35%]">
      <input
        type="text"
        name="demoUnitCost"
        className="border-[1px] px-1 rounded w-full border-black"
        value={toggleData?.demoUnitCost || "--"}
        onChange={handleChange}
      />
      <span>($/SF)</span>
    </div>
  </div>

  <div className="flex flex-row items-center my-2 justify-between">
    <label className="w-[30%]">Estimated Demo Cost</label>
    <div className="flex items-center w-[35%]">
      <input
        type="text"
        name="EstimatedDemoCost"
        className="border-[1px] px-1 rounded w-full border-gray-400 bg-gray-100"
        value={toggleData?.EstimatedDemoCost || "--"}
        disabled={true}
      />
    </div>
  </div>

  <ApplyChanges
    updateCapData={updateCapData}
    capData={capData}
    currentTable={currentTable}
    isUpdateCapDataLoading={isUpdateCapDataLoading}
  />
</div>

  );
};

export default DemoSection;

import ApplyChanges from "./ApplyChanges";

const ListingSection = ({
  zipCode,
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    if (name === "zipCode") {
      setCapData((prevCapData) => ({
        ...prevCapData,
        [name]: validValue,
      }));
    } else {
      setCapData((prevCapData) => ({
        ...prevCapData,
        [currentTable]: {
          ...prevCapData[currentTable],
          [name]: validValue,
        },
      }));
    }
  };

  return (
    <>
      <div className="px-4 pb-3 text-gray-600">
        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-[30%] mr-2">Zip Code</label>
          <input
            type="text"
            name="zipCode"
            className={`border-[1px] px-2 rounded w-[50%] ${
              toggleData === undefined
                ? "border-black"
                : "border-gray-400 bg-gray-100"
            }`}
            value={zipCode || ""}
            onChange={handleInputChange}
            disabled={toggleData !== undefined}
          />
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <label className="w-[30%] mr-2">Residential Floor Area</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              name="RFA"
              className="border-[1px] px-2 rounded w-[90%] border-black"
              value={toggleData?.RFA || "--"}
              onChange={handleInputChange}
            />
            <span className="w-[25%] text-right">SF</span>
          </div>
        </div>

        {currentTable !== "existing" && (
          <div className="flex flex-row items-center my-2 justify-between">
            <label className="w-[30%] mr-2">Floor Area Deductions</label>
            <div className="flex flex-row items-center justify-end w-[50%]">
              <input
                type="text"
                name="DED"
                className="border-[1px] px-2 rounded w-[90%] border-black"
                value={toggleData?.DED || "--"}
                onChange={handleInputChange}
              />
              <span className="w-[25%] text-right">%</span>
            </div>
          </div>
        )}

        {currentTable !== "existing" && (
          <div className="flex flex-row items-center my-2 justify-between">
            <label className="w-[30%] mr-2">Gross Floor Area</label>
            <div className="flex flex-row items-center justify-end w-[50%]">
              <input
                type="text"
                name="GFA"
                className="border-[1px] px-2 rounded w-[90%] border-black"
                value={toggleData?.GFA || "--"}
                onChange={handleInputChange}
              />
              <span className="w-[25%] text-right">SF</span>
            </div>
          </div>
        )}

        <div className="flex flex-row items-center my-4 justify-between">
          <label className="w-[30%] mr-2">Purchase Price</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              name="numericPrice"
              className="border-[1px] px-2 rounded w-[90%] border-black"
              value={toggleData?.numericPrice || "--"}
              onChange={handleInputChange}
            />
            <span className="w-[25%] text-right">$</span>
          </div>
        </div>

        <div className="flex flex-row items-center my-2 justify-between">
          <label className="w-[30%] mr-2">Price/SF Floor Area</label>
          <div className="flex flex-row items-center justify-end w-[50%]">
            <input
              type="text"
              name="price_sf"
              className="border-[1px] px-2 rounded w-[90%] border-gray-400 bg-gray-100"
              value={toggleData?.price_sf || "--"}
              disabled={true}
            />
            <span className="w-[25%] text-right">$/SF</span>
          </div>
        </div>

        <ApplyChanges
          updateCapData={updateCapData}
          capData={capData}
          currentTable={currentTable}
          isUpdateCapDataLoading={isUpdateCapDataLoading}
        />
      </div>
    </>
  );
};

export default ListingSection;

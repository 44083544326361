import { useState } from "react";
import { useNavigate } from "react-router-dom";
import background from "../../assets/bg_new.jpg";

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="max-w-md w-full bg-white bg-opacity-90 px-8 py-6 rounded-lg shadow-lg text-center">
        <div className="flex justify-center mb-4">
          <img src="/assets/logo.jpg" alt="NYCZoning AI Logo" className="w-20 h-20 object-contain" />
        </div>

        <h1 className="text-3xl font-bold text-gray-900 mb-2">NYC ZONING AI</h1>
        <p className="text-gray-700 mb-6">
          An AI Powered Zoning Law & Real Estate Platform
        </p>

        <button
          className="w-full px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-500 text-lg font-medium transition-colors duration-300 mb-2"
          onClick={() => navigate("/register")}
        >
          Get Started
        </button>
        <button
          type="button"
          className="w-full px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50"
          onClick={() => navigate("/login")}
        >
          Log in
        </button>
      </div>
    </div>
  );
};

export default LandingPage;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    fontSize: "0.875rem",
    color: "#333",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
});

const ImageSection = ({ imgUrls }) => {
  const hasValidImage = imgUrls && imgUrls.length > 0 && imgUrls[0];

  return (
    <div className="flex h-64">
      <div className="w-full border-r border-gray-200">
        {hasValidImage ? (
          <img
            src={imgUrls[0]}
            alt="Main property view"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <span className="text-gray-500">No image available</span>
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderSection = ({ name, zipcode, price, imgUrls }) => (
  <div className="w-full border-b border-gray-200 p-2">
    <ImageSection imgUrls={imgUrls} />
    <div className="text-center text-base text-gray-700 mt-1">
      {name} {zipcode}
    </div>
    <div className="text-center text-lg font-bold mt-1">
      {price && price.substr("$") ? price : `$${price}`}
    </div>
  </div>
);

const InfoSection = ({
  lotArea,
  priceSf,
  zoningDistricts,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  tip1,
  tip2,
  tip2_5,
  linktip2_5,
  linktip2,
  setCapModalOpen,
  capRate,
  deptYield,
  IRR,
  CashOnCashReturn,
  handleAnalyze,
}) => {
  const formattedZoningDistricts = Array.isArray(zoningDistricts)
    ? zoningDistricts.join(", ")
    : zoningDistricts;

  const uniqueFARs =
    FARs && FARs.length > 0 ? [...new Set(FARs)].join(", ") : '' + FARs;
  const uniqueFAs =
    FAs && FAs.length > 0 ? [...new Set(FAs)].join(", ") : '' + FAs;
  const uniqueMaxApartments =
    maxApartments && maxApartments.length > 0
      ? [...new Set(maxApartments)]
      : [maxApartments];

  return (
    <div className="w-full">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="p-2 text-sm text-gray-600">Lot Area:</td>
            <td className="p-2 text-sm font-bold">{lotArea || "--"}</td>
            <td className="p-2 text-sm text-gray-600">Max FA (sf):</td>
            <td className="p-2 text-sm font-bold">{uniqueFAs || "--"}</td>
          </tr>
          <tr>
            <td className="p-2 text-sm text-gray-600">Existing sf:</td>
            <td className="p-2 text-sm font-bold">--</td>
            <td className="p-2 text-sm text-gray-600">New (sf):</td>
            <td className="p-2 text-sm font-bold">--</td>
          </tr>
          <tr>
            <td className="p-2 text-sm text-gray-600">Max Apartments:</td>
            <td className="p-2 text-sm font-bold" colSpan="3">
              {maxApartments || "--"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const ButtonSection = ({ handleClick, handleAnalyze }) => (
  <div className="flex items-center justify-center mt-4">
    <button
      onClick={handleClick}
      className="bg-blue-500 w-full text-white rounded-md font-semibold hover:bg-blue-700 transition-colors px-3 py-2 text-sm md:text-base"
    >
      View
    </button>
    <button
      onClick={handleAnalyze}
      className="bg-blue-500 w-full ml-2 text-white rounded-md font-semibold hover:bg-blue-700 transition-colors px-3 py-2 text-sm md:text-base"
    >
      Analyze
    </button>
  </div>
);

const Card = ({
  title,
  price,
  priceSf,
  location,
  beds,
  baths,
  sqft,
  imgUrls,
  propertyUrls,
  zipcode,
  lotArea,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  zoningDistricts,
  tipValues,
  setCapModalOpen,
  onClick,
  existingApartments,
  existingPriceSf,
  cardCapData,
}) => {
  const [capRate, setCapRate] = useState("--");
  const [deptYield, setDeptYield] = useState("--");
  const [IRR, setIRR] = useState("--");
  const [CashOnCashReturn, setCashOnCashReturn] = useState("--");
  const navigate = useNavigate();

  useEffect(() => {
    if (cardCapData && cardCapData.rental) {
      setCapRate(cardCapData.rental.CapRate?.[0]?.toFixed(2) || "--");
      setDeptYield(cardCapData.rental.DeptYield?.[0]?.toFixed(2) || "--");
      setIRR(cardCapData.rental.IRR?.[0]?.toFixed(2) || "--");
      setCashOnCashReturn(
        cardCapData.rental.CashOnCashReturn?.[0]?.toFixed(2) || "--"
      );
    }
  }, [cardCapData]);

  const handleClick = () => {
    if (propertyUrls && propertyUrls.length > 0) {
      window.open(propertyUrls[0], "_blank");
    }
  };

  const handleAnalyze = () => {
    console.log("Analyze");
    // window.open(`/operator-filter?lotIds=${encodeURIComponent(title)}`, "_blank");
    window.open(`/zoning-analysis?address=${encodeURIComponent(title)}`, "_blank");
  };

  const { tip1, tip2, tip2_5value, linktip2_5, linktip2 } = tipValues || {};
  const name = title?.replace(/\s\d{5}$/, "");
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden h-full flex flex-col">
      <div className="aspect-square relative">
        <img 
          src={imgUrls?.[0]} 
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-4 flex flex-col flex-1">
        <div className="min-h-[3rem] font-bold mb-4">
          {title?.replace(/\s\d{5}$/, "")}
        </div>
        <div className="min-h-[2.5rem] font-bold mb-6">
          {price && price.startsWith("$") ? price : `$${price}`}
        </div>
        <div className="grid grid-cols-3 gap-4 flex-1">
          <div>
            <div className="text-gray-600 text-sm">Lot Area:</div>
            <div className="font-bold">{lotArea || "--"}</div>
          </div>
          <div>
            <div className="text-gray-600 text-sm">Existing Apt:</div>
            <div className="font-bold">{existingApartments || "--"}</div>
          </div>
          <div>
            <div className="text-gray-600 text-sm">Max Apt:</div>
            <div className="font-bold">{maxApartments || "--"}</div>
          </div>
          <div>
            <div className="text-gray-600 text-sm">Res. FAR:</div>
            <div className="font-bold">{FARs?.toFixed(2) || "--"}</div>
          </div>
          <div>
            <div className="text-gray-600 text-sm">Existing $/sf:</div>
            <div className="font-bold">{(existingPriceSf && '$' + existingPriceSf?.toFixed(0).toLocaleString()) || "--"}</div>
          </div>
          <div>
            <div className="text-gray-600 text-sm">New $/sf:</div>
            <div className="font-bold">{(priceSf && '$' + priceSf?.toFixed(0).toLocaleString()) || "--"}</div>
          </div>
        </div>
        <div className="flex gap-2 mt-6">
          <button
            onClick={handleClick}
            className="flex-1 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-md text-sm py-2 transition-colors"
          >
            View
          </button>
          <button
            onClick={handleAnalyze}
            className="flex-1 text-blue-600 bg-blue-50 hover:bg-blue-100 rounded-md text-sm py-2 transition-colors"
          >
            Analyze
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React, { useEffect, useState } from "react";
import CouponDetails from "./components/CouponDetails";
import GenerateCoupon from "./components/GenerateCoupon";
import { getApi } from "../../../Api/Api";
import { toast } from "react-toastify";

const Coupon = () => {
  const [coupons, setCouponList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getCoupons = async () => {
      try {
        setIsLoading(true);
        const response = await getApi("admin/get-coupons");
        if (response?.data?.data) {
          setCouponList(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching coupons:', error);
        toast.error(error.response?.data?.message || 'Failed to fetch coupons. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
    getCoupons();
  }, []);

  return (
    <article className="generate_coupon m-10">
      <main className="grid grid-cols-5 gap-10">
        <aside className="col-span-1">
          <GenerateCoupon setCouponList={setCouponList} coupons={coupons} isLoading={isLoading} />
        </aside>
        <aside className="col-span-4">
          <CouponDetails setCouponList={setCouponList} coupons={coupons} isLoading={isLoading} />
        </aside>
      </main>
    </article>
  );
};

export default Coupon;

import React from 'react';
import { Popover } from '@headlessui/react';

const Tooltip = ({ content, children }) => {
  return (
    <Popover className="relative inline-block">
      <Popover.Button className="focus:outline-none">
        {children}
      </Popover.Button>

      <Popover.Panel className="absolute z-10 w-96 px-2 mt-3 transform -translate-x-1/2 left-1/2">
        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4 bg-white">
            <p className="text-sm text-gray-700 whitespace-normal break-words">{content}</p>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default Tooltip; 
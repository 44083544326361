import ApplyChanges from "./ApplyChanges";

const ConstructionLoan = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    let finalValue = validValue;

    if (name === "closingCostpercentage" || name === "Downpaymentpercentage") {
      const numericValue = parseFloat(validValue);
      if (numericValue > 100) {
        finalValue = "100";
      } else if (numericValue < 0) {
        finalValue = "0";
      }
    }
    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: finalValue,
      },
    }));
  };

  return (
    <>
      <div className="px-4 pb-3 text-gray-600">
        <div className="flex items-center my-2 justify-between">
          <label className="text-gray-700 w-[30%]">Closing Cost</label>
          <div className="flex w-[65%] space-x-2 justify-end">
            <input
              type="text"
              name="closingCostpercentage"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.closingCostpercentage || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">%</span>
            <input
              type="text"
              disabled
              className="border-[1px] px-1 rounded w-[45%] border-gray-400 bg-gray-100"
              value={toggleData?.ClosingCost || "--"}
            />
            <span className="w-[10%] text-center">$</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="text-gray-700 w-[30%]">Downpayment</label>
          <div className="flex w-[65%] space-x-2 justify-end">
            <input
              type="text"
              name="Downpaymentpercentage"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.Downpaymentpercentage || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">%</span>
            <input
              type="text"
              disabled
              className="border-[1px] px-1 rounded w-[45%] border-gray-400 bg-gray-100"
              value={toggleData?.Downpayment || "--"}
            />
            <span className="w-[10%] text-center">$</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Construction Loan</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              disabled
              name="ConstructionLoan"
              className="border-[1px] px-1 rounded w-[60%] border-gray-400 bg-gray-100"
              value={toggleData?.ConstructionLoan || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">$</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Interest Rate</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              name="InterestRate"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.InterestRate || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">%</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Monthly Interest Rate</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              name="monthlyIR"
              className="border-[1px] px-1 rounded w-[60%] border-gray-400 bg-gray-100"
              value={toggleData?.monthlyIR || "--"}
              onChange={handleChange}
              disabled
            />
            <span className="w-[10%] text-center">%</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Loan Term in Months</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              name="LoanTermInMonths"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.LoanTermInMonths || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">Months</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Loan Term</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              name="LoanTerm"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.LoanTerm || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">Years</span>
          </div>
        </div>

        <div className="flex items-center my-2 justify-between">
          <label className="w-[30%]">Loan Monthly Payment</label>
          <div className="flex w-[65%] justify-end">
            <input
              type="text"
              name="LoanMonthlyPayment"
              className="border-[1px] px-1 rounded w-[60%] border-black"
              value={toggleData?.LoanMonthlyPayment || "--"}
              onChange={handleChange}
            />
            <span className="w-[10%] text-center">$/Month</span>
          </div>
        </div>

        <ApplyChanges
          updateCapData={updateCapData}
          capData={capData}
          currentTable={currentTable}
          isUpdateCapDataLoading={isUpdateCapDataLoading}
        />
      </div>
    </>
  );
};

export default ConstructionLoan;

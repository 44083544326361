import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ApplyChanges from "./ApplyChanges";

const SaleSection = ({
  capData,
  toggleData,
  setCapData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading
}) => {
  const [additionalSections, setAdditionalSections] = useState([]);

  const addSection = () => {
    const newId = additionalSections.length
      ? additionalSections[additionalSections.length - 1].id + 1
      : 1;
    setAdditionalSections([
      ...additionalSections,
      { id: newId, label: `New Section ${newId}` },
    ]);
  };

  const removeSection = (id) => {
    setAdditionalSections(
      additionalSections.filter((section) => section.id !== id)
    );
  };

  useEffect(() => {
    let ADD = additionalSections.map((section) => section.value);

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        ADD: ADD,
      },
    }));
  }, [additionalSections, currentTable]);

  const handleInputChange = (id, field, newValue) => {
    if (field === "value") {
      newValue = newValue.replace(/[^0-9.-]/g, "");

      if (newValue === "" || isNaN(newValue)) {
        newValue = "0";
      }
    }
    setAdditionalSections(
      additionalSections.map((section) =>
        section.id === id ? { ...section, [field]: newValue } : section
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  const [noOfStudioAPtsPerc, setNoOfStudioAPtsPerc] = useState(0);
  const [noOf1brAptsPerc, setNoOf1brAptsPerc] = useState(0);
  const [noOf2brAptsPerc, setNoOf2brAptsPerc] = useState(0);
  const [noOfMoreThan2brAptsPerc, setNoOfMoreThan2brAptsPerc] = useState(0);
  const [totApt, setTotApt] = useState(toggleData?.TotalNumberOfApartments);

  useEffect(() => {
    setNoOfStudioAPtsPerc(
      (toggleData?.noOfStudioAPts / toggleData?.TotalNumberOfApartments) * 100
    );
    setNoOf1brAptsPerc(
      (toggleData?.noOf1brApts / toggleData?.TotalNumberOfApartments) * 100
    );
    setNoOf2brAptsPerc(
      (toggleData?.noOf2brApts / toggleData?.TotalNumberOfApartments) * 100
    );
    setNoOfMoreThan2brAptsPerc(
      (toggleData?.noofmorethan2brApts / toggleData?.TotalNumberOfApartments) *
        100
    );
  }, []);

  const handlePercentChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      return;
    }

    if (parsedValue > 97) {
      return;
    }

    let newValues = {
      noOfStudioAPtsPerc,
      noOf1brAptsPerc,
      noOf2brAptsPerc,
      noOfMoreThan2brAptsPerc,
    };

    newValues[name] = parsedValue;

    const newTotal =
      newValues.noOfStudioAPtsPerc +
      newValues.noOf1brAptsPerc +
      newValues.noOf2brAptsPerc +
      newValues.noOfMoreThan2brAptsPerc;

    const maxTotal = 100;

    if (newTotal > maxTotal) {
      let excess = newTotal - maxTotal;

      if (name !== "noOfStudioAPtsPerc" && noOfStudioAPtsPerc > 1) {
        const deduction = Math.min(excess, noOfStudioAPtsPerc - 1);
        newValues.noOfStudioAPtsPerc -= deduction;
        excess -= deduction;
      }

      if (excess > 0 && name !== "noOf1brAptsPerc" && noOf1brAptsPerc > 1) {
        const deduction = Math.min(excess, noOf1brAptsPerc - 1);
        newValues.noOf1brAptsPerc -= deduction;
        excess -= deduction;
      }

      if (excess > 0 && name !== "noOf2brAptsPerc" && noOf2brAptsPerc > 1) {
        const deduction = Math.min(excess, noOf2brAptsPerc - 1);
        newValues.noOf2brAptsPerc -= deduction;
        excess -= deduction;
      }

      if (
        excess > 0 &&
        name !== "noOfMoreThan2brAptsPerc" &&
        noOfMoreThan2brAptsPerc > 1
      ) {
        const deduction = Math.min(excess, noOfMoreThan2brAptsPerc - 1);
        newValues.noOfMoreThan2brAptsPerc -= deduction;
        excess -= deduction;
      }

      setNoOfStudioAPtsPerc(newValues.noOfStudioAPtsPerc);
      setNoOf1brAptsPerc(newValues.noOf1brAptsPerc);
      setNoOf2brAptsPerc(newValues.noOf2brAptsPerc);
      setNoOfMoreThan2brAptsPerc(newValues.noOfMoreThan2brAptsPerc);
    } else {
      switch (name) {
        case "noOfStudioAPtsPerc":
          setNoOfStudioAPtsPerc(parsedValue);
          break;
        case "noOf1brAptsPerc":
          setNoOf1brAptsPerc(parsedValue);
          break;
        case "noOf2brAptsPerc":
          setNoOf2brAptsPerc(parsedValue);
          break;
        case "noofmorethan2brAptsPerc":
          setNoOfMoreThan2brAptsPerc(parsedValue);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="px-4 pb-3 text-gray-600">
      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Nr of Studio apts</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              type="text"
              name="noOfStudioAPts"
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.noOfStudioAPts || "--"}
              onChange={handleChange}
            />
            <input
              type="text"
              name="noOfStudioAPtsPerc"
              className="border-[1px] px-1 rounded w-1/3 border-black"
              onChange={handlePercentChange}
              value={noOfStudioAPtsPerc || "--"}
            />%
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Nr of 1BR apts</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              type="text"
              name="noOf1brApts"
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.noOf1brApts || "--"}
              onChange={handleChange}
            />
            <input
              type="text"
              name="noOf1brAptsPerc"
              className="border-[1px] px-1 rounded w-1/3 border-black"
              onChange={handlePercentChange}
              value={noOf1brAptsPerc || "--"}
            />%
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Nr of 2BR apts</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              type="text"
              name="noOf2brApts"
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.noOf2brApts || "--"}
              onChange={handleChange}
            />
            <input
              type="text"
              name="noOf2brAptsPerc"
              className="border-[1px] px-1 rounded w-1/3 border-black"
              onChange={handlePercentChange}
              value={noOf2brAptsPerc || "--"}
            />%
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">
          Nr of &gt;2BR apts
        </label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              name="noofmorethan2brApts"
              onChange={handleChange}
              type="text"
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.noofmorethan2brApts || "--"}
            />
            <input
              type="text"
              className="border-[1px] px-1 rounded w-1/3 border-black"
              name="noofmorethan2brAptsPerc"
              onChange={handlePercentChange}
              value={noOfMoreThan2brAptsPerc || "--"}
            />%
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Total Apartments</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              type="text"
              onChange={handleChange}
              name="TotalNumberOfApartments"
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled={true}
              value={toggleData?.TotalNumberOfApartments || "--"}
            />
            <input
              type="text"
              onChange={(e) => {
                const { name, value } = e.target;
                if (name === "TotalNumberOfApartmentsPerc") {
                  const validValue = value.replace(/[^0-9]/g, "");
                  const parsedValue = parseInt(validValue, 10);
                  if (!isNaN(parsedValue) && parsedValue <= 100) {
                    setTotApt(validValue);
                  }
                }
              }}
              name="TotalNumberOfApartmentsPerc"
              className="border-[1px] px-1 rounded w-1/3 border-black"
              value={totApt || "--"}
            />%
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Sale Studio</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              type="text"
              name="Studio_ARS"
              value={toggleData?.Studio_ARS || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />
            <input
              onChange={handleChange}
              type="text"
              value={
                toggleData?.Studio_ARS * toggleData?.TotalNumberOfApartments ||
                ""
              }
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled
            />$/month
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Rent 1BRs</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="AR1"
              type="text"
              value={toggleData?.AR1 || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />
            <input
              type="text"
              value={
                toggleData?.AR1 * toggleData?.TotalNumberOfApartments || "--"
              }
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled
            />$/month
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">
          Rent 2BR apartments
        </label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="AR2"
              type="text"
              value={toggleData?.AR2 || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />
            <input
              type="text"
              value={
                toggleData?.AR2 * toggleData?.TotalNumberOfApartments || "--"
              }
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled
            />$/month
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">
          Rent &gt;2BR apartments
        </label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="AR3"
              type="text"
              value={toggleData?.AR3 || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />
            <input
              type="text"
              value={
                toggleData?.AR3 * toggleData?.TotalNumberOfApartments || "--"
              }
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled
            />$/month
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Sub-Total </label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="sub_total"
              type="text"
              value={toggleData?.sub_total || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />
            <input
              type="text"
              value={
                toggleData?.sub_total * toggleData?.TotalNumberOfApartments ||
                "--"
              }
              className="border-[1px] px-1 rounded w-1/3 border-gray-400 bg-gray-100"
              disabled
            />$/month
          </div>
        </div>
      </div>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Days in Market Cost</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="daysIntheMarket"
              type="text"
              value={toggleData?.daysIntheMarket || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />$
            <input
              type="text"
              value={toggleData?.costDay || "--"}
              onChange={handleChange}
              name="costDay"
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />$/Day
          </div>
        </div>
      </div>

      {additionalSections.map((section) => (
        <div key={section.id}>
          <div className="flex items-center my-2 justify-end mt-5">
            <div className="flex flex-col w-full">
              <div className="flex space-x-2 w-full justify-between">
                <span>Name</span>
                <input
                  type="text"
                  name="name"
                  value={section.name}
                  onChange={(e) =>
                    handleInputChange(section.id, e.target.name, e.target.value)
                  }
                  className="border-[1px] px-1 rounded w-1/3 border-black"
                />
                <span>Value</span>
                <input
                  type="text"
                  name="value"
                  value={section.value}
                  onChange={(e) =>
                    handleInputChange(section.id, e.target.name, e.target.value)
                  }
                  className="border-[1px] px-1 rounded w-1/3 border-black"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={() => removeSection(section.id)}
              className="text-blue-500 text-sm"
            >
              Delete
            </button>
          </div>
        </div>
      ))}

      <button
        onClick={addSection}
        className="flex items-center mt-4 text-blue-500"
      >
        <FaPlus className="mr-2" /> Add
      </button>

      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Cost to Sell</label>
        <div className="flex flex-col w-2/3 items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="costToSellpercentage"
              type="text"
              value={toggleData?.costToSellpercentage || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />%
            <input
              type="text"
              value={toggleData?.totalCosttoSell || "--"}
              onChange={handleChange}
              name="totalCosttoSell"
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />$
          </div>
        </div>
      </div>
      <div className="flex items-center my-2 justify-between">
        <label className="text-gray-700 w-1/3">Total Revenue</label>
        <div className="flex flex-col w-full items-end">
          <div className="flex space-x-2 w-full justify-end">
            <input
              onChange={handleChange}
              name="totalCondoRevenue"
              type="text"
              value={toggleData?.totalCondoRevenue || "--"}
              className="border-[1px] px-1 rounded w-1/3 border-black"
            />$/year
          </div>
        </div>
      </div>

      <ApplyChanges
        updateCapData={updateCapData}
        capData={capData}
        currentTable={currentTable}
        isUpdateCapDataLoading={isUpdateCapDataLoading}
      />
    </div>
  );
};

export default SaleSection;

import ApplyChanges from "./ApplyChanges";

const BreakDownOvertime = ({
  toggleData,
  setCapData,
  capData,
  currentTable,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const defaultRows = toggleData?.years || 20;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }

    let finalValue = validValue;

    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: finalValue,
      },
    }));
  };

  return (
    <>
      <div className="overflow-x-auto h-[400px]">
        <div className="flex items-center justify-between mb-4 mt-2">
          <h2 className="w-1/4">Change Years</h2>
          <input
            className="border border-gray-300 px-2 py-2 rounded w-1/5"
            name="years"
            value={toggleData?.years}
            onChange={handleChange}
          />
          <ApplyChanges
            updateCapData={updateCapData}
            capData={capData}
            currentTable={currentTable}
            isUpdateCapDataLoading={isUpdateCapDataLoading}
          />
        </div>

        <table className="min-w-full text-sm border-collapse border border-gray-300 text-left">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-6 py-3">Year</th>
              {currentTable !== "condo" && (
                <>
                  <th className="border border-gray-300 px-6 py-3">
                    Annual Income
                  </th>
                  <th className="border border-gray-300 px-6 py-3">
                    Cash Flow
                  </th>
                  <th className="border border-gray-300 px-6 py-3">
                    Cash on Cash Return
                  </th>
                </>
              )}
              <th className="border border-gray-300 px-6 py-3">
                Equity Accumulated
              </th>
              <th className="px-6 py-3 border-b text-center" colSpan={2}>
                <div className="flex flex-col items-center space-y-1 pb-2">
                  <span className="border-b w-full">If Sold at Year End</span>
                  <div className="flex space-x-4 justify-center">
                    {currentTable !== "condo" && (
                      <div className="border-r pr-4">
                        <span>Cash to Receive</span>
                      </div>
                    )}
                    <span>Return (IRR)</span>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-6 py-3">Begin</td>
              {currentTable !== "condo" && (
                <>
                  <td className="border border-gray-300 px-6 py-3"></td>
                  <td className="border border-gray-300 px-6 py-3"></td>
                  <td className="border border-gray-300 px-6 py-3"></td>
                </>
              )}
              <td className="border border-gray-300 px-6 py-3"></td>
              <td className="border border-gray-300 px-6 py-3"></td>
              {currentTable !== "condo" && (
                <td className="border border-gray-300 px-6 py-3"></td>
              )}
            </tr>

            {Array.from({ length: defaultRows }, (_, index) => (
              <tr key={index}>
                <td className="border border-gray-300 px-6 py-3">
                  {index + 1}
                </td>
                {currentTable !== "condo" && (
                  <>
                    <td className="border border-gray-300 px-6 py-3">
                      {toggleData?.annualIncome?.[index] || "--"}
                    </td>
                    <td className="border border-gray-300 px-6 py-3">
                      {toggleData?.CashFlows?.[index] || "--"}
                    </td>
                    <td className="border border-gray-300 px-6 py-3">
                      {toggleData?.CashOnCashReturn?.[index] || "--"}
                    </td>
                  </>
                )}
                <td className="border border-gray-300 px-6 py-3">
                  {toggleData?.EquityAccumulated?.[index] || "--"}
                </td>
                {currentTable !== "condo" && (
                  <td className="border border-gray-300 px-6 py-3">
                    {toggleData?.CashTor?.[index] || "--"}
                  </td>
                )}
                <td className="border border-gray-300 px-6 py-3">
                  {toggleData?.IRR?.[index] || toggleData?.IRR || "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BreakDownOvertime;

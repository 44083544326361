import React from "react";
import ApplyChanges from "./ApplyChanges";

const ConstructionSection = ({
  toggleData,
  setCapData,
  currentTable,
  capData,
  updateCapData,
  isUpdateCapDataLoading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let validValue = value ? value.replace(/[^0-9.-]/g, "") : "0";

    if (validValue === "0" && value !== "") {
      validValue = value.replace(/[^0-9.-]/g, "");
    }
    setCapData((prevCapData) => ({
      ...prevCapData,
      [currentTable]: {
        ...prevCapData[currentTable],
        [name]: validValue,
      },
    }));
  };

  return (
    <div className="px-4 pb-3 text-gray-600">
      <div className="flex flex-row items-center my-4 justify-between">
        <label className="w-[30%] mr-2">Construction Unit Cost</label>
        <div className="flex flex-row items-center justify-end w-[50%]">
          <input
            type="text"
            name="ConstructionUnitCost"
            className="border-[1px] px-2 rounded w-[90%] border-black"
            value={toggleData?.ConstructionUnitCost || "--"}
            onChange={handleChange}
          />
          <span className="w-[25%] text-right">$/SF</span>
        </div>
      </div>

      <div className="flex flex-row items-center my-2 justify-between">
        <label className="w-[30%] mr-2">Estimated Construction Cost</label>
        <div className="flex flex-row items-center justify-end w-[50%]">
          <input
            type="text"
            name="ECC"
            disabled={true}
            className="border-[1px] px-2 rounded w-[90%] border-gray-400 bg-gray-100"
            value={toggleData?.ECC || "--"}
            onChange={handleChange}
          />
          <span className="w-[25%] text-right">$</span>
        </div>
      </div>

      <ApplyChanges
        updateCapData={updateCapData}
        capData={capData}
        currentTable={currentTable}
        isUpdateCapDataLoading={isUpdateCapDataLoading}
      />
    </div>
  );
};

export default ConstructionSection;
